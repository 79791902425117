import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Phonenav from "./Phonenav";
import { MyContext } from "../Context/Mycontext";

function Updatecommunication() {


    const context = useContext(MyContext);

    const {navshow,setnavshow} =context;
  
  useEffect(() => {
    // Function to remove and add classes to the body tag
    const removeAndAddClass = () => {
      const body = document.body;
      body.className = ''; 
      body.classList.add('account','mobilelogin-index-updatemobile', 'page-layout-2columns-left'
        
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();
  }, []);



  useEffect(() => {
    // Function to remove the old head content and replace it with a new one
    const replaceHeadTag = () => {
      // Get the current head element
      const head = document.querySelector("head");

      // Remove the old head content
      while (head.firstChild) {
        head.removeChild(head.firstChild);
      }

      // Define the new head content
      const newHeadContent = `


<><><><><><meta httpequiv="origin-trial" content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9" />
    { /* Google Tag Manager */ }{ /* End Google Tag Manager */ }
    <meta charSet="utf-8" />
    <meta name="title" content="Update Communication" />
    <meta name="robots" content="INDEX, FOLLOW, ARCHIVE" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@muftijeans" />
    <meta name="twitter:title" content="Update Communication" />
    <meta name="twitter:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg" />
    <meta name="twitter:url" content="https://www.muftijeans.in/" />
    <meta property="og:title" content="Update Communication" />
    <meta property="og:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg" />
    <meta property="og:url" content="https://www.muftijeans.in/" />
    <title>Update Communication</title>
    <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/_cache/merged/57fcea3f138d3d4297e00d124630ccaf.min.css" />
    <link rel="stylesheet" type="text/css" media="screen and (min-width: 768px)" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/styles-l.min.css" />
    <link rel="stylesheet" type="text/css" media="print" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/print.min.css" />
    <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/_cache/merged/161b37fc16b2af05bfc55815c8ca59d2.min.css" />
    <link rel="icon" type="image/x-icon" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico" />
    <link rel="shortcut icon" type="image/x-icon" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico" />


    <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/light/opensans-300.woff2" />
    <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/regular/opensans-400.woff2" />
    <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/semibold/opensans-600.woff2" />
    <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/bold/opensans-700.woff2" />
    <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/Luma-Icons.woff2" />
   

    <meta name="google-site-verification" content="BaylKQFecuyMjXFmIOJNtNP0nVZE7ijQhtX4uJPer6A" />
    <meta name="facebook-domain-verification" content="yyehg4kpch6ge5rq5xyr7qxd52ts4y" />







    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap" />
    { /* <link href="https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap" rel="stylesheet" /> */ }
    <meta name="google-site-verification" content="googlecb8d2cd7f72f5378" />
    { /* Global site tag (gtag.js) - Google Ads: 792747482 */ }
    <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/custom.min.css" />
    { /* BEGIN GOOGLE ANALYTICS CODE */ }{ /* END GOOGLE ANALYTICS CODE */ }
    <link rel="stylesheet" type="text/css" media="all" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css" /></>



         `;

      // Add the new content to the head
      head.innerHTML = newHeadContent;
    };

    replaceHeadTag();
  }, []);

  return (
    <>
     


<main id="maincontent" className="page-main"> <a id="contentarea" tabIndex={-1} />
            <div className="page messages">
                <div data-placeholder="messages" />
                <div data-bind="scope: 'messages'">
                    { /* ko if: cookieMessages && cookieMessages.length > 0 */ }{ /* /ko */ }{ /* ko if: messages().messages && messages().messages.length > 0 */ }{ /* /ko */ }
                </div>
            </div>
            <div className="columns">
                <div className="column main" style={{ display: navshow === 'active' ? 'none' : '' }}>
                    <div className="page-title-wrapper">
                        <h1 className="page-title"><span className="base" data-ui-id="page-title-wrapper">Update
                                Communication</span></h1>
                    </div><input name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" />
                    <div id="authenticationPopup" data-bind="scope:'authenticationPopup', style: {display: 'none'}" style={{ display: "none" }}> { /* ko template: getTemplate() */ }


                        { /* /ko */ }
                    </div> <span />
                    <div className="update_mob">
                        <div className="update_mob_text">
                            <div id="smserrordiv" name="smserrordiv" className="message error" style={{ display: "none" }} />
                            <div id="smssuccessdiv" name="smssuccessdiv" className="message success" style={{ display: "none" }}>
                            </div><input type="hidden" name="mobilenumber" id="createmobile" /><input type="hidden" name="createotp" id="createotp" />
                            <div>
                                <div className="mobilenumber reginput field reg-mobile updatenumber"><label style={{ fontSize: 16 }}>Update Mobile Number</label>
                                    <div className="intl-tel-input allow-dropdown separate-dial-code iti-sdc-3">
                                        <div className="flag-container">
                                            <div className="selected-flag" role="combobox" aria-owns="country-listbox" tabIndex={0} title="India (\u092D\u093E\u0930\u0924): +91">
                                                <div className="iti-flag in" />
                                                <div className="selected-dial-code">+91</div>
                                                <div className="iti-arrow" />
                                            </div>
                                            <ul className="country-list hide" id="country-listbox" aria-expanded="false" role="listbox" aria-activedescendant="iti-item-in">
                                                <li className="country standard active" id="iti-item-in" role="option" data-dial-code="91" data-country-code="in" aria-selected="true">
                                                    <div className="flag-box">
                                                        <div className="iti-flag in" />
                                                    </div><span className="country-name">India (भारत)</span><span className="dial-code">+91</span>
                                                </li>
                                            </ul>
                                        </div><input type="text" value={true} id="mobileget" className="mobnumber input-text required-entry updatemobnumber" placeholder="Mobile Number*" maxLength={12} autoComplete="off" style={{ width: "auto" }} fdprocessedid="oz0sgf" />
                                    </div><input type="hidden" id="expiretime" name="expiretime" value="1" />
                                    <div id="otpdiv" className="control otpbox"><input type="password" name="otp" id="otp" title="otp" className="input-text" autoComplete="off" maxLength={0} placeholder="OTP" style={{ display: "none", width: "32.5%" }} /></div><button id="updateotpbtn" name="updateotpbtn" type="button" className="mobbtn action primary updatesendotp" title="Get OTP" fdprocessedid="ay7zfv"><span>Get OTP</span></button> <button id="resendotpbtn" name="resendotpbtn" type="button" className="mobbtn action primary resendbtn" title="Resend OTP" style={{ display: "none" }}><span>Resend OTP</span></button>
                                    <div className="timerdivupd" style={{ display: "none" }}>Resend OTP in : <span id="timerupdotpverify" /></div><input type="hidden" value="+91" id="countryreg" name="countryreg" /><span id="reg-mob-error-msg" />
                                    <div className="notice">Mobile No. without Country Code i.e 9898989898</div>
                                    <div className="otp-content reginput field" id="regi_otp_cont">
                                        <div className="input-box otpbox"><input type="password" value={true} id="otp" className="input-text required-entry mage-error otp" maxLength={0} placeholder="OTP*" style={{ display: "none" }} /></div>
                                    </div>91XXXXXXXX46 <div id="existingnumberdiv">
                                        <p className="empty">Your Existing Verified Mobile Number is : 91XXXXXXXX46</p>
                                    </div>
                                    <div className="update_mob_btn"><input type="button" value="Update Mobile Number" className="updatemobbtns action primary confirmotpbtn mobbtn" style={{ display: "none" }} /><img className="sms-request-load" src="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/loader-1.gif" alt="Please Wait..." id="updatemobile_img" style={{ display: "none" }} /></div><input type="hidden" value="268356" className="userId" />
                                </div>
                                <div id="popup-component">
                                    <div data-bind="scope: 'popupComponent'">
                                        <div data-bind="template: getTemplate()">
                                            <div className="mobilenumber reginput field reg-mobile updatenumber" style={{ display: "flex", flexDirection: "column", width: 120, marginTop: 40 }}>
                                                <label style={{ fontSize: 16 }}>Communication</label>
                                                <label>
                                                    <input type="checkbox"  className="check-option" name="checkbox" data-bind="checked: customer_whatsapp_notification" id="customer_whatsapp_notification" value="1" /><span>WhatsApp</span>
                                                </label>
                                                <label>
                                                    <input type="checkbox"  className="check-option" name="checkbox" data-bind="checked: customer_sms_notification" id="customer_sms_notification" value="1" /><span>SMS</span>
                                                </label>
                                                <label>
                                                    <input type="checkbox"  className="check-option" name="checkbox" data-bind="checked: customer_email_notification" id="customer_email_notification" value="1" /><span>Email</span>
                                                </label>
                                            </div>
                                            <div className="popup-overlay" id="popup-content">

                                                <div className="">
                                                    <form id="submit-otp-form" method="post" data-bind="event: {submit: submitOTPLogin }, visible: !otpVerified() && !isNewCustomer()" noValidate>
                                                        { /* Show the OTP field only if OTP has been sent successfully */ }
                                                        <div className="fieldset submit-otp" data-bind="attr: {'data-hasrequired': $t('* Required Fields')}" data-hasrequired="* Required Fields">
                                                            <div data-bind="if: isErrorMessage" />
                                                            <div data-bind="if: isSuccessMessage" />
                                                            <div className="field otp required" data-bind="visible: otpSent() && !otpVerified()" style={{ display: "none" }}>
                                                                <div className="msgdesc">
                                                                    One Time Password (OTP) has been sent to your mobile
                                                                    <span id="regGetMobile" />, please enter the
                                                                    code.
                                                                </div>
                                                                <label className="label" htmlFor="otp">
                                                                    <span data-bind="i18n: 'Enter One Time Password'">Enter
                                                                        One Time Password</span>
                                                                </label>
                                                                <input name="login_type" type="hidden" value="mobile" />
                                                                <input name="mobile" type="hidden" data-bind="textInput: mobile,\n                                          attr: {autocomplete: window.authenticationPopup.autocomplete},\n                                          event: {input: updateMobileValue}" value="9811971746" autoComplete="off" />
                                                                <div className="control" style={{ display: "none" }}>
                                                                    <input name="otp" id="otp" type="number" pattern="\\d{4}" inputMode="numeric" autoComplete="one-time-code" data-bind="textInput: otp, attr: { maxlength: 4, placeHolder : $t('OTP')}, valueUpdate: 'input', event: { input: autoSubmitOTP } " className="input-text required-entry validate-number" data-validate="{required:true}" maxLength={4} placeholder="OTP" aria-required="true" />
                                                                </div>
                                                                <div className="otp-container">
                                                                    <input pattern="\\d*" name="otp1" onInput={event => { this.value = this.value.replace(/[^0-9]/g, ''); }} inputMode="numeric" type="text" data-bind="value: otp1, event: { input: moveToNext, keyup: moveBackOnDelete}" className="otp-input input-text validate-number" maxLength={1} />
                                                                    <input pattern="\\d*" name="otp2" onInput={event => { this.value = this.value.replace(/[^0-9]/g, ''); }} inputMode="numeric" type="text" data-bind="value: otp2, event: { input: moveToNext, keyup: moveBackOnDelete}" className="otp-input input-text validate-number" maxLength={1} />
                                                                    <input pattern="\\d*" name="otp3" onInput={event => { this.value = this.value.replace(/[^0-9]/g, ''); }} inputMode="numeric" type="text" data-bind="value: otp3, event: { input: moveToNext, keyup: moveBackOnDelete}" className="otp-input input-text validate-number" maxLength={1} />
                                                                    <input pattern="\\d*" name="otp4" onInput={event => { this.value = this.value.replace(/[^0-9]/g, ''); }} inputMode="numeric" type="text" data-bind="value: otp4, event: { input: moveToNext, keyup: moveBackOnDelete}" className="otp-input input-text validate-number" maxLength={1} />
                                                                </div>
                                                                <p className="note"><span>4-digit OTP </span></p>
                                                            </div>
                                                            { /* Show a countdown timer htmlFor OTP resend */ }
                                                            <div data-bind="if: isTimerRunning" />
                                                            <div data-bind="visible: noticeVisible" style={{ display: "none" }}>
                                                                <p className="notice" style={{ color: "red" }}>Resend limit
                                                                    exceeded. Please try again later.</p>
                                                            </div>
                                                            <div className="actions-toolbar" data-bind="visible: otpSent()" style={{ display: "none" }}>
                                                                <div className="primary">
                                                                    <button className="action secondary" name="submit-otp" id="submit-otp" data-bind="visible: !resend()">
                                                                        <span data-bind="i18n: 'Submit OTP'">Submit
                                                                            OTP</span>
                                                                    </button>
                                                                    <button className="action secondary" name="resend" id="resend-otp" data-bind="click: resendButton, visible: resend()" style={{ display: "none" }}>
                                                                        <span data-bind="i18n: 'Resend OTP'">Resend
                                                                            OTP</span>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>


                                            <style>{`
                                                input.otp-input {
                                                    width: 35px !important;
                                                    margin-right: 21px;
                                                }

                                                .otp-container {
                                                    display: flex;
                                                    flex-wrap: nowrap;
                                                    flex-direction: row;
                                                }

                                                /* Default Styles */
                                                .modal-popup.mobile-verification .modal-inner-wrap {
                                                    width: 500px !important;
                                                    height: 100% !important;
                                                }

                                                .modal-popup._inner-scroll.mobile-verification .modal-inner-wrap {
                                                    max-height: 371px !important;
                                                    transform: translateY(-50%);
                                                    top: 50%
                                                }

                                                .modal-popup._inner-scroll.mobile-verification .modal-content {
                                                    padding-bottom: 5px;
                                                }

                                                .modal-popup.mobile-verification .action-close {
                                                    border-radius: 100%;
                                                    width: 30px !important;
                                                    height: 30px !important;
                                                    background: #000;
                                                }

                                                .modal-popup.mobile-verification .action-close:before {
                                                    color: #fff;
                                                    padding-top: 3px;
                                                }

                                                .modal-popup .action-close:before,
                                                .modal-slide .action-close:before {
                                                    color: #fff !important;
                                                }

                                                .action-close {
                                                    border: 1px solid;
                                                    padding: 6px;
                                                    border-radius: 5px;
                                                }

                                                .modal-inner-wrap {
                                                    border-radius: 7px;
                                                }

                                                input[autocomplete=one-time-code] {
                                                    width: 9ch;
                                                    /* roughly */
                                                    text-align: center;
                                                    letter-spacing: .5ch;
                                                }

                                                input#otp {
                                                    font-size: 21px;
                                                }

                                                /* Responsive Styles */
                                                @media (max-width: 1200px) {
                                                    .modal-popup .modal-inner-wrap {
                                                        width: 70% !important;
                                                    }
                                                }

                                                @media (max-width: 992px) {
                                                    .modal-popup .modal-inner-wrap {
                                                        width: 90% !important;
                                                    }
                                                }

                                                @media (max-width: 768px) {
                                                    .modal-popup .modal-inner-wrap {
                                                        width: 95% !important;
                                                        height: auto !important;
                                                    }

                                                    .modal-popup .modal-content,
                                                    .modal-popup .modal-header {
                                                        padding-left: 21px;
                                                    }

                                                    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
                                                        margin-top: 52px;
                                                    }

                                                    .modal-popup._inner-scroll .modal-inner-wrap {
                                                        max-height: 70% !important;
                                                    }

                                                    input#otp {
                                                        font-size: 18px;
                                                    }
                                                }

                                                @media (max-width: 576px) {
                                                    .modal-popup.mobile-verification .modal-inner-wrap {
                                                        width: 90% !important;
                                                        height: auto !important;
                                                    }

                                                    .modal-popup._inner-scroll.mobile-verification .modal-inner-wrap {
                                                        top: 5%;
                                                    }

                                                    .action-close {
                                                        padding: 4px;
                                                        font-size: 14px;
                                                    }

                                                    input#otp {
                                                        font-size: 16px;
                                                    }
                                                }

                                                .text-red {
                                                    color: red;
                                                }

                                                .text-green {
                                                    color: green;
                                                }

                                                .center {
                                                    position: absolute;
                                                    width: 80px;
                                                    height: auto;
                                                    top: 50%;
                                                    left: 50%;
                                                }

                                                input[type="checkbox"] {
                                                    position: relative;
                                                    width: 40%;
                                                    background: gainsboro;
                                                    -webkit-appearance: none;
                                                    height: 20px;
                                                    background: #efefef;
                                                    outline: none;
                                                    border-radius: 30px;
                                                    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
                                                    transition: 0.5s;
                                                }

                                                input[type="checkbox"]:checked {
                                                    background: #0ebeff;
                                                }

                                                input[type="checkbox"]:before {
                                                    content: '';
                                                    position: absolute;
                                                    width: 20px;
                                                    height: 20px;
                                                    left: 2px;
                                                    background: #fff;
                                                    box-shadow: 0px 1px 6px 1px gainsboro;
                                                    border-radius: 50%;
                                                    top: 0px;
                                                    transform: scale(1.1);
                                                    transition: 0.5s;
                                                }

                                                input[type="checkbox"]:checked:before {
                                                    left: 27px;
                                                }
                                            `}</style>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="amsearch-overlay-block" data-bind=" scope: 'index = amsearch_overlay_section', mageInit: { 'Magento_Ui/js/core/app': { components: { 'amsearch_overlay_section': { component: 'Amasty_Xsearch/js/components/overlay' } } } }">
                        <div className="amsearch-overlay" data-bind=" event: { click: hide }, afterRender: initNode" />
                    </section>
                </div>





                {/* <div className="sidebar sidebar-main desktop-filter">
                    <div className="custom_menu_top_img">
                        <div className="user-image desk-user-image"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#000" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                        </path>
                                    </g>
                                </svg></a></div>
                    </div>

                    <div className="custom_menu_top">
                        <div className="mufti-userprofile"><a className="mblclosemenu dskclose logedin" href="javascript:void(0)"><svg width="24" height="24" viewBox="0 0 24 24" className="close-icon" fill="#ffffff">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#ffffff" d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z">
                                        </path>
                                    </g>
                                </svg></a>
                            <div className="user-image1"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M0 0h24v24H0z" opacity="0.05" />
                                            <path fill="#fff" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                            </path>
                                        </g>
                                    </svg></a></div>
                        </div>
                    </div>

                    <div className="block block-collapsible-nav">
                        <div className="title block-collapsible-nav-title"><strong> My Account</strong></div>
                        <div className="content block-collapsible-nav-content" id="block-collapsible-nav">
                            <ul className="nav items">
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item customer-order-list"><a className="my-orders" href="https://www.muftijeans.in/sales/order/history/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/my-orders.png" />My Orders</a>
                                </li>
                                <li className="nav item customer-profile"><a href="https://www.muftijeans.in/customer/account/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/profile.png" />Profile</a>
                                    <ul className="nav-sub-menu">
                                        <li className="nav item"><a href="https://www.muftijeans.in/customer/account/">Account
                                                Information</a></li>
                                        <li className="nav item"><a href="https://www.muftijeans.in/mobilelogin/index/updatemobile/">Update
                                                Communication</a></li>
                                    </ul>
                                </li>
                                <li className="nav item customer-manage-address"><a href="https://www.muftijeans.in/customer/address/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/manage-address.png" />Manage
                                        Address</a></li>
                                <li className="nav item customer-wishlist-list"><a href="https://www.muftijeans.in/wishlist/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/wishlist.png" />Wishlist</a>
                                </li>
                                <style>{`
                                    .block-dashboard-info .box-actions {
                                        display: none;
                                    }
                                `}</style>
                            </ul>
                        </div>
                    </div>
                </div> */}



                <Phonenav/>





            </div>
        </main>












    </>
  );
}

export default Updatecommunication;

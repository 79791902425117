import React, { useContext, useState } from 'react';
import { MyContext } from '../Context/Mycontext';

function Signupform() {

    const  context = useContext(MyContext)
    const {setMainSignpage,GetUser,url2} =context;
  const url = "http://localhost:5000";
  
  // State for form inputs
  const [formData, setFormData] = useState({
    firstname: '',
    phoneno: '',
    email: '',
    password: '',
    terms: false,
  });

  // State for validation errors
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.firstname.match(/^[a-zA-Z\s]+$/)) {
      newErrors.firstname = 'First name should only contain letters';
    }

    if (!formData.phoneno.match(/^\d{10}$/)) {
      newErrors.phoneno = 'Phone number should be 10 digits';
    }

    if (!formData.email.includes('@')) {
      newErrors.email = 'Please enter a valid email';
    }

    if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }

    if (!formData.terms) {
      newErrors.terms = 'You must agree to the terms and conditions';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      try {
        // API request to create account
        const response = await fetch(`${url2}/api/user/newuser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        const result = await response.json();
        if (response.ok) {
          alert('Account created successfully');
  
          // Store token in localStorage
          localStorage.setItem("token", result.autotoken);
          GetUser();
  
          // Reset form fields and errors
          setFormData({
            firstname: '',
            phoneno: '',
            email: '',
            password: '',
            terms: false,
          });
  
          setErrors({}); // Clear any error messages if needed
  
          // If there's a function to redirect after signup, call it (setMainSignpage in your case)
          setMainSignpage(false);
          
        } else {
          alert(result.message || 'Failed to create account');
        }
      } catch (error) {
        alert('An error occurred. Please try again.');
      }
    }
  };
  

  return (
    <form className="form form-login" onSubmit={handleSubmit} id="customer-registration-form">
      <div className="fieldset row login">
        {/* First Name Field */}
        <div className="field">
          <div className="control">
            <input
              name="firstname"
              type="text"
              className="input-text"
              placeholder="First Name"
              value={formData.firstname}
              onChange={handleChange}
            />
            {errors.firstname && <span className="error">{errors.firstname}</span>}
          </div>
        </div>

        {/* Phone Number Field */}
        <div className="field">
          <div className="control">
            <input
              name="phoneno"
              type="text"
              className="input-text"
              placeholder="Phone No."
              value={formData.phoneno}
              onChange={handleChange}
            />
            {errors.phoneno && <span className="error">{errors.phoneno}</span>}
          </div>
        </div>

        {/* Email Field */}
        <div className="field">
          <div className="control">
            <input
              name="email"
              type="email"
              className="input-text"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>

        {/* Password Field */}
        <div className="field">
          <div className="control">
            <input
              name="password"
              type="password"
              className="input-text"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <span className="error">{errors.password}</span>}
          </div>
        </div>

        {/* Terms & Conditions */}
        <div className="terms">
          <input
            type="checkbox"
            name="terms"
            checked={formData.terms}
            onChange={handleChange}
          />
          <label htmlFor="terms">
            I have read the terms and conditions and agree to receive newsletters.
          </label>
          {errors.terms && <span className="error">{errors.terms}</span>}
        </div>

        {/* Submit Button */}


        <div className="actions-toolbar">
          <div className="primary">
            <button type="submit" className="action action-login secondary">
              Create Account
            </button>
            
          </div>
        </div>
      </div>
    </form>

    
  );
}

export default Signupform;

import React, { useContext } from "react";
import { MyContext } from "../Context/Mycontext";
import Signupform from "./Signupform";
import Login from "./Loginform";
import Loginform from "./Loginform";
import Forgetpass from "./Forgetpass";

function Signup() {
  const context = useContext(MyContext);

  const { MainSignpage, setMainSignpage,Signupformpop,Loginformpop,forgetpasspop,setforgetpasspop } = context;

  return (
    <>
      <div className="modals-wrapper">
        <aside
          role="dialog"
          className="modal-popup popup-login customer-login-popup modal-slide _inner-scroll _show"
          aria-labelledby="modal-title-13"
          aria-describedby="modal-content-13"
          data-role="modal"
          data-type="popup"
          tabIndex={0}
          style={{ zIndex: "902" }}
        >
          <div data-role="focusable-start" tabIndex={0} />
          <div className="modal-inner-wrap custom-model-inner">
            <div className="custom-model-inner" data-role="focusable-scope">
              <div
                id="modal-content-13"
                className="custom-model-wrap"
                data-role="content"
              >
                <div
                  className="pop-up-content-wrap"
                  data-bind="afterRender: setModalElement, blockLoader: isLoading"
                >
                  <div
                    className="leftArea adjustHeight"
                    data-bind="html: leftPopup"
                  >
                    <div
                      data-content-type="html"
                      data-appearance="default"
                      data-element="main"
                      data-decoded="true"
                    >
                      <div className="banner">
                        <img
                          className="popup-ben-des"
                          src="https://d118ps6mg0w7om.cloudfront.net/media/wysiwyg/fit-in/430x201/Desktop_LogIn-Popup-OTP-with-Banner_1_.jpg?v=12001"
                          alt=""
                          style={{ display: "none" }}
                        />
                        <img
                          className="popup-ben-mob"
                          src="https://d118ps6mg0w7om.cloudfront.net/media/wysiwyg/fit-in/500x455/Mobile_LogIn-Popup-OTP-with-Banner_1_.jpg?v=12001"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="rightArea userArea mage-tabs-disabled rightAreaMain"
                    id="tabs"
                  >
                    <header className="modal-header">
                      <button
                        className="action-close"
                        onClick={() => {
                          setMainSignpage(false);
                        }}
                        data-role="closeBtn"
                        type="button"
                        fdprocessedid="89jjhm"
                      >
                        <span>Close</span>
                      </button>
                    </header>

                    <div className="numSection userDets">
                      <div
                        id="otp-login-step"
                        className="item content"
                        data-role="content"
                        role="tabpanel"
                        aria-hidden="false"
                        style={{}}
                      >
                        <div
                          className=""
                          data-bind="visible: !otpSent()"
                          style={{ display: "" }}
                        >
                          {/* Send OTP Form */}
                          {/* ko foreach: getRegion('messages') */}
                          {/* ko template: getTemplate() */}
                          <div
                            data-role="checkout-messages"
                            className="messages"
                            data-bind="visible: isVisible(), click: removeAll"
                          >
                            {/* ko foreach: messageContainer.getErrorMessages() */}
                            {/*/ko*/}
                            {/* ko foreach: messageContainer.getSuccessMessages() */}
                            {/*/ko*/}
                          </div>
                          {/* /ko */}
                          {/*/ko*/}
                          <div className="login-card">
                            <h2>Welcome</h2>
                            <p />
                            <div className="block-title">
                              <strong
                                id="block-customer-login-heading"
                                role="heading"
                                aria-level="2"
                                data-bind="i18n: ''"
                              />
                            </div>
                            {Signupformpop && <Signupform />}
                            {Loginformpop && <Loginform />}
                            {forgetpasspop && <Forgetpass />}

                          

                           
                           
                          
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="footerPopup" data-bind="html: footerPopup">
                      <div
                        data-content-type="html"
                        data-appearance="default"
                        data-element="main"
                        data-decoded="true"
                      >
                        <div className="otherLinks">
                          <div className="conditions">
                            <a href="/terms-and-condition">Terms of use</a>
                            <a href="/privacy">Privacy Policy</a>
                          </div>
                          <div className="copyright">
                            <span href="#">
                              {" "}
                              © 2024 Mufti. All Rights Reserved.
                            </span>
                          </div>
                        </div>
                      </div>

                      {/*                                     
                                    <div className="topbanner">
                                        <div className="iconSliderArea slick-initialized slick-slider">
                                            <div className="slick-list draggable">
                                                <div className="slick-track" style={{ opacity: "1", width: 315, transform: "translate3d(0px, 0px, 0px)" }}>
                                                    <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 85 }}>
                                                        <div>
                                                            <div className="slide" style={{ width: "100%", display: "inline-block" }}>
                                                                <div className="icons">
                                                                    <img src="https://d118ps6mg0w7om.cloudfront.net/media/wysiwyg/icon01.png" alt="" />
                                                                </div>
                                                                <p>Easy Returns</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 85 }}>
                                                        <div>
                                                            <div className="slide" style={{ width: "100%", display: "inline-block" }}>
                                                                <div className="icons">
                                                                    <img src="https://d118ps6mg0w7om.cloudfront.net/media/wysiwyg/icon02.png" alt="" />
                                                                </div>
                                                                <p>Free Shipping <br /> <span>(Order Above 999)</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="slick-slide slick-active" data-slick-index="2" aria-hidden="false" style={{ width: 85 }}>
                                                        <div>
                                                            <div className="slide" style={{ width: "100%", display: "inline-block" }}>
                                                                <div className="icons">
                                                                    <img src="https://d118ps6mg0w7om.cloudfront.net/media/wysiwyg/icon03.png" alt="" />
                                                                </div>
                                                                <p>Exclusive Updates</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-role="focusable-end" tabIndex={0} />
        </aside>

        <div className="modals-overlay" style={{ zIndex: "901" }} />
      </div>
    </>
  );
}

export default Signup;

import React from 'react'

function Checkorder2() {
  return (
    <>
    
    <div
            className="col-mp mp-12 hoverable"
            data-bind="scope: 'checkout.steps.billing-step'"
          >
            {/* ko template: getTemplate() */}
            {/* ko foreach: {data: elems, as: 'element'} */}
            {/* ko if: hasTemplate() */}
            {/* ko template: getTemplate() */}

            <div
              id="payment"
              role="presentation"
              className="checkout-payment-method"
            >
              <div className="step-title" data-role="title">
                <i className="fa fa-credit-card" />
                {/* ko if: window.checkoutConfig.oscConfig.isUsedMaterialDesign */}
                
                <span data-bind="i18n: 'Payment Methods'">Payment Methods</span>
              </div>
              <div
                id="checkout-step-payment"
                className="step-content col-mp mp-12"
                data-role="content"
                role="tabpanel"
                aria-hidden="false"
                data-bind="blockLoader: isLoading"
              >
                <form id="co-payment-form" className="form payments" noValidate>
                  <input
                    data-bind="attr: {value: getFormKey()}"
                    type="hidden"
                    name="form_key"
                    value="RPFFabubiPMW2A8N"
                  />
                  <fieldset className="fieldset">
                    <legend className="legend">
                      <span data-bind="i18n: 'Payment Information'">
                        Payment Information
                      </span>
                    </legend>
                    {/* ko foreach: getRegion('beforeMethods') */}
                    {/* ko template: getTemplate() */}
                    {/* ko foreach: {data: elems, as: 'element'} */}
                    {/* ko if: hasTemplate() */}
                    {/* ko template: getTemplate() */}
                    <div>
                      {/* ko foreach: {data: getRegion('place-order-recaptcha'), as: 'recaptcha'} */}
                      
                    </div>
                    <hr />
                    
                    
                    
                    
                    
                    <div
                      id="checkout-payment-method-load"
                      className="opc-payment"
                      data-bind="visible: isPaymentMethodsAvailable"
                    >
                      {/* ko foreach: getRegion('payment-methods-list') */}
                      {/* ko template: getTemplate() */}
                      {/* ko if: isPaymentMethodsAvailable() */}
                      <div className="items payment-methods">
                        {/* ko repeat: {foreach: paymentGroupsList, item: '$group'} */}
                        <div className="payment-group" data-repeat-index="0">
                          {/* ko if: regionHasElements($group().displayArea) */}
                          <div
                            className="step-title"
                            data-role="title"
                            data-bind="i18n: getGroupTitle($group)"
                          >
                            Payment Method
                          </div>
                          
                          {/* ko foreach: {data: getRegion($group().displayArea), as: 'method'} */}
                          {/* ko template: getTemplate() */}
                          <div
                            className="payment-method _active"
                            data-bind="css: {'_active': (getCode() == isChecked())}"
                          >
                            <div className="payment-method-title field choice">
                              <input
                                type="radio"
                                name="payment[method]"
                                className="radio"
                                data-bind="attr: {'id': getCode()}, value: getCode(), checked: isChecked, click: selectPaymentMethod, visible: isRadioButtonVisible()"
                                id="razorpay"
                                value="razorpay"
                                style={{ display: "none" }}
                              />
                              <label
                                data-bind="attr: {'for': getCode()}"
                                className="label"
                                htmlFor="razorpay"
                              >
                                <img
                                  src="https://cdn.razorpay.com/logo.png"
                                  width="20%"
                                  alt="Razorpay"
                                  className="payment-icon"
                                />
                              </label>
                            </div>

                            <div className="payment-method-content">
                              {/* ko foreach: getRegion('messages') */}
                              {/* ko template: getTemplate() */}
                              <div
                                data-role="checkout-messages"
                                className="messages"
                                data-bind="visible: isVisible(), click: removeAll"
                              >
                                {/* ko foreach: messageContainer.getErrorMessages() */}
                                {/*/ko*/}
                                {/* ko foreach: messageContainer.getSuccessMessages() */}
                                {/*/ko*/}
                              </div>
                              
                              {/*/ko*/}
                              <div className="payment-method-billing-address">
                                {/* ko foreach: $parent.getRegion(getBillingAddressFormName()) */}
                                {/*/ko*/}
                              </div>
                              <div className="checkout-agreements-block">
                                {/* ko foreach: $parent.getRegion('before-place-order') */}
                                {/* ko template: getTemplate() */}
                                {/* ko foreach: { data: elems, as: 'element' } */}
                                
                                
                                {/*/ko*/}
                              </div>
                              <div className="actions-toolbar">
                                <div className="primary">
                                  <button
                                    className="action primary checkout"
                                    type="submit"
                                    data-bind="\n                    click: preparePayment,\n                    attr: {title: $t('Pay Now')},\n                    css: {disabled: !isPlaceOrderActionAllowed()},\n                    enable: (getCode() == isChecked())\n                    "
                                    title="Pay Now"
                                  >
                                    <span data-bind="i18n: 'Pay Now'">
                                      Pay Now
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          
                        </div>
                        
                      </div>
                      
                      {/* ko ifnot: isPaymentMethodsAvailable() */}
                      
                      
                      
                    </div>
                    <div
                      className="no-quotes-block"
                      data-bind="visible: isPaymentMethodsAvailable() == false"
                      style={{ display: "none" }}
                    >
                      {/* ko i18n: 'No Payment method available.'*/}
                      <span>No Payment method available.</span>
                      
                    </div>
                    {/* ko if: errorValidationMessage().length > 0 */}
                    
                  </fieldset>
                </form>
                <div className="osc-payment-after-methods">
                  {/* ko foreach: getRegion('afterMethods') */}
                  {/* ko template: getTemplate() */}
                  {/* ko foreach: {data: elems, as: 'element'} */}
                  {/* ko if: hasTemplate() */}
                  {/* ko template: getTemplate() */}
                  <div
                    className="payment-option _collapsible opc-payment-additional discount-code"
                    data-bind="mageInit: {'collapsible':{'openedState': '_active'}}"
                    data-collapsible="true"
                    role="tablist"
                  >
                    <div
                      className="payment-option-title field choice"
                      data-role="title"
                      role="tab"
                      aria-selected="false"
                      aria-expanded="false"
                      tabIndex={0}
                    >
                      <span
                        className="action action-toggle"
                        id="block-discount-heading"
                        role="heading"
                        aria-level="2"
                      >
                        {/* ko i18n: 'Apply Discount Code'*/}
                        <span>Apply Discount Code</span>
                        
                      </span>
                    </div>
                    <div
                      className="payment-option-content"
                      data-role="content"
                      role="tabpanel"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    >
                      {/* ko foreach: getRegion('messages') */}
                      {/* ko template: getTemplate() */}
                      <div
                        data-role="checkout-messages"
                        className="messages"
                        data-bind="visible: isVisible(), click: removeAll"
                      >
                        {/* ko foreach: messageContainer.getErrorMessages() */}
                        {/*/ko*/}
                        {/* ko foreach: messageContainer.getSuccessMessages() */}
                        {/*/ko*/}
                      </div>
                      
                      {/*/ko*/}
                      <form
                        className="form form-discount"
                        id="discount-form"
                        data-bind="blockLoader: (typeof isLoading === 'undefined') ? isBlockLoading : isLoading"
                      >
                        <div className="payment-option-inner">
                          <div className="field">
                            <label className="label" htmlFor="discount-code">
                              <span data-bind="i18n: 'Enter discount code'">
                                Enter discount code
                              </span>
                            </label>
                            <div className="control">
                              <input
                                className="input-text"
                                type="text"
                                id="discount-code"
                                name="discount_code"
                                data-validate="{'required-entry':true}"
                                data-bind="value: couponCode, attr:{disabled:isApplied(),placeHolder: $t('Enter discount code')} "
                                placeHolder="Enter discount code"
                                fdprocessedid="5jn5a"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="actions-toolbar">
                          <div className="primary">
                            {/* ko ifnot: isApplied() */}
                            <button
                              className="action action-apply"
                              type="submit"
                              data-bind="'value': $t('Apply Discount'), click: apply"
                              value="Apply Discount"
                              fdprocessedid="ffvdwl"
                            >
                              <span>
                                {/* ko i18n: 'Apply Discount'*/}
                                <span>Apply Discount</span>
                                
                              </span>
                            </button>
                            
                            {/* ko if: isApplied() */}
                            
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  
                  

                  {/* ko if: hasTemplate() */}
                  {/* ko template: getTemplate() */}
                  <div
                    id="coupon-link-cart"
                    className="view-coupon-list-link"
                    data-trigger="open-coupon-list-cart"
                  >
                    <a href="javascript:void(0);" className="view-coupon">
                      {/* ko i18n: 'View More Coupon'*/}
                      <span>View More Coupon</span>
                      
                    </a>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </div>
                  
                  

                  {/* ko if: hasTemplate() */}
                  {/* ko template: getTemplate() */}
                  
                  
                  
                  
                  
                </div>
              </div>
            </div>
            
            
            
            
          </div>
    
    
    
    </>
  )
}

export default Checkorder2

import React from 'react'

function Searchbarproduct({product}) {
  return (
<>

<li
                                              className="amsearch-item product-item"
                                              data-click-url="https://www.muftijeans.in/olive-graphic-print-t-shirt-mfk-9422-q-olive.html"
                                            >
                                              <a
                                                className="amsearch-image"
                                                title="Olive Graphic Print T-Shirt"
                                                href="https://www.muftijeans.in/olive-graphic-print-t-shirt-mfk-9422-q-olive.html"
                                              >
                                                <span
                                                  className="product-image-container product-image-container-85465"
                                                  style={{ width: 1000 }}
                                                >
                                                  <span
                                                    className="product-image-wrapper"
                                                    style={{
                                                      paddingBottom: "133.3%",
                                                    }}
                                                  >
                                                    <img
                                                      className="product-image-photo"
                                                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/1000x1333/16-04-24_mfk-9422-q-17-olive_1_mfk-9422-q-17-olive.jpg"
                                                      loading="lazy"
                                                      width="1000"
                                                      height="1333"
                                                      alt="Olive Graphic Print T-Shirt"
                                                    />
                                                  </span>
                                                </span>
                                                <style>{`
                                                                                            .product-image-container-85465 {
                                                                                                width: 1000px;
                                                                                            }

                                                                                            .product-image-container-85465 span.product-image-wrapper {
                                                                                                padding-bottom: 133.3%;
                                                                                            }
                                                                                        `}</style>
                                              </a>
                                              <section className="amsearch-description product details product-item-details">
                                                <a
                                                  className="amsearch-link product-item-link"
                                                  href="https://www.muftijeans.in/olive-graphic-print-t-shirt-mfk-9422-q-olive.html"
                                                  title="Olive Graphic Print T-Shirt"
                                                >
                                                  Olive Graphic Print T-Shi...
                                                </a>
                                                <div className="amsearch-wrapper-inner">
                                                  <div
                                                    className="price-box price-final_price"
                                                    data-role="priceBox"
                                                    data-product-id="85465"
                                                    data-price-box="product-id-85465"
                                                  >
                                                    <span className="normal-price">
                                                      <span className="price-container price-final_price tax weee">
                                                        <span className="price-label" />
                                                        <span
                                                          id="product-price-85465"
                                                          data-price-amount="699"
                                                          data-price-type="finalPrice"
                                                          className="price-wrapper "
                                                        >
                                                          <span className="price">
                                                            ₹699
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>{" "}
                                                    <span className="old-price sly-old-price no-display">
                                                      <span className="price-container price-final_price tax weee">
                                                        <span className="price-label">
                                                          Regular Price
                                                        </span>
                                                        <span
                                                          id="old-price-85465"
                                                          data-price-amount="1399"
                                                          data-price-type="oldPrice"
                                                          className="price-wrapper "
                                                        >
                                                          <span className="price">
                                                            ₹1,399
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="product actions product-item-actions"
                                                    item-actions="1"
                                                    data-amsearch-js="product-item"
                                                  >
                                                    <div className="actions-primary">
                                                      <form
                                                        className="amasty-xsearch-form"
                                                        data-role="tocart-form"
                                                        action="https://www.muftijeans.in/checkout/cart/add/uenc/aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9hbWFzdHlfeHNlYXJjaC9hdXRvY29tcGxldGUvaW5kZXgvP3E9JTIwc2hpcnQmdWVuYz1hSFIwY0hNNkx5OTNkM2N1YlhWbWRHbHFaV0Z1Y3k1cGJpOXphR2x5ZEhNdFptOXlMVzFsYmk1b2RHMXMmZm9ybV9rZXk9Qkc2dk9sUHc0OHQzRlg3byZfPTE3MjgxMzIwNjg0NzI%2C/product/85465/"
                                                        method="post"
                                                      >
                                                        <input
                                                          type="hidden"
                                                          name="product"
                                                          value="85465"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="uenc"
                                                          value="aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9jaGVja291dC9jYXJ0L2FkZC91ZW5jL2FIUjBjSE02THk5M2QzY3ViWFZtZEdscVpXRnVjeTVwYmk5aGJXRnpkSGxmZUhObFlYSmphQzloZFhSdlkyOXRjR3hsZEdVdmFXNWtaWGd2UDNFOUpUSXdjMmhwY25RbWRXVnVZejFoU0ZJd1kwaE5Oa3g1T1ROa00yTjFZbGhXYldSSGJIRmFWMFoxWTNrMWNHSnBPWHBoUjJ4NVpFaE5kRnB0T1hsTVZ6RnNZbWsxYjJSSE1YTW1abTl5YlY5clpYazlRa2MyZGs5c1VIYzBPSFF6UmxnM2J5WmZQVEUzTWpneE16SXdOamcwTnpJJTJDL3Byb2R1Y3QvODU0NjUv"
                                                        />
                                                        <input
                                                          name="form_key"
                                                          type="hidden"
                                                          value="Oh5Dn3Bcs3geVw09"
                                                        />
                                                        <button
                                                          type="submit"
                                                          title="ADD TO BAG"
                                                          className="action tocart primary"
                                                          fdprocessedid="8v9iee"
                                                        >
                                                          <span>
                                                            ADD TO BAG
                                                          </span>
                                                        </button>
                                                      </form>
                                                    </div>
                                                    <div
                                                      data-role="add-to-links"
                                                      className="actions-secondary"
                                                    >
                                                      <a
                                                        href="#"
                                                        className="amsearch-button -clear -wishlist -icon"
                                                        title="Add to Wish List"
                                                        aria-label="Add to Wish List"
                                                        role="button"
                                                      />
                                                      <a
                                                        href="#"
                                                        className="amsearch-button -clear -compare -icon"
                                                        title="Add to Compare"
                                                        aria-label="Add to Compare"
                                                        role="button"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            </li>






</>
  )
}

export default Searchbarproduct

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkorder2 from "./Checkorder2";
import { useLocation } from "react-router-dom";
import { MyContext } from "../Context/Mycontext";

function Checkorder() {

  const navigate=useNavigate();

  const context = useContext(MyContext);
  const { addressid } = context; // Extracting the address ID from context

  const location = useLocation();
  const [productId, setProductId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [size, setSize] = useState('');
  const [prices, setPrices] = useState(''); // Fixed capitalization to 'setPrices'
  const [status, setStatus] = useState('Pending'); // You can set the status initially as needed

  useEffect(() => {
    // Extracting the product ID from the URL path
    const pathParts = location.pathname.split('/');
    const id = pathParts[pathParts.length - 1];
    setProductId(id);

    // Extracting query parameters (quantity, size, price)
    const searchParams = new URLSearchParams(location.search);
    const quantityParam = searchParams.get('quantity');
    const sizeParam = searchParams.get('size');
    const priceParam = searchParams.get('price');

    setQuantity(quantityParam);
    setSize(sizeParam);
    setPrices(priceParam);
  }, [location]);

  useEffect(() => {
    // For debugging purposes
    console.log('Product ID:', productId);
    console.log('Quantity:', quantity);
    console.log('Size:', size);
    console.log('Address ID:', addressid);
  }, [productId, quantity, size, addressid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const token = localStorage.getItem('token');
  
    // Assuming totalprice is calculated as price * quantity
    const totalprice = prices * quantity;
     // Generating current date in ISO format
    const status = 'Pending'; // You can adjust this status based on your business logic
  
    const data = {
      productid: productId,
      addressid,
      size,  // Ensuring size is a number
      quantity: Number(quantity), // Ensuring quantity is a number
      totalprice,
      
      status
    };
  
    console.log(data, "this is order data");
  
    try {
      const response = await fetch('https://dhancash.uw.r.appspot.com/api/userorder/userorder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auto-token': `${token}`,
        },
        body: JSON.stringify(data),  
      });
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Order submitted successfully:', result);
        navigate("/thanks");

      } else {
        console.log('Error submitting order:', result);
        alert('Error submitting order:',result);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };
  


  return (
    <>
      <div className="col-mp mp-6 mp-sm-7 mp-xs-12">
        <div className="row-mp">
          <Checkorder2 />

          <div className="col-mp mp-12" >
            

            <div id="opc-sidebar">
              
              <div className="order-summary hoverable">
                <div className="step-title" data-role="title">
                  <i className="fa fa-check-square" />
                  
                  <span >Order Summary</span>
                </div>
                <div
                  className="opc-block-summary step-content"
                  data-bind="blockLoader: isLoading"
                >
                  <div
                    className="block items-in-cart active"
                    
                  >
                    <div
                      className="title"
                      data-role="title"
                      data-bind="visible: window.checkoutConfig.oscConfig.isShowItemListToggle"
                      role="tab"
                      aria-selected="false"
                      aria-expanded="true"
                      tabIndex={0}
                    >
                      <strong role="heading">
                       
                       
                        <span>1</span>

                        
                        <span data-bind="i18n: 'Item in Cart'">
                          Item in Cart
                        </span>

                      </strong>
                    </div>
                    <div
                      className="content minicart-items"
                      data-role="content"
                      role="tabpanel"
                      aria-hidden="false"
                    >
                      <div className="minicart-items-wrapper overflowed">
                        <table
                          id="checkout-review-table"
                          className="data-table"
                        >
                          <colgroup>
                            <col />
                            <col width="1" />
                            <col width="1" />
                            <col width="1" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th
                                className="a-center"
                                
                              >
                                Product Name
                              </th>
                              <th className="a-center" >
                                Quantity
                              </th>
                              <th
                                className="a-center"
                                
                              >
                                Subtotal
                              </th>
                              <th className="a-center" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="product-item">
                             

                              <td className="a-left product">
                                
                                <span
                                  className="product-image-container"
                                  
                                  style={{ height: 225, width: 150 }}
                                >
                                  <span className="product-image-wrapper ram">
                                    <img
                                      data-bind="attr: {'src': getSrc($parents[1]), 'width': getWidth($parents[1]), 'height': getHeight($parents[1]), 'alt': getAlt($parents[1]), 'title': getAlt($parents[1]) }"
                                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/300x450/1_mfs-15355-t-02-white.jpg"
                                      width="300"
                                      height="450"
                                      alt="White Abstract Print Stretch Slim Fit Casual Shirt"
                                      title="White Abstract Print Stretch Slim Fit Casual Shirt"
                                    />
                                  </span>
                                </span>

                                <div className="product-item-detail">
                                  <div className="product-item-inner">
                                    <div className="product-item-name-block">
                                      
                                      <a
                                        data-bind="attr:{href: getProductUrl($parent)}"
                                        target="_blank"
                                        href="https://www.muftijeans.in/white-abstract-print-stretch-slim-fit-casual-shirt-mfs-15355-t-white.html"
                                      >
                                        <strong
                                          className="product-item-name"
                                          data-bind="text: $parent.name"
                                        >
                                          White Abstract Print Stretch Slim Fit
                                          Casual Shirt
                                        </strong>
                                      </a>

                                     
                                    </div>
                                  </div>
                                  
                                  <div
                                    className="product options"
                                    
                                  >
                                    <span
                                      data-role="title"
                                      className="toggle"
                                      
                                      tabIndex={0}
                                    >
                                      
                                      <span>View Details</span>
                                    </span>
                                    <div
                                      data-role="content"
                                      className="content"
                                      role="tabpanel"
                                      aria-hidden="true"
                                      style={{ display: "none" }}
                                    >
                                      <strong className="subtitle">
                                        
                                        <span>Options Details</span>
                                      </strong>
                                      <dl className="item-options">
                                        
                                        <dt
                                          className="label"
                                          data-bind="text: label"
                                        >
                                          Size
                                        </dt>
                                       
                                        <dd
                                          className="values"
                                          data-bind="html: value"
                                        >
                                          {size}
                                        </dd>

                                        <dt
                                          className="label"
                                          data-bind="text: label"
                                        >
                                          Colours
                                        </dt>
                                        
                                        <dd
                                          className="values"
                                          
                                        >
                                          White
                                        </dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="mp-brand-item"
                                  style={{ marginLeft: 100 }}
                                ></div>
                              </td>
                              <td className="a-center details-qty">
                                <div className="qty-wrapper">
                                  <a
                                    className="button-action minus"
                                    data-bind="click: minusQty"
                                    title="Minus"
                                  >
                                    <i className="fa fa-minus"  onClick={()=>{
                                      if(quantity>1){
                                        setQuantity(quantity-1)
                                    }
                                      
                                    }} />
                                  </a>
                                  <div className="qty-wrap">
                                    <input
                                      className="item_qty input-text update value"
                                      name="item_qty"
                                      value={quantity}
                                      id="2777375"
                                      
                                    />
                                  </div>
                                  <a
                                    className="button-action plus"
                                    data-bind="click: plusQty"
                                    title="Plus"
                                  >
                                    <i className="fa fa-plus" onClick={()=>{
                                      
                                      setQuantity(+quantity + 1);
                                    
                                      
                                    }}  />
                                  </a>
                                </div>
                              </td>
                              <td className="a-center subtotal">
                                <div className="subtotal">
                                  <span
                                    className="price-including-tax"
                                    data-bind="attr:{'data-label': $t('Incl. Tax')}"
                                    data-label="Incl. Tax"
                                  >
                                    <span className="cart-price">
                                      <span
                                        className="price"
                                        data-bind="text: getFormattedPrice(getRowDisplayPriceInclTax($parents[2]))"
                                      >
                                        ₹{prices*quantity}
                                      </span>
                                    </span>
                                  </span>
                                </div>

                                <div className="mp-brand-item"></div>
                              </td>
                              <td className="a-center last button-remove">
                                <div className="remove-wrapper">
                                  <a className="button-action edit">
                                    <i className="fa fa-pencil" />
                                  </a>
                                  <a
                                    className="button-action remove"
                                    data-bind="click:function(){removeItem($parent.item_id)}"
                                  >
                                    <i className="fa fa-trash-o" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <table className="data table table-totals">
                    <caption
                      className="table-caption"
                      data-bind="i18n: 'Order Summary'"
                    >
                      Order Summary
                    </caption>
                    <tbody>
                      <tr className="totals sub">
                        <th
                          data-bind="i18n: title"
                          className="mark"
                          scope="row"
                        >
                          Bag Subtotal
                        </th>
                        <td className="amount">
                          <span
                            className="price"
                            data-bind="text: getValueInclTax(), attr: {'data-th': title}"
                            data-th="Bag Subtotal"
                          >
                            ₹{prices*quantity}
                          </span>
                        </td>
                      </tr>

                      <tr className="totals shipping excl">
                        <th className="mark" scope="row">
                          <span className="label" data-bind="i18n: title">
                            Shipping
                          </span>

                          <span
                            className="value"
                            data-bind="text: getShippingMethodTitle()"
                          >
                            Free Shipping - Free
                          </span>
                        </th>
                        <td className="amount">
                          <span
                            className="price"
                            data-bind="text: getValue(), attr: {'data-th': title}"
                            data-th="Shipping"
                          >
                            ₹0
                          </span>
                        </td>
                      </tr>

                      <tr className="grand totals">
                        <th className="mark" scope="row">
                          <strong data-bind="i18n: title">Order Total</strong>
                        </th>
                        <td
                          data-bind="attr: {'data-th': title}"
                          className="amount"
                          data-th="Order Total"
                        >
                          <strong>
                            <span
                              className="price"
                              
                            >
                              ₹{prices*quantity}
                            </span>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/*/ko*/}

              <div className="opc-block-shipping-information"></div>

              <div id="co-place-order-area">
                <div className="osc-place-order-wrapper col-mp mp-lg-6 mp-md-6 mp-sm-12 mp-xs-12">
                  <div className="checkout-agreements-block mp-12">
                    <form
                      id="co-place-order-agreement"
                      className="form"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div data-role="checkout-agreements">
                        <div
                          className="checkout-agreements fieldset"
                          data-bind="visible: isVisible"
                        >
                          <div className="checkout-agreement field choice required">
                            <input
                              type="checkbox"
                              className="required-entry"
                              id="agreement__1"
                              name="agreement[1]"
                              value="1"
                              checked={true}
                            />
                            <label
                              className="label"
                              data-bind="attr: {'for': $parent.getCheckboxId($parentContext, agreementId)}"
                              htmlFor="agreement__1"
                            >
                              <button
                                type="button"
                                className="action action-show"
                                data-bind="click: function(data, event) { return $parent.showContent(data, event) }"
                              >
                                <span data-bind="html: checkboxText">
                                  I have read the terms and conditions and agree
                                  to receive newsletters, offers and
                                  personalized communication via Whatsapp, SMS &
                                  Email from Muftijeans.
                                </span>
                              </button>
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="actions-toolbar">
                    <div className="place-order-primary">
                      <button
                        className="action primary checkout"
                        type="submit"
                        title="Place Order"
                        form="co-place-order-agreement"
                      >
                        <span>Place Order</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mp-clear" />
              </div>
            </div>
          </div>
        </div>
      </div>



      <style>{`
                 .agreement:hover {
                   text-decoration: underline;
                }
                 `}</style>
    </>
  );
}

export default Checkorder;

import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../Context/Mycontext";

function Checkaddress() {
  const context=useContext(MyContext);

  const {addressid,setaddressid}=context;
  const [addresses, setAddresses] = useState([]);
  const [selects, setselects] = useState(null);
  const token = localStorage.getItem("token");

  // Assuming the token is stored in localStorage

  useEffect(() => {
    // Function to fetch addresses from the API
    const fetchAddresses = async () => {
      try {
        const response = await fetch(
          "https://dhancash.uw.r.appspot.com/api/Address/getaddress",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auto-token": `${token}`, // Include the token in the headers
            },
          }
        );

        const result = await response.json();
        console.log(result, "API response"); // Log the entire response for debugging

        if (response.ok) {
          setAddresses(result);
        } else {
          console.error("Failed to fetch addresses:", result.message);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses(); // Call the function to fetch addresses when the component mounts
  }, [token]);

  return (
    <>
      <div
        className="col-mp mp-12"
        data-bind="scope: 'checkout.steps.shipping-step.shippingAddress'"
      >
        <div
          id="shipping"
          className="checkout-shipping-address"
          data-bind="fadeVisible: visible()"
        >
          <div className="step-title" data-role="title">
            <i className="fa fa-home" />

            <span data-bind="i18n: 'Shipping Address'">Shipping Address</span>
          </div>
          <div
            id="checkout-step-shipping"
            className="step-content"
            data-role="content"
          >
            <div className="field addresses">
              <div className="control">
                <div className="shipping-address-items">
                  {addresses.map((address, index) => (
                    <div
                      key={index}
                      className={`shipping-address-item ${
                        selects === address._id ? "selected-item" : ""
                      }`}
                      onClick={() => {
                        setselects(address._id);
                        setaddressid(address._id);
                        console.log(selects, "this ishs isnla");
                      }}
                    >
                      {address.name}
                      <br />
                      {address.address}
                      <br />
                      {address.landmark}
                      <br />
                      {address.city} {address.state} {address.pincode}
                      <br />
                      {address.phone}
                      <br />
                      <button
                        type="button"
                        className="action edit-address-link"
                      >
                        <span>Edit</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkaddress;

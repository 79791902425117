import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Phonenav from "./Phonenav";
import { MyContext } from "../Context/Mycontext";

function Address() {
  const context = useContext(MyContext);
  const { navshow, token, settoken } = context;

  // State to store form input values
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    landmark: "",
    state: "",
    country: "India", // Default value for country
    city: "",
    pincode: "",
  });

  useEffect(() => {
    const removeAndAddClass = () => {
      const body = document.body;
      body.className = "";
      body.classList.add(
        "account",
        "customer-address-form",
        "page-layout-2columns-left"
      );
    };
    removeAndAddClass();
  }, []);

  // Handle input change and update state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData, "mukeem address data");

    // Get the token from localStorage
    const token = localStorage.getItem("token"); // Assuming 'token' is stored in localStorage
    console.log(token, "this is token");

    try {
      const response = await fetch(
        "https://dhancash.uw.r.appspot.com/api/Address/address",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auto-token": `${token}`, // Set the auth token in the headers
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        alert("Address saved successfully!");

        // Reset the formData after successful submission
        setFormData({
          name: "",
          phone: "",
          address: "",
          pincode: "",
          country: "IN", // Reset to default country if needed
          city: "",
          landmark: "",
          state: "",
        });
      } else {
        alert("Failed to save address: " + result.message);
      }
    } catch (error) {
      console.error("Error saving address:", error);
      alert("An error occurred while saving the address.");
    }
  };

  return (
    <>
      <main id="maincontent" className="page-main">
        {" "}
        <a id="contentarea" tabIndex={-1} />
        <div className="page messages">
          <div data-placeholder="messages" />
          <div data-bind="scope: 'messages'"></div>
        </div>
        <div className="columns">

          
          <div className="column main"
            style={{ display: navshow === "active" ? "none" : "" }}
          >
            <div className="page-title-wrapper">
              <h1 className="page-title">
                <span className="base" data-ui-id="page-title-wrapper">
                  Add New Address
                </span>
              </h1>
            </div>
            <input name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" />

            <span />
            <form className="form-address-edit" onSubmit={handleSubmit}>
              <fieldset className="fieldset">
                <div className="block-title--myac">
                  <h3>Contact Information</h3>
                </div>

                <div className="row customer-name-fields">
                  <div className="field field-name-firstname required col-lg-12">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        <span>Full Name</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="input-text required-entry"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="field telephone required">
                  <label className="label" htmlFor="phone">
                    <span>Phone Number</span>
                  </label>
                  <div className="control">
                    <input
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                      maxLength={10}
                    />
                  </div>
                </div>

                <div className="field street required">
                  <label htmlFor="address" className="label">
                    <span>Street Address</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                    />
                  </div>
                </div>

                <div className="field required">
                  <label htmlFor="landmark" className="label">
                    <span>Landmark</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      name="landmark"
                      value={formData.landmark}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                    />
                  </div>
                </div>

                <div className="field required">
                  <label htmlFor="state" className="label">
                    <span>State</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                    />
                  </div>
                </div>

                <div className="field required">
                  <label className="label" htmlFor="city">
                    <span>City</span>
                  </label>
                  <div className="control">
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                    />
                  </div>
                </div>

                <div className="field zip required">
                  <label className="label" htmlFor="pincode">
                    <span>Zip/Postal Code</span>
                  </label>
                  <div className="control">
                    <input
                      type="number"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                      className="input-text required-entry"
                      required
                    />
                  </div>
                </div>
              </fieldset>

              <div className="actions-toolbar">
                <div className="primary">
                  <button
                    type="submit"
                    className="action save btn btn-primary"
                    title="Save Address"
                  >
                    <span>Save Address</span>
                  </button>
                  <Link
                    className="action btn btn-secondary margin-left10 back"
                    to="/account"
                  >
                    <span>Go back</span>
                  </Link>
                </div>
              </div>
            </form>

            <section className="amsearch-overlay-block">
              <div
                className="amsearch-overlay"
                data-bind=" event: { click: hide }, afterRender: initNode"
              />
            </section>
          </div>

         
          <Phonenav />
        </div>
      </main>
    </>
  );
}

export default Address;

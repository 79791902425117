import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "../Context/Mycontext";

function Sidebar() {

  const navigate = useNavigate();
  const context = useContext(MyContext);
  const {
    MainSidebar,
    setMainSidebar,
    userdetails,
    token,
    setMainSignpage,
    replaceHeadContent,
    setSignupformpop,
    setLoginformpop
  } = context;

  const [userprofile, setuserprofile] = useState("none");
  const [userprofilesign, setuserprofilesign] = useState("block");

  useEffect(() => {
    if (token) {
      // If token exists, block the user profile (you can also set it to a different value if needed)
      setuserprofile("block");
      setuserprofilesign("none");
    } else {
      // If token is null, set user profile to none (invisible)
      setuserprofile("none");
      setuserprofilesign("block");
    }
  }, [token]);

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem("token");
    navigate("/");

    // Optionally navigate to a different page or show a message
    console.log("Logged out successfully.");
  };

  const signuppop = () => {
    setMainSignpage(true);
  };

  return (
    <>
      <div className="modals-wrapper">
        <aside
          role="dialog"
          className="modal-slide mufti-megamenu _inner-scroll _show"
          aria-describedby="modal-content-0"
          data-role="modal"
          data-type="slide"
          tabIndex={0}
          style={{ zIndex: "902" }}
        >
          <div data-role="focusable-start" tabIndex={0} />
          <div className="modal-inner-wrap" data-role="focusable-scope">
            <header className="modal-header">
              <button
                className="action-close"
                data-role="closeBtn"
                type="button"
                onClick={() => {
                  setMainSidebar(false);
                }}
              >
                <span>Close</span>
              </button>
            </header>
            <div
              id="modal-content-0"
              className="modal-content"
              data-role="content"
            >
              <div id="modal">
                <div className="modal-body-content">
                  <div
                    className="mufti-userprofile"
                    style={{ display: userprofile }}
                  >
                    <div className="user-image">
                      <a className="naviLevel" href="">
                        <svg width="36" height="36" viewBox="0 0 24 24">
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h24v24H0z" opacity="0.05" />
                            <path
                              fill="#fff"
                              d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z"
                            ></path>
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div className="user-links">
                      <a className="naviLevel user-name" href="">
                        mukeem md
                      </a>
                    </div>
                  </div>
                  <div
                    data-content-type="html"
                    data-appearance="default"
                    data-element="main"
                    data-decoded="true"
                  >
                    <div className="acc">
                      <div className="acc-nomenu">
                        <ul className="menulink megasubmenu">
                          <li onClick={() => {
                  setMainSidebar(false);
                }}>
                            <a title="Home" href="/">
                              Home
                            </a>
                          </li>

                          <li onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link title="All Same" to="/product?category=all">
                              <b>All Same</b>
                            </Link>
                          </li>

                          <li onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link
                              title="400050 (Oversized)"
                              to="/product?category=oversize"
                            >
                              4 TRIPLE O FIVE O (Oversized)
                            </Link>
                          </li>
                          <li className="first" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link
                              title="New Arrival"
                              to="/product?category=newarrival"
                            >
                              New Arrival{" "}
                              <em style={{ color: "#078426", fontSize: 16 }}>
                                AW-24
                              </em>
                            </Link>
                          </li>

                          <li className="submenu-content" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <h3>
                              <Link to="/product?category=shirts">Shirts</Link>
                            </h3>
                          </li>
                          <li className="submenu-content" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <h3>
                              <Link to="/product?category=jeans">Jeans</Link>
                            </h3>
                          </li>
                          <li className="submenu-content" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <h3>
                              <Link to="/product?category=tshirt">
                                T-Shirts
                              </Link>
                            </h3>
                          </li>
                          <li className="first" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link to="/product?category=Cargos">Cargos</Link>
                          </li>
                          <li className="first" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link to="/product?category=Trousers">
                              Trousers
                            </Link>
                          </li>
                          <li className="first" onClick={() => {
                  setMainSidebar(false);
                }}>
                            <Link to="/product?category=Shorts">Shorts</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="joinus" onClick={()=>{
                    setMainSignpage("block")
                    setMainSidebar(false);
                    setSignupformpop(true);
                    setLoginformpop(false);
                  }} style={{ display: userprofilesign }}>
                    <ul className="menujoinus">
                      <li>
                        <div className="joinus">
                          <a
                            href="#"
                            className="ajax-register-link"
                            data-role="closeBtn"
                          >
                            SIGN IN
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="joinus" onClick={()=>{
                    setMainSignpage("block")
                    setMainSidebar(false);
                    setSignupformpop(false);
                    setLoginformpop(true);
                  }} style={{ display: userprofilesign }}>
                    <ul className="menujoinus">
                      <li>
                        <div className="joinus">
                          <a
                            href="#"
                            className="ajax-register-link"
                            data-role="closeBtn"
                          >
                            LOGIN IN
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="joinus"
                    style={{ display: userprofile }}
                    
                  >
                    <div className="joinus-logedin">
                      <div className="my-account-link">
                      <Link to="/account"><a href="">Accounts</a></Link>
                      </div>
                      <div className="my-account-link">
                      <Link to="/myorder"><a href="">Orders</a></Link>
                      </div>
                      <div className="my-account-link">
                       <Link to="/myorder"> <a href="">Track Order</a></Link>
                      </div>
                      <div className="my_account_link_list" onClick={handleLogout}>
                        <a className="header_account_link" href="">
                          {" "}
                          Logout{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <style>{`
                            .mufti-megamenu.modal-slide {
                                left: 0;
                                right: inherit;
                                width: 340px;
                            }

                            .mufti-megamenu.modal-slide .modal-inner-wrap {
                                transform: translateX(-100%);
                                -webkit-transform: translateX(-100%);
                            }

                            .mufti-megamenu.modal-slide._show .modal-inner-wrap {
                                transform: translateX(0%);
                                -webkit-transform: translateX(0%);
                            }

                            .mufti-megamenu .acc-nomenu ul.menulink li a {
                                font-size: 18px;
                            }

                            @media only screen and (max-width: 768px) {
                                .mufti-megamenu.modal-slide._inner-scroll .modal-inner-wrap {
                                    display: flex;
                                }
                            }
                        `}</style>
              </div>
            </div>
          </div>
          <div data-role="focusable-end" tabIndex={0} />
        </aside>

        <div
          className="modals-overlay"
          style={{ zIndex: "901" }}
          onClick={() => {
            setMainSidebar(false);
          }}
        />
      </div>
    </>
  );
}

export default Sidebar;

import React, { useEffect } from "react";
import "./Product.css";
import { Link } from "react-router-dom";
import Productmap from "./Productmap";

function Product() {

  useEffect(() => {
    // Function to remove and add classes to the body tag
    const removeAndAddClass = () => {
      const body = document.body;
      body.className = '';
      body.classList.add(
        "page-with-filter", "page-products", "categorypath-shirts-for-men", "category-shirts-for-men",  "catalog-category-view", "page-layout-2columns-left"
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();
  }, []);



  useEffect(() => {
    // Function to remove the old <head> content and replace it with the new one
    const replaceHeadTag = () => {
      // Get the current head element
      const head = document.querySelector("head");
  
      // Remove the old head content
      while (head.firstChild) {
        head.removeChild(head.firstChild);
      }
  
      // Add new head content
      const newHeadContent = `
        <meta http-equiv="origin-trial"
          content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9">
        <meta charset="utf-8">
        <meta name="title" content="Shirts for Men - Shop Casual Shirts Online at Mufti Jeans">
        <meta name="description"
          content="Shop casual shirts for men online at Mufti Jeans. Find a variety of cotton shirts including check, denim, stripe, plain, and print. Explore full sleeve and half sleeve options.">
        <meta name="keywords"
          content="shirts for men, full sleeve shirt, casual shirts for men, mens casual shirt, mens cotton shirts, half sleeve shirt, men shirt online">
        <meta name="robots" content="INDEX, FOLLOW, ARCHIVE">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
        <meta name="format-detection" content="telephone=no">
        <meta name="twitter:card" content="summary_large_image">
        <meta name="twitter:site" content="@muftijeans">
        <meta name="twitter:title" content="Shirts for Men - Shop Casual Shirts Online at Mufti Jeans">
        <meta name="twitter:description"
          content="Shop casual shirts for men online at Mufti Jeans. Find a variety of cotton shirts including check, denim, stripe, plain, and print. Explore full sleeve and half sleeve options.">
        <meta name="twitter:image"
          content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
        <meta name="twitter:url" content="https://www.muftijeans.in/shirts-for-men.html">
        <meta property="og:title" content="Shirts for Men - Shop Casual Shirts Online at Mufti Jeans">
        <meta property="og:description"
          content="Shop casual shirts for men online at Mufti Jeans. Find a variety of cotton shirts including check, denim, stripe, plain, and print. Explore full sleeve and half sleeve options.">
        <meta property="og:image"
          content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
        <meta property="og:url" content="https://www.muftijeans.in/shirts-for-men.html">
        <meta property="og:type" content="website">
        <title>Shirts for Men - Shop Casual Shirts Online at Mufti Jeans</title>
        <link rel="stylesheet" type="text/css" media="all"
          href="https://www.muftijeans.in/static/version1728012928/_cache/merged/674096182ee6d5bee7817e20fba1f54b.min.css">
        <link rel="stylesheet" type="text/css" media="screen and (min-width: 768px)"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/styles-l.min.css">
        <link rel="stylesheet" type="text/css" media="print"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/print.min.css">
        <link rel="stylesheet" type="text/css" media="all"
          href="https://www.muftijeans.in/static/version1728012928/_cache/merged/161b37fc16b2af05bfc55815c8ca59d2.min.css">
        <link rel="icon" type="image/x-icon"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
        <link rel="shortcut icon" type="image/x-icon"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
        <link rel="preload" as="font" crossorigin="anonymous"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/light/opensans-300.woff2">
        <link rel="preload" as="font" crossorigin="anonymous"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/regular/opensans-400.woff2">
        <link rel="preload" as="font" crossorigin="anonymous"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/semibold/opensans-600.woff2">
        <link rel="preload" as="font" crossorigin="anonymous"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/bold/opensans-700.woff2">
        <link rel="preload" as="font" crossorigin="anonymous"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/Luma-Icons.woff2">
        <link rel="canonical" href="https://www.muftijeans.in/shirts-for-men.html">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
        <link rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap">
        <meta name="google-site-verification" content="googlecb8d2cd7f72f5378">
        <link rel="stylesheet" type="text/css" media="all"
          href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/custom.min.css">
        <link rel="stylesheet" type="text/css" media="all"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">
      `;
  
      // Insert the new head content
      head.innerHTML = newHeadContent;
    };
  
    // Call the function to replace the head tag
    replaceHeadTag();
  }, []);
  

 




  return (
    <>


    

     
<main id="maincontent" className="page-main"> <a id="contentarea" tabIndex={-1} />
            <div className="page messages">
                <div data-placeholder="messages" />
                <div data-bind="scope: 'messages'">
                    
                </div>
            </div>
            <div className="category-view"> </div>
            <div className="columns">
                <div className="column main">
                  <input name="form_key" type="hidden" value="jxrdhQCrryeg5Os0" />
                    <div id="authenticationPopup" data-bind="scope:'authenticationPopup', style: {display: 'none'}" style={{ display: "none" }}> 
                    </div> <span />

                    <div id="layer-product-list">
                       
                        <div className="toolbar toolbar-products headerNavFixed">
                            <div className="modes"> <strong className="modes-label" id="modes-label">View as</strong> <strong title="Grid" className="modes-mode active mode-list" data-value="list"><span>List</span></strong> <a className="modes-mode mode-grid" title="Grid" href="#" data-role="mode-switcher" data-value="grid" id="mode-grid" aria-labelledby="modes-label mode-grid"><span>Grid</span></a> </div>
                            <p className="toolbar-amount" id="toolbar-amount"> Items <span className="toolbar-number">1</span>-<span className="toolbar-number">10</span> of <span className="toolbar-number">964</span> </p>
                            <div className="toolbar-sorter sorter"><label className="sorter-label" htmlFor="sorter">Sort By</label>
                                <select id="sorter" data-role="sorter" className="sorter-options" fdprocessedid="ng93dm">
                                    <option value="position" selected={true}>Sort By</option>
                                    <option value="created_at">What's New</option>
                                    <option value="popularity">Popularity</option>
                                    <option value="low_to_high">Price - Low To High</option>
                                    <option value="high_to_low">Price - High To Low</option>
                                </select> <a title="Set Descending Direction" href="#" className="action sorter-action sort-asc" data-role="direction-switcher" data-value="desc"><span>Set Descending Direction</span></a> </div>
                        </div>

                        <div className="products wrapper list products-list" >
                            <ol id="mufti_pr_listing" className="products list items product-items" style={{marginTop:"50px"}}>
                                

                            <Link to="/oneproduct">

                                	    <li className="item product product-item season_id_2039">
    <div className="product-item-info" id="product-item-info_104269" data-container="product-list">
        <div className="product-top">
            <div className="product-slider slick-initialized slick-slider slick-dotted"><button type="button"
                    className="slick-prev pull-left slick-arrow" style={{}} fdprocessedid="vtosy"><i
                        className="fa fa-angle-left" aria-hidden="true" /></button>
                <div className="slick-list draggable">
                    <div className="slick-track" style={{ opacity: "1" , width: 1436 }}>
                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false"
                            style={{ width: 359, position: "relative" , left: 0, top: 0, zIndex: "999" , opacity: "1" }}
                            role="tabpanel" id="slick-slide10">
                            <div>
                                <div className="gallery-item product_id_104269 " style={{ width: "100%" ,
                                    display: "inline-block" }}><a href="javascript:void(0);"  style={{ paddingBottom: "150%" }} className="product photo product-item-link
                                        product-item-photo" tabIndex={0}> <img alt="Tonal Print Loose Fit Casual SHIRTS"
                                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4148-t-78-light-grey.jpg"
                                            className="muftigal1 img-responsive product-image-photo img-thumbnail"
                                            id="product_sku_cp_8905310502737" width="500" height="667" loading="lazy"
                                            fetchpriority="low" />
                                        <div className="product-image-photo" />
                                    </a></div>
                            </div>
                        </div>
                        <div className="slick-slide" data-slick-index="1" aria-hidden="true" style={{ width: 359,
                            position: "relative" , left: "-359px" , top: 0, zIndex: "998" , opacity: "0" }}
                            tabIndex={-1} role="tabpanel" id="slick-slide11">
                            <div>
                                <div className="gallery-item product_id_104269 " style={{ width: "100%" ,
                                    display: "inline-block" }}><a href="javascript:void(0);" onClick={event=> {
                                        window.location.href =
                                        'https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html?pageNum=1';
                                        }} style={{ paddingBottom: "150%" }} className="product photo product-item-link
                                        product-item-photo" tabIndex={-1}> <img
                                            alt="Tonal Print Loose Fit Casual SHIRTS"
                                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_bndr-4148-t-78-light-grey.jpg"
                                            className="muftigal2 img-responsive product-image-photo img-thumbnail"
                                            id="product_sku_cp_8905310502737" width="500" height="667" loading="lazy"
                                            fetchpriority="low" />
                                        <div className="product-image-photo" />
                                    </a></div>
                            </div>
                        </div>
                        <div className="slick-slide" data-slick-index="2" aria-hidden="true" style={{ width: 359,
                            position: "relative" , left: "-718px" , top: 0, zIndex: "998" , opacity: "0" }}
                            tabIndex={-1} role="tabpanel" id="slick-slide12">
                            <div>
                                <div className="gallery-item product_id_104269 " style={{ width: "100%" ,
                                    display: "inline-block" }}><a href="javascript:void(0);" onClick={event=> {
                                        window.location.href =
                                        'https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html?pageNum=1';
                                        }} style={{ paddingBottom: "150%" }} className="product photo product-item-link
                                        product-item-photo" tabIndex={-1}> <img
                                            alt="Tonal Print Loose Fit Casual SHIRTS"
                                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/1000x1333/3_bndr-4148-t-78-light-grey.jpg"
                                            className="muftigal3 img-responsive product-image-photo img-thumbnail"
                                            id="product_sku_cp_8905310502737" width="500" height="667" loading="lazy"
                                            fetchpriority="low" />
                                        <div className="product-image-photo" />
                                    </a></div>
                            </div>
                        </div>
                        <div className="slick-slide" data-slick-index="3" aria-hidden="true" style={{ width: 359,
                            position: "relative" , left: "-1077px" , top: 0, zIndex: "998" , opacity: "0" }}
                            tabIndex={-1} role="tabpanel" id="slick-slide13">
                            <div>
                                <div className="gallery-item product_id_104269 " style={{ width: "100%" ,
                                    display: "inline-block" }}><a href="javascript:void(0);" onClick={event=> {
                                        window.location.href =
                                        'https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html?pageNum=1';
                                        }} style={{ paddingBottom: "150%" }} className="product photo product-item-link
                                        product-item-photo" tabIndex={-1}> <img
                                            alt="Tonal Print Loose Fit Casual SHIRTS"
                                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/1000x1333/4_bndr-4148-t-78-light-grey.jpg"
                                            className="muftigal4 img-responsive product-image-photo img-thumbnail"
                                            id="product_sku_cp_8905310502737" width="500" height="667" loading="lazy"
                                            fetchpriority="low" />
                                        <div className="product-image-photo" />
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div><button type="button" className="slick-next pull-right slick-arrow" style={{}}
                    fdprocessedid="8nxm"><i className="fa fa-angle-right" aria-hidden="true" /></button>
                <ul className="slick-dots" role="tablist" style={{}}>
                    <li className="slick-active" role="presentation"><button type="button" role="tab"
                            id="slick-slide-control10" aria-controls="slick-slide10" aria-label="1 of 4" tabIndex={0}
                            aria-selected="true" fdprocessedid="r8gisr">1</button></li>
                    <li role="presentation"><button type="button" role="tab" id="slick-slide-control11"
                            aria-controls="slick-slide11" aria-label="2 of 4" tabIndex={-1}>2</button></li>
                    <li role="presentation"><button type="button" role="tab" id="slick-slide-control12"
                            aria-controls="slick-slide12" aria-label="3 of 4" tabIndex={-1}>3</button></li>
                    <li role="presentation"><button type="button" role="tab" id="slick-slide-control13"
                            aria-controls="slick-slide13" aria-label="4 of 4" tabIndex={-1}>4</button></li>
                </ul>
            </div>
            <div style={{ display: "none" }}><span className="color_id_281" /></div>
            <div className="item-card__quickBuy"><button title="Add to Bag" className="item-quickbuy-button"
                    fdprocessedid="wszqse"><span className="icon" /> <span className="text quickbuy-v2">Add to
                        Bag</span></button></div>
        </div>
        <div className="product details product-item-details product-name-mufti active" data-cat="1758">
            <div className="product name product-item-name product-name-mufti"><a className="product-item-link"
                    href="https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html">Tonal
                    Print SHIRTS</a></div>
            <div className="product-price-mufti listtop">
                <div className="price-box price-final_price" data-role="priceBox" data-product-id="104269"
                    data-price-box="product-id-104269"> <span className="normal-price"> <span
                            className="price-container price-final_price tax weee"> <span className="price-label" />
                            <span id="product-price-104269" data-price-amount="2999" data-price-type="finalPrice"
                                className="price-wrapper "><span className="price">₹2,999</span></span> </span></span>
                </div>
            </div>
            <div className="mufti-addons-links">
                <div className="actions-link" data-role="add-to-links">
                    <div className="share_wshbx share_icon_li">
                        <div className="share_icon" id="share_icon1" title="Share Product" />
                       
                    </div>
                    <div className="share_wshbx wishlist_ico"><button data-title="Add to Wish List"
                            className="action towishlist" title="Add to Wishlist" aria-label="Add to Wish List"
                            
                            data-id="104269" data-action="add-to-wishlist" role="button" style={{ display: "block" }}
                            fdprocessedid="lw7ai9"><span className="wish_ico" /></button> <button
                            data-title="Remove from Wish List" className="action towishlist"
                            title="Remove from Wish List" aria-label="Remove from Wish List"
                            data-action="remove-from-wishlist" data-id="104269" role="button" style={{ display: "none"
                            }}><span className="wish_ico_added" /></button></div>
                </div>
            </div>
            <div className="swatch_listing" data-cat="1758" data-colorg="1816">
                <div className="swatchtitle">
                    <div className="clssize">X</div>
                    <p className="">Select a Size</p>
                </div>
                <div className="swatch-opt-104269 mufti-swatch-swap" data-role="swatch-option-104269"
                    data-rendered="true">
                    <div className="swatch-attribute size" data-attribute-code="size" data-attribute-id="223">
                        <div aria-activedescendant="" tabIndex={0} aria-invalid="false" aria-required="true"
                            role="listbox" aria-label="Size" className="swatch-attribute-options clearfix">
                            <div className="swatch-option text" id="option-label-size-223-item-698" index="0"
                                aria-checked="false" aria-describedby="option-label-size-223" tabIndex={0}
                                data-option-type="0" data-option-id="698" data-option-label="M-38" aria-label="M-38"
                                role="option" data-thumb-width="50" data-thumb-height="50"
                                data-option-tooltip-value="M-38">M-38</div>
                            <div className="swatch-option text" id="option-label-size-223-item-697" index="1"
                                aria-checked="false" aria-describedby="option-label-size-223" tabIndex={0}
                                data-option-type="0" data-option-id="697" data-option-label="L-40" aria-label="L-40"
                                role="option" data-thumb-width="50" data-thumb-height="50"
                                data-option-tooltip-value="L-40">L-40</div>
                            <div className="swatch-option text" id="option-label-size-223-item-696" index="2"
                                aria-checked="false" aria-describedby="option-label-size-223" tabIndex={0}
                                data-option-type="0" data-option-id="696" data-option-label="XL-42" aria-label="XL-42"
                                role="option" data-thumb-width="50" data-thumb-height="50"
                                data-option-tooltip-value="XL-42">XL-42</div>
                            <div className="swatch-option text" id="option-label-size-223-item-695" index="3"
                                aria-checked="false" aria-describedby="option-label-size-223" tabIndex={0}
                                data-option-type="0" data-option-id="695" data-option-label="2XL-44" aria-label="2XL-44"
                                role="option" data-thumb-width="50" data-thumb-height="50"
                                data-option-tooltip-value="2XL-44">2XL-44</div>
                        </div>
                    </div>
                    <div className="swatch-attribute mufti_colourgroup" data-attribute-code="mufti_colourgroup"
                        data-attribute-id="310">
                        <div aria-activedescendant="" tabIndex={0} aria-invalid="false" aria-required="true"
                            role="listbox" aria-label="Colours" className="swatch-attribute-options clearfix">
                            <div className="swatch-option image preselected"
                                id="option-label-mufti_colourgroup-310-item-1816" index="0" aria-checked="false"
                                aria-describedby="option-label-mufti_colourgroup-310" tabIndex={0} data-option-type="2"
                                data-option-id="1816" data-option-label="Light Grey" aria-label="Light Grey"
                                role="option" data-thumb-width="50" data-thumb-height="50"
                                data-option-tooltip-thumb="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png"
                                data-option-tooltip-value="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png"
                                style={{
                                background: "url(https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png) no-repeat center"
                                , backgroundSize: "initial" , width: 50, height: 50 }} title="Light Grey" />
                        </div>
                    </div>
                </div>
                <div className="sizechrtlnk" data-trigger="trigger-104269" id="sizechart-104269"><span
                        data-bind="i18n: 'Size Chart'">Size Chart</span></div>
            </div>
        </div>
        <div className="product-addtocart">
            <form data-role="tocart-form" data-product-sku="cp_8905310502737"
                action="https://www.muftijeans.in/checkout/cart/add/uenc/aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9zaGlydHMtZm9yLW1lbi5odG1s/product/104269/"
                method="post">
                <div id="qty-dropdown-product104269" className="qty-dropdown-selector" data-productid="104269"> <input
                        type="number" style={{ display: "none" }} name="qty" id="qty104269" maxLength={12} value="1"
                        title="Quantity" data-validate="null" />
                </div> <input type="hidden" name="product" value="104269" /><input type="hidden" name="uenc"
                    value="aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9jaGVja291dC9jYXJ0L2FkZC91ZW5jL2FIUjBjSE02THk5M2QzY3ViWFZtZEdscVpXRnVjeTVwYmk5emFHbHlkSE10Wm05eUxXMWxiaTVvZEcxcy9wcm9kdWN0LzEwNDI2OS8," /><input
                    name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" /> <button type="submit" title="ADD TO BAG"
                    className="action tocart primary"><span>ADD TO BAG</span></button><input
                    className="swatch-input super-attribute-select" name="super_attribute[223]" type="text" value={true}
                    data-selector="super_attribute[223]" data-validate="{required: true}" aria-required="true"
                    aria-invalid="false" /><input className="swatch-input super-attribute-select"
                    name="super_attribute[310]" type="text" value={true} data-selector="super_attribute[310]"
                    data-validate="{required: true}" aria-required="true" aria-invalid="false" />
            </form>
        </div>
    </div>
                                        </li>

                                </Link>

                                        
                                        <Productmap/>


                     


                               
                               
                            </ol>
                        </div>

                        

                       
                       
                        <div className="mbl-sorting">
                            <div className="mbl-sorting-rht"><span className="filter-icon">SORT / FILTER</span></div>
                        </div>
                        
                    </div>

                    
                    <section className="amsearch-overlay-block" >
                        <div className="amsearch-overlay" data-bind=" event: { click: hide }, afterRender: initNode" />
                    </section>
                </div>

               


            </div>
        </main>








































































        <style>{`
                        .mplayer-subcategory.carousel-container {
                            display: flex;
                            flex-direction: column;
                        }

                        .mplayer-subcategory-container {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 20px;
                        }

                        #mplayer-subcategory-list-items {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .mplayer-category-item {
                            flex: 1 1 50%;
                            /* Default to 2 items per row */
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                        }

                        .mplayer-item-slider {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }

                        /* Apply styles htmlFor desktop view */
                        @media (min-width: 768px) {
                            .mplayer-category-item {
                                flex: 1 1 25%;
                                /* Default to 4 items per row on desktop */
                            }
                        }

                        @media (max-width: 768px) {

                            /* Hide items beyond the first 2 on mobile view */
                            .mplayer-category-item:nth-child(n+3) {
                                display: none;
                            }
                        }
                    `}</style>








        <style>{`
                            @media (max-width: 767px) {
                                .products-category-grid-media-carousel--animate {
                                    animation: shift-ltr 0.5s linear;
                                    animation-delay: 1s;
                                    scroll-snap-align: start;
                                    scroll-snap-stop: always;
                                }
                            }

                            /* Keyframes htmlFor the animation */
                            @keyframes shift-ltr {
                                0% {
                                    transform: translateX(0%);
                                }

                                50% {
                                    transform: translateX(-40%);
                                }

                                100% {
                                    transform: translateX(0%);
                                }
                            }
                        `}</style>

    </>
  );
}

export default Product;

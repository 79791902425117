import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Homepage/Home";
import Navbar from "./Casamanolomainpart/Navbar";
import Footer from "./Casamanolomainpart/Footer";
import Product from "./Casaprdwithcatigri/Product";
import Mybag from "./Mybag/Mybag";
import Checkout from "./Checkout/Checkout";
import Profile from "./Userprofile/Profile";
import Updatecommunication from "./Userprofile/Updatecommunication";
import Address from "./Userprofile/Address";
import Myorder from "./Userprofile/Myorder";
import Sidebar from "./Casamanolomainpart/Sidebar";
import MyContextProvider from "./Context/Mycontext"; // Correct import
import Productone from "./Product/Productone";

function App() {
  return (
    <>
      {/* Wrap the whole app in MyContextProvider */}
      <MyContextProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home  />} />
            <Route path="/product" element={<Product />} />
            <Route path="/oneproduct/:id" element={<Productone />} />
            {/* <Route path="/mybag" element={<Mybag />} /> */}
            <Route path="/checkout/:id" element={<Checkout />} />
            
            <Route path="/account" element={<Profile />} />
            <Route path="/updatecom" element={<Updatecommunication />} />
            <Route path="/address" element={<Address />} />
            <Route path="/myorder" element={<Myorder />} />
            {/* <Route path="/sidebar" element={<Sidebar />} /> */}
            

          </Routes>
          <Footer />
        </Router>
      </MyContextProvider>
    </>
  );
}

export default App;

import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../Context/Mycontext";

function BagProducts() {
  const [products, setProducts] = useState([]);
  const [allidproducts, setallidProducts] = useState();





  const context = useContext(MyContext);

  const {
    
    url2
  } = context;

  // Assuming you are fetching product details from local storage or an API
  useEffect(() => {
    const storedProductIds =
      JSON.parse(localStorage.getItem("bagProducts")) || [];

      

    if (storedProductIds.length > 0) {
      fetchProducts(storedProductIds);
    }
  }, []);

  const fetchProducts = async (productIds) => {
    

    try {
      const response = await fetch(`${url2}products/getproductsbyids`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: productIds }), // Pass the product IDs
      });

      const result = await response.json();
      if (response.ok) {
        setProducts(result);
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {products.length > 0 ? (
        products.map((product) => (
          <li
            key={product._id}
            className="item product product-item quickcart-product-item odd last"
            data-role="product-item"
            data-collapsible="true"
          >
            <div className="product">
              <a
                data-bind="attr: {href: product_url, title: product_name}"
                tabIndex={-1}
                className="product-item-photo"
                href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html"
                title="Red &amp; Yellow Tipped Collar Pique Polo T-Shirt"
              >
                <span
                  className="product-image-container"
                  data-bind="style: {width: width/2 + 'px'}"
                  style={{ width: 150 }}
                >
                  <span
                    className="product-image-wrapper"
                    data-bind="style: {'padding-bottom': height/width*100 + '%'}"
                    style={{
                      paddingBottom: "150%",
                    }}
                  >
                    <img
                      className="product-image-photo"
                      data-bind="attr: {src: src, alt: alt}, style: {width: 'auto', height: 'auto'}"
                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/300x450/1_mfk-9307-q-03-red_5.jpg"
                      alt="Red & Yellow Tipped Collar Pique Polo T-Shirt"
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </span>
                </span>
              </a>

              <div className="product-item-details">
                <strong className="product-item-name">
                  <a
                    data-bind="attr: {href: product_url}, html: product_name"
                    href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html"
                  >
                    Red & Yellow Tipped Collar Pique Polo T-Shirt
                  </a>
                </strong>

                <div className="product actions">
                  <div className="secondary">
                    <a
                      href="#"
                      className="action delete"
                      data-cart-item="2735465"
                      title="Remove item"
                    >
                      <span
                        className="icon-line-cross"
                        onClick={() => {
                          const bagProducts = JSON.parse(localStorage.getItem("bagProducts")) || [];
                        
                          // Use filter to remove the product with the matching _id
                          const updatedBagProducts = bagProducts.filter(
                            (productInBag) => productInBag !== product._id
                          );
                        
                          // Update localStorage with the filtered array
                          localStorage.setItem("bagProducts", JSON.stringify(updatedBagProducts));
                          
                        }}
                        
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                      </span>
                    </a>
                  </div>
                </div>

                <div
                  className="product options"
                  role="tablist"
                  data-collapsible="true"
                >
                  <div
                    className="content"
                    data-role="title"
                    role="tab"
                    aria-selected="false"
                    aria-expanded="false"
                    tabIndex={0}
                  >
                    <dl className="product options list">
                      <div className="option-wrapper">
                        <dt className="label">Colours</dt>
                        <dd className="values">
                          <span data-bind="html: option.value">Red</span>
                        </dd>
                      </div>

                      <div className="option-wrapper">
                        <dt className="label">Size</dt>
                        <dd className="values">
                          <span data-bind="html: option.value">M-38</span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div className="product-item-pricing">
                  <div className="price-container">
                    <span className="price-wrapper" data-bind="html: price">
                      {" "}
                      <span
                        className="price-including-tax"
                        data-label="Incl. Tax"
                      >
                        <span className="minicart-old-price">
                          <span className="price">₹2,099</span>
                        </span>{" "}
                        <span className="minicart-price ram">
                          {" "}
                          <span className="price">₹1,049</span>
                        </span>{" "}
                        <span
                          className="buyfree-label"
                          style={{
                            display: "inline-block",
                            fontSize: 14,
                            float: "right",
                            paddingLeft: 5,
                          }}
                        >
                          (50% Off)
                        </span>{" "}
                      </span>{" "}
                    </span>
                  </div>

                  <div className="details-qty qty">
                    <label className="label" htmlFor="cart-item-2735465-qty">
                      Quantity
                    </label>
                    <span className="item-minus qty-update" />
                    <input
                      type="number"
                      size={4}
                      className="item-qty cart-item-qty"
                      maxLength={12}
                      disabled={true}
                      id="cart-item-2735465-qty"
                    />
                    <div className="spinner" />

                    <span className="item-plus qty-update" />
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))
      ) : (
        <p>Your bag is empty!</p>
      )}
    </div>
  );
}

export default BagProducts;

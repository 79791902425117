import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../Context/Mycontext";

const Loginform = () => {
  const context = useContext(MyContext);
  const { setMainSignpage, GetUser,forgetpasspop,setforgetpasspop,setLoginformpop,url2 } = context;

  const [formData, setFormData] = useState({
    contact: "", // Combined field for phone or email
    password: "",
    terms: false,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.contact) {
      newErrors.contact = "Please enter your phone number or email.";
    } else if (
      !/\S+@\S+\.\S+/.test(formData.contact) &&
      isNaN(formData.contact)
    ) {
      newErrors.contact = "Please enter a valid email or phone number.";
    }
    if (!formData.password) {
      newErrors.password = "Password is required.";
    }
    if (!formData.terms) {
      newErrors.terms = "You must agree to the terms and conditions.";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    try {
      const response = await fetch(`${url2}api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact: formData.contact,
          password: formData.password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data, "this is token");
  
        // Save the token in localStorage
        localStorage.setItem('token', data.autotoken); // Save 'autotoken' to localStorage
  
        // Handle login success, e.g., save user data or token
        
        GetUser();
        alert("Login successful!");
        setMainSignpage(false);
        navigate(window.location.pathname);
      } else {
        const error = await response.json();
        alert(`Error: ${error.message}`);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Login failed. Please try again.");
    }
  };
  

  return (
    <form
      className="form form-login"
      onSubmit={handleSubmit}
      id="customer-registration-form"
    >
      <div className="fieldset row login">
        {/* Combined Phone Number/Email Field */}
        <div className="field">
          <div className="control">
            <input
              name="contact"
              type="text"
              className="input-text"
              placeholder="Phone No. or Email Address"
              value={formData.contact}
              onChange={handleChange}
              aria-label="Phone Number or Email Address" // Accessibility improvement
            />
            {errors.contact && <span className="error">{errors.contact}</span>}
          </div>
        </div>

        {/* Password Field */}
        <div className="field">
          <div className="control">
            <input
              name="password"
              type="password"
              className="input-text"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              aria-label="Password" // Accessibility improvement
            />
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
        </div>

        <div
          onClick={() => {
            setforgetpasspop(true);
            setLoginformpop(false);
          }}
        >
          <p className="forget-password" style={{color:"blue",cursor:"pointer",textDecoration:"underline"}}>Forget Password</p>
        </div>

        {/* Terms & Conditions */}
        <div className="terms">
          <input
            type="checkbox"
            name="terms"
            checked={formData.terms}
            onChange={handleChange}
            aria-label="Terms and Conditions" // Accessibility improvement
          />
          <label htmlFor="terms">
            I have read the terms and conditions and agree to receive
            newsletters.
          </label>
          {errors.terms && <span className="error">{errors.terms}</span>}
        </div>

        {/* Submit Button */}
        <div className="actions-toolbar">
          <div className="primary">
            <button type="submit" className="action action-login secondary">
              Login Account
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Loginform;



import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { MyContext } from "../Context/Mycontext";

function Productmap() {


  const context = useContext(MyContext);

  const {url2}=context;
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category"); // Retrieve the category from query parameters

  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch products based on category
    const fetchProducts = async () => {
      try {
        let response;
        if (category === "all") {
          response = await fetch(
            `${url2}api/products/getproductall`
          );
        } else {
          response = await fetch(
            `${url2}api/products/getproduct?category=${category}`
          );
        }

        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (category) {
      fetchProducts();
    }
  }, [category]);

  return (
    <>
      {products.length > 0 ? (
        products.map((product) => (
          <Link
            to={`/oneproduct/${product._id}`}
            onClick={() => {
              console.log(product._id, "this is id ");
            }}
            style={{
              textDecoration: "none",
              color: "inherit",
              padding: "",
              margin: "3px",
            }}
          >
            <li className="item product product-item season_id_2039">
              <div
                className="product-item-info"
                id="product-item-info_104269"
                data-container="product-list"
              >
                <div className="product-top">
                  <div className="product-slider slick-initialized slick-slider slick-dotted">
                    <button
                      type="button"
                      className="slick-prev pull-left slick-arrow"
                      style={{}}
                      fdprocessedid="vtosy"
                    >
                      <i className="fa fa-angle-left" aria-hidden="true" />
                    </button>
                    <div className="slick-list draggable">
                      <div
                        className="slick-track"
                        style={{ opacity: "1", width: 1436 }}
                      >
                        <div
                          className="slick-slide slick-current slick-active"
                          data-slick-index="0"
                          aria-hidden="false"
                          style={{
                            width: 359,
                            position: "relative",
                            left: 0,
                            top: 0,
                            zIndex: "999",
                            opacity: "1",
                          }}
                          role="tabpanel"
                          id="slick-slide10"
                        >
                          <div>
                            <div
                              className="gallery-item product_id_104269 "
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <a
                                href="javascript:void(0);"
                                style={{ paddingBottom: "150%" }}
                                className="product photo product-item-link
                                        product-item-photo"
                                tabIndex={0}
                              >
                                {" "}
                                <img
                                  alt="Tonal Print Loose Fit Casual SHIRTS"
                                  src={product.image1}
                                  className="muftigal1 img-responsive product-image-photo img-thumbnail"
                                  id="product_sku_cp_8905310502737"
                                  width="500"
                                  height="667"
                                  loading="lazy"
                                  fetchpriority="low"
                                />
                                <div className="product-image-photo" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          className="slick-slide"
                          data-slick-index="1"
                          aria-hidden="true"
                          style={{
                            width: 359,
                            position: "relative",
                            left: "-359px",
                            top: 0,
                            zIndex: "998",
                            opacity: "0",
                          }}
                          tabIndex={-1}
                          role="tabpanel"
                          id="slick-slide11"
                        >
                          <div>
                            <div
                              className="gallery-item product_id_104269 "
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <a
                                href="javascript:void(0);"
                                
                                style={{ paddingBottom: "150%" }}
                                className="product photo product-item-link
                                        product-item-photo"
                                tabIndex={-1}
                              >
                                {" "}
                                <img
                                  alt="Tonal Print Loose Fit Casual SHIRTS"
                                  src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_bndr-4148-t-78-light-grey.jpg"
                                  className="muftigal2 img-responsive product-image-photo img-thumbnail"
                                  id="product_sku_cp_8905310502737"
                                  width="500"
                                  height="667"
                                  loading="lazy"
                                  fetchpriority="low"
                                />
                                <div className="product-image-photo" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          className="slick-slide"
                          data-slick-index="2"
                          aria-hidden="true"
                          style={{
                            width: 359,
                            position: "relative",
                            left: "-718px",
                            top: 0,
                            zIndex: "998",
                            opacity: "0",
                          }}
                          tabIndex={-1}
                          role="tabpanel"
                          id="slick-slide12"
                        >
                          <div>
                            <div
                              className="gallery-item product_id_104269 "
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <a
                                href="javascript:void(0);"
                                onClick={(event) => {
                                  window.location.href =
                                    "https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html?pageNum=1";
                                }}
                                style={{ paddingBottom: "150%" }}
                                className="product photo product-item-link
                                        product-item-photo"
                                tabIndex={-1}
                              >
                                {" "}
                                <img
                                  alt="Tonal Print Loose Fit Casual SHIRTS"
                                  src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/1000x1333/3_bndr-4148-t-78-light-grey.jpg"
                                  className="muftigal3 img-responsive product-image-photo img-thumbnail"
                                  id="product_sku_cp_8905310502737"
                                  width="500"
                                  height="667"
                                  loading="lazy"
                                  fetchpriority="low"
                                />
                                <div className="product-image-photo" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          className="slick-slide"
                          data-slick-index="3"
                          aria-hidden="true"
                          style={{
                            width: 359,
                            position: "relative",
                            left: "-1077px",
                            top: 0,
                            zIndex: "998",
                            opacity: "0",
                          }}
                          tabIndex={-1}
                          role="tabpanel"
                          id="slick-slide13"
                        >
                          <div>
                            <div
                              className="gallery-item product_id_104269 "
                              style={{ width: "100%", display: "inline-block" }}
                            >
                              <a
                                href="javascript:void(0);"
                                onClick={(event) => {
                                  window.location.href =
                                    "https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html?pageNum=1";
                                }}
                                style={{ paddingBottom: "150%" }}
                                className="product photo product-item-link
                                        product-item-photo"
                                tabIndex={-1}
                              >
                                {" "}
                                <img
                                  alt="Tonal Print Loose Fit Casual SHIRTS"
                                  src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/1000x1333/4_bndr-4148-t-78-light-grey.jpg"
                                  className="muftigal4 img-responsive product-image-photo img-thumbnail"
                                  id="product_sku_cp_8905310502737"
                                  width="500"
                                  height="667"
                                  loading="lazy"
                                  fetchpriority="low"
                                />
                                <div className="product-image-photo" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="slick-next pull-right slick-arrow"
                      style={{}}
                      fdprocessedid="8nxm"
                    >
                      <i className="fa fa-angle-right" aria-hidden="true" />
                    </button>
                    <ul className="slick-dots" role="tablist" style={{}}>
                      <li className="slick-active" role="presentation">
                        <button
                          type="button"
                          role="tab"
                          id="slick-slide-control10"
                          aria-controls="slick-slide10"
                          aria-label="1 of 4"
                          tabIndex={0}
                          aria-selected="true"
                          fdprocessedid="r8gisr"
                        >
                          1
                        </button>
                      </li>
                      <li role="presentation">
                        <button
                          type="button"
                          role="tab"
                          id="slick-slide-control11"
                          aria-controls="slick-slide11"
                          aria-label="2 of 4"
                          tabIndex={-1}
                        >
                          2
                        </button>
                      </li>
                      <li role="presentation">
                        <button
                          type="button"
                          role="tab"
                          id="slick-slide-control12"
                          aria-controls="slick-slide12"
                          aria-label="3 of 4"
                          tabIndex={-1}
                        >
                          3
                        </button>
                      </li>
                      <li role="presentation">
                        <button
                          type="button"
                          role="tab"
                          id="slick-slide-control13"
                          aria-controls="slick-slide13"
                          aria-label="4 of 4"
                          tabIndex={-1}
                        >
                          4
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div style={{ display: "none" }}>
                    <span className="color_id_281" />
                  </div>
                  <div className="item-card__quickBuy">
                    <button
                      title="Add to Bag"
                      className="item-quickbuy-button"
                      fdprocessedid="wszqse"
                    >
                      <span className="icon" />{" "}
                      <span className="text quickbuy-v2">Add to Bag</span>
                    </button>
                  </div>
                </div>
                <div
                  className="product details product-item-details product-name-mufti active"
                  data-cat="1758"
                >
                  <div className="product name product-item-name product-name-mufti">
                    <a
                      className="product-item-link"
                      href="https://www.muftijeans.in/tonal-print-loose-fit-casual-shirts-bndr-4148-t-grey.html"
                    >
                      Tonal Print SHIRTS
                    </a>
                  </div>
                  <div className="product-price-mufti listtop">
                    <div
                      className="price-box price-final_price"
                      data-role="priceBox"
                      data-product-id="104269"
                      data-price-box="product-id-104269"
                    >
                      {" "}
                      <span className="normal-price">
                        {" "}
                        <span className="price-container price-final_price tax weee">
                          {" "}
                          <span className="price-label" />
                          <span
                            id="product-price-104269"
                            data-price-amount="2999"
                            data-price-type="finalPrice"
                            className="price-wrapper "
                          >
                            <span className="price">₹2,999</span>
                          </span>{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="mufti-addons-links">
                    <div className="actions-link" data-role="add-to-links">
                      <div className="share_wshbx share_icon_li">
                        <div
                          className="share_icon"
                          id="share_icon1"
                          title="Share Product"
                        />
                      </div>
                      <div className="share_wshbx wishlist_ico">
                        <button
                          data-title="Add to Wish List"
                          className="action towishlist"
                          title="Add to Wishlist"
                          aria-label="Add to Wish List"
                          data-id="104269"
                          data-action="add-to-wishlist"
                          role="button"
                          style={{ display: "block" }}
                          fdprocessedid="lw7ai9"
                        >
                          <span className="wish_ico" />
                        </button>{" "}
                        <button
                          data-title="Remove from Wish List"
                          className="action towishlist"
                          title="Remove from Wish List"
                          aria-label="Remove from Wish List"
                          data-action="remove-from-wishlist"
                          data-id="104269"
                          role="button"
                          style={{ display: "none" }}
                        >
                          <span className="wish_ico_added" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="swatch_listing"
                    data-cat="1758"
                    data-colorg="1816"
                  >
                    <div className="swatchtitle">
                      <div className="clssize">X</div>
                      <p className="">Select a Size</p>
                    </div>
                    <div
                      className="swatch-opt-104269 mufti-swatch-swap"
                      data-role="swatch-option-104269"
                      data-rendered="true"
                    >
                      <div
                        className="swatch-attribute size"
                        data-attribute-code="size"
                        data-attribute-id="223"
                      >
                        <div
                          aria-activedescendant=""
                          tabIndex={0}
                          aria-invalid="false"
                          aria-required="true"
                          role="listbox"
                          aria-label="Size"
                          className="swatch-attribute-options clearfix"
                        >
                          <div
                            className="swatch-option text"
                            id="option-label-size-223-item-698"
                            index="0"
                            aria-checked="false"
                            aria-describedby="option-label-size-223"
                            tabIndex={0}
                            data-option-type="0"
                            data-option-id="698"
                            data-option-label="M-38"
                            aria-label="M-38"
                            role="option"
                            data-thumb-width="50"
                            data-thumb-height="50"
                            data-option-tooltip-value="M-38"
                          >
                            M-38
                          </div>
                          <div
                            className="swatch-option text"
                            id="option-label-size-223-item-697"
                            index="1"
                            aria-checked="false"
                            aria-describedby="option-label-size-223"
                            tabIndex={0}
                            data-option-type="0"
                            data-option-id="697"
                            data-option-label="L-40"
                            aria-label="L-40"
                            role="option"
                            data-thumb-width="50"
                            data-thumb-height="50"
                            data-option-tooltip-value="L-40"
                          >
                            L-40
                          </div>
                          <div
                            className="swatch-option text"
                            id="option-label-size-223-item-696"
                            index="2"
                            aria-checked="false"
                            aria-describedby="option-label-size-223"
                            tabIndex={0}
                            data-option-type="0"
                            data-option-id="696"
                            data-option-label="XL-42"
                            aria-label="XL-42"
                            role="option"
                            data-thumb-width="50"
                            data-thumb-height="50"
                            data-option-tooltip-value="XL-42"
                          >
                            XL-42
                          </div>
                          <div
                            className="swatch-option text"
                            id="option-label-size-223-item-695"
                            index="3"
                            aria-checked="false"
                            aria-describedby="option-label-size-223"
                            tabIndex={0}
                            data-option-type="0"
                            data-option-id="695"
                            data-option-label="2XL-44"
                            aria-label="2XL-44"
                            role="option"
                            data-thumb-width="50"
                            data-thumb-height="50"
                            data-option-tooltip-value="2XL-44"
                          >
                            2XL-44
                          </div>
                        </div>
                      </div>
                      <div
                        className="swatch-attribute mufti_colourgroup"
                        data-attribute-code="mufti_colourgroup"
                        data-attribute-id="310"
                      >
                        <div
                          aria-activedescendant=""
                          tabIndex={0}
                          aria-invalid="false"
                          aria-required="true"
                          role="listbox"
                          aria-label="Colours"
                          className="swatch-attribute-options clearfix"
                        >
                          <div
                            className="swatch-option image preselected"
                            id="option-label-mufti_colourgroup-310-item-1816"
                            index="0"
                            aria-checked="false"
                            aria-describedby="option-label-mufti_colourgroup-310"
                            tabIndex={0}
                            data-option-type="2"
                            data-option-id="1816"
                            data-option-label="Light Grey"
                            aria-label="Light Grey"
                            role="option"
                            data-thumb-width="50"
                            data-thumb-height="50"
                            data-option-tooltip-thumb="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png"
                            data-option-tooltip-value="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png"
                            style={{
                              background:
                                "url(https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/s/w/fit-in/50x50/sw_bndr-4148-t-78-light-grey.png) no-repeat center",
                              backgroundSize: "initial",
                              width: 50,
                              height: 50,
                            }}
                            title="Light Grey"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="sizechrtlnk"
                      data-trigger="trigger-104269"
                      id="sizechart-104269"
                    >
                      <span data-bind="i18n: 'Size Chart'">Size Chart</span>
                    </div>
                  </div>
                </div>




                <div className="product-addtocart">
                  <form
                    data-role="tocart-form"
                    data-product-sku="cp_8905310502737"
                    action="https://www.muftijeans.in/checkout/cart/add/uenc/aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9zaGlydHMtZm9yLW1lbi5odG1s/product/104269/"
                    method="post"
                  >
                    <div
                      id="qty-dropdown-product104269"
                      className="qty-dropdown-selector"
                      data-productid="104269"
                    >
                      {" "}
                      <input
                        type="number"
                        style={{ display: "none" }}
                        name="qty"
                        id="qty104269"
                        maxLength={12}
                        value="1"
                        title="Quantity"
                        data-validate="null"
                      />
                    </div>{" "}
                    <input type="hidden" name="product" value="104269" />
                    <input
                      type="hidden"
                      name="uenc"
                      value="aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9jaGVja291dC9jYXJ0L2FkZC91ZW5jL2FIUjBjSE02THk5M2QzY3ViWFZtZEdscVpXRnVjeTVwYmk5emFHbHlkSE10Wm05eUxXMWxiaTVvZEcxcy9wcm9kdWN0LzEwNDI2OS8,"
                    />
                    <input
                      name="form_key"
                      type="hidden"
                      value="Oh5Dn3Bcs3geVw09"
                    />{" "}
                    <button
                      type="submit"
                      title="ADD TO BAG"
                      className="action tocart primary"
                    >
                      <span>ADD TO BAG</span>
                    </button>
                    <input
                      className="swatch-input super-attribute-select"
                      name="super_attribute[223]"
                      type="text"
                      value={true}
                      data-selector="super_attribute[223]"
                      data-validate="{required: true}"
                      aria-required="true"
                      aria-invalid="false"
                    />
                    <input
                      className="swatch-input super-attribute-select"
                      name="super_attribute[310]"
                      type="text"
                      value={true}
                      data-selector="super_attribute[310]"
                      data-validate="{required: true}"
                      aria-required="true"
                      aria-invalid="false"
                    />
                  </form>
                </div>

                ``
              </div>
            </li>
          </Link>
        ))
      ) : (
        <p>Loading products...</p>
      )}
    </>
  );
}

export default Productmap;

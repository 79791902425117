import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Phonenav from './Phonenav';
import { MyContext } from '../Context/Mycontext';

function Myorder() {


    const context = useContext(MyContext);

    const {navshow,setnavshow} =context;

    useEffect(() => {
        // Function to remove and add classes to the body tag
        const removeAndAddClass = () => {
          const body = document.body;
          body.className = ''; 
          
          body.classList.add("account", "sales-order-history", "page-layout-2columns-left"
            
           
          );
        };
    
        // Call the function inside useEffect
        removeAndAddClass();
        
      
       
      }, []);

  return (
   <>

   <main id="maincontent" className="page-main"> <a id="contentarea" tabIndex={-1} />
            <div className="page messages">
                <div data-placeholder="messages" />
                <div data-bind="scope: 'messages'">
                    { /* ko if: cookieMessages && cookieMessages.length > 0 */ }{ /* /ko */ }{ /* ko if: messages().messages && messages().messages.length > 0 */ }{ /* /ko */ }
                </div>
            </div>
            <div className="columns">
                <div className="column main" style={{ display: navshow === 'active' ? 'none' : '' }}>
                    <div className="page-title-wrapper">
                        <h1 className="page-title"><span className="base" data-ui-id="page-title-wrapper">My Orders</span></h1>
                    </div><input name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" />
                    <div id="authenticationPopup" data-bind="scope:'authenticationPopup', style: {display: 'none'}" style={{ display: "none" }}> { /* ko template: getTemplate() */ }


                        { /* /ko */ }
                    </div> <span />
                    <div className="table-wrapper orders-history ram16">
                        <div className="order-items-list">
                            <div className="order-item-section">
                                <div className="order-item">
                                    <div className="order-item-head">
                                        <div className="order-item-date">Date: 05/10/2024</div>
                                        <div className="order-item-orderid">Order: # 100189176</div>
                                    </div>
                                    <div className="row-item sku-104270">
                                        <div className="order-item-image"><img src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/1_bndr-4157-t-88-beige.jpg" />
                                        </div>
                                        <div className="order-item-content">
                                            <h3><a href="https://www.muftijeans.in//abstract-print-loose-fit-casual-shirts-bndr-4157-t-beige.html">Abstract
                                                    Print Loose Fit Casual Shirts</a></h3>
                                            <ul>
                                                <li> <span className="item_text">Price</span> <span className="item_sep">:</span> <span className="item_value">₹2,999</span>
                                                </li>
                                                <li> <span className="item_text">Qty </span> <span className="item_sep">:</span>
                                                    <span className="item_value">1</span></li>
                                                <li> <span className="item_text">Size</span> <span className="item_sep">:</span>
                                                    <span className="item_value">M-38</span></li>
                                                <li> <span className="item_text">Color</span> <span className="item_sep">:</span> <span className="item_value">Beige</span>
                                                </li>
                                                <li> <span className="item_text">Status</span> <span className="item_sep">:</span> <span className="item_value">
                                                        Canceled</span></li>
                                            </ul>
                                            <div className="order-item-action mobile-track"><a href="https://magento.fynd.io/order-tracking/" className="action view" target="_blank"><span>Track</span></a></div>
                                        </div>
                                        <div className="order-item-action desktop-track"><a href="https://magento.fynd.io/order-tracking/" className="action view" target="_blank"><span>Track</span></a></div>
                                    </div>
                                </div>
                                <div className="order-details-links">
                                    <ul>{ /* <li> <a class="invoice-link" href="<? ?>" ></a></li> */ }
                                        <li> <a className="returns-rma-link" href="https://www.muftijeans.in/returns/rma/new/order_id/184162/">Return</a>
                                        </li>
                                        <li className="order_show_hide_action"><a className="order_show_hide" href="javascript:void(0)">More Details</a></li>
                                    </ul>
                                </div>
                                <div id="order-item-footer" className="order-item-footer">
                                    <div className="order-amount-details">
                                        <p><span className="detail-text">Subtotal</span> <span className="detail-sep">:</span>
                                            <span className="detail-value">₹2,999</span></p>
                                        <p><span className="detail-text">Shipping & Handling</span> <span className="detail-sep">:</span> <span className="detail-value">₹0</span></p>
                                        <p><span className="detail-text">Grand Total(Excl. Tax)</span> <span className="detail-sep">:</span> <span className="detail-value">₹2,999</span></p>
                                        <p><span className="detail-text">Grand Total(Incl.Tax)</span> <span className="detail-sep">:</span> <span className="detail-value"><strong>₹2,999</strong></span></p>
                                    </div>
                                    <h3 className="order-information-title">Order Information</h3>
                                    <div className="order-information-section">
                                        <div className="order-information">
                                            <h2>Shipping Address</h2>
                                            <p>mukeem md<br />Gali Number 19, Shriram Colony, Block B, Rajiv Nagar,
                                                Khajoori Khas, Delhi, India North West Delhi , Delhi , 110094<br />India
                                            </p>
                                            <p>T:9990851391</p>
                                        </div>
                                        <div className="order-information">
                                            <h2>Shipping Method</h2>
                                            <p>Free Shipping - Free</p>
                                        </div>
                                        <div className="order-information">
                                            <h2>Billing Address</h2>
                                            <p>mukeem md<br />Gali Number 19, Shriram Colony, Block B, Rajiv Nagar,
                                                Khajoori Khas, Delhi, India North West Delhi , Delhi , 110094<br />India
                                            </p>
                                            <p>T:9990851391</p>
                                        </div>
                                        <div className="order-information">
                                            <h2>Payment Method</h2>
                                            <p>Credit / Debit Card, Net Banking or Cash Card (Razorpay)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="data table table-order-items history" id="my-orders-table" style={{ display: "none" }}>
                            <caption className="table-caption">Orders</caption>
                            <thead>
                                <tr>
                                    <th scope="col" className="col id">Order #</th>
                                    <th scope="col" className="col date">Date</th>
                                    <th data-th="RMA" className="col">RMA</th>
                                    <th scope="col" className="col total">Order Total</th>
                                    <th scope="col" className="col status">Status</th>
                                    <th scope="col" className="col actions">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-th="Order #" className="col id">100189176</td>
                                    <td data-th="Date" className="col date">10/5/24</td>
                                    <td data-th="RMA" className="col">
                                        <div className="order-title"> <br /><br /></div>
                                    </td>
                                    <td data-th="Order Total" className="col total">₹2,999</td>
                                    <td data-th="Status" className="col status">Canceled</td>
                                    <td data-th="Actions" className="col actions"><a href="https://www.muftijeans.in/sales/order/view/order_id/184162/" className="action view"><span>View Order</span></a> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="order-products-toolbar toolbar bottom">
                        <div className="pager">
                            <p className="toolbar-amount"><span className="toolbar-number"> 1 Item </span></p>
                            <div className="limiter"><strong className="limiter-label">Show</strong> <select id="limiter" className="limiter-options" fdprocessedid="rd7tie">
                                    <option value="https://www.muftijeans.in/sales/order/history/?limit=10" selected={true}>10</option>
                                    <option value="https://www.muftijeans.in/sales/order/history/?limit=20">20</option>
                                    <option value="https://www.muftijeans.in/sales/order/history/?limit=50">50</option>
                                </select> <span className="limiter-text">per page</span></div>
                        </div>
                    </div>
                    <div className="actions-toolbar">
                        <hr />
                        <div className="secondary"><a className="action btn btn-xs btn-secondary back" href="https://www.muftijeans.in/customer/account/"><span>Back</span></a></div>
                    </div>
                    <section className="amsearch-overlay-block" data-bind=" scope: 'index = amsearch_overlay_section', mageInit: { 'Magento_Ui/js/core/app': { components: { 'amsearch_overlay_section': { component: 'Amasty_Xsearch/js/components/overlay' } } } }">
                        <div className="amsearch-overlay" data-bind=" event: { click: hide }, afterRender: initNode" />
                    </section>
                </div>








                {/* <div className="sidebar sidebar-main desktop-filter">
                    <div className="custom_menu_top_img">
                        <div className="user-image desk-user-image"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#000" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                        </path>
                                    </g>
                                </svg></a></div>
                    </div>
                    <div className="custom_menu_top">
                        <div className="mufti-userprofile"><a className="mblclosemenu dskclose logedin" href="javascript:void(0)"><svg width="24" height="24" viewBox="0 0 24 24" className="close-icon" fill="#ffffff">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#ffffff" d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z">
                                        </path>
                                    </g>
                                </svg></a>
                            <div className="user-image1"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M0 0h24v24H0z" opacity="0.05" />
                                            <path fill="#fff" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                            </path>
                                        </g>
                                    </svg></a></div>
                        </div>
                    </div>
                    <div className="block block-collapsible-nav">
                        <div className="title block-collapsible-nav-title"><strong> My Account</strong></div>
                        <div className="content block-collapsible-nav-content" id="block-collapsible-nav">
                            <ul className="nav items">
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item customer-order-list"><a className="my-orders" href="https://www.muftijeans.in/sales/order/history/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/my-orders.png" />My Orders</a>
                                </li>
                                <li className="nav item customer-profile"><a href="https://www.muftijeans.in/customer/account/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/profile.png" />Profile</a>
                                    <ul className="nav-sub-menu">
                                        <li className="nav item"><a href="https://www.muftijeans.in/customer/account/">Account
                                                Information</a></li>
                                        <li className="nav item"><a href="https://www.muftijeans.in/mobilelogin/index/updatemobile/">Update
                                                Communication</a></li>
                                    </ul>
                                </li>
                                <li className="nav item customer-manage-address"><a href="https://www.muftijeans.in/customer/address/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/manage-address.png" />Manage
                                        Address</a></li>
                                <li className="nav item customer-wishlist-list"><a href="https://www.muftijeans.in/wishlist/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/wishlist.png" />Wishlist</a>
                                </li>
                                <style>{`
                                    .block-dashboard-info .box-actions {
                                        display: none;
                                    }
                                `}</style>
                            </ul>
                        </div>
                    </div>
                </div> */}

                    <Phonenav/>





            </div>
        </main>
   
   
   </>
  )
}

export default Myorder

import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { MyContext } from "../Context/Mycontext";
import Signpage from "../Signpage/Signpage";
import Mybagsidebar from "../Mybag/Mybagsidebar";
import MyImage from "./LOGOnew.png";
import Signup from "../Signpage/Signup";
import Mybags from "./Mybags";
import Searchbarproduct from "./Searchbarproduct";
import { useNavigate } from "react-router-dom";

function Navbar() {

  const navigate = useNavigate();
 

  const [showsearchbar, setshowsearchbar] = useState("none");
  const [usericn, setusericn] = useState("none");
  const [usericnsign, setusericnsign] = useState("block");

  const context = useContext(MyContext);

  const {
    MainSidebar,
    setMainSidebar,
    token,
    MainSignpage,
    setMainSignpage,
    replaceHeadContent,
    setSignupformpop,
    setLoginformpop,
    url2
  } = context;

  const opensearchbar = () => {
    setshowsearchbar("");
  };

  const closesearchbar = () => {
    setshowsearchbar("none");
  };

  useEffect(() => {
    if (token) {
      // If token exists, block the user profile (you can also set it to a different value if needed)

      setusericn("block");
      setusericnsign("none");
    } else {
      // If token is null, set user profile to none (invisible)

      setusericn("none");
      setusericnsign("block");
    }
  }, [token]);

  const [searchQuery, setSearchQuery] = useState(""); // For storing the search input
  const [products, setProducts] = useState([]); // For storing fetched products

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Fetch products based on search query
  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `${url2}api/products/search?query=${searchQuery}`
      );
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Fetch products whenever the search query changes
  useEffect(() => {
    fetchProducts();
  }, [searchQuery]);

  return (
    <>
      <>
        <header className="page-header">
          <div className="header content header-transparent">
            <div className="hdrbtm">
              <div className="inrbx_wdth">
                <div className="inrbx_inr">
                  <div className="header-left">
                    <div
                      className="hdrbtm_btm"
                      onClick={() => {
                        setMainSidebar(true);
                      }}
                    >
                      <div className="hdrbtmrht">
                        <div className="hmbrgrmnu">
                          <div className="hamburger-box">
                            {" "}
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/icons/menu_home.svg"
                              alt="Mufti Jeans"
                              width="40"
                              height="31"
                            />
                          </div>
                        </div>
                        <div className="topnavouter">
                          <div> </div>
                        </div>
                      </div>
                    </div>
                    <div className="logobx">
                      <h1 className="logoh">
                        {" "}
                        <a href="/" title="Muftijeans">
                          <img
                            src={MyImage}
                            alt="casamanolo"
                            width="240"
                            height="31"
                          />
                        </a>{" "}
                      </h1>
                    </div>
                  </div>
                  <div className="header-right">
                    <ul className="topinrrht">
                      <li className="srcbx" onClick={opensearchbar}>
                        <div className="search-form not-full">
                          <div
                            className="srchbxnw"
                            style={{ left: 0, display: showsearchbar }}
                          >
                            <div className="srchbninr">
                              <div className="srchhdng">
                                <a
                                  onClick={closesearchbar}
                                  className="closesrch"
                                  href=""
                                />
                              </div>
                              <div className="srchfrmbx">
                                <div className="form-search-mufti">
                                  <section className="amsearch-wrapper-block">
                                    <div
                                      className="amsearch-form-block -opened"
                                      data-bind=" style: { width: resized() ? data.width + 'px' : '' }, css: { '-opened': opened }, afterRender: initCssVariables"
                                      style={{ width: 900 }}
                                    >
                                      <section
                                        className="amsearch-input-wrapper -dynamic-width -typed -match"
                                        data-bind=" css: { '-dynamic-width': data.isDynamicWidth, '-match': $data.readyForSearch(), '-typed': inputValue && inputValue().length }"
                                      >
                                        <input
                                          type="text"
                                          name="q"
                                          id="ajax-mp-search"
                                          placeholder="Enter Keyword or Item"
                                          className="amsearch-input"
                                          value={searchQuery}
                                          onChange={handleSearchChange}
                                          maxLength={128}
                                        />
                                        <section
                                          className="amsearch-loader-block"
                                          data-bind="visible: loading"
                                          style={{ display: "none" }}
                                        />
                                        {/* /ko */}
                                        <button
                                          className="amsearch-button -close -clear -icon"
                                          style={{}}
                                          data-bind=" event: { click: inputValue.bind(this, '') }, attr: { title: $t('Clear Field') }, visible: inputValue && inputValue().length"
                                          title="Clear Field"
                                        />{" "}
                                        <button
                                          className="amsearch-button -loupe -clear -icon"
                                          data-bind=" event: { click: search }, css: { '-disabled': !$data.match() }, attr: { title: $t('Search') }"
                                          style={{ marginTop: 8 }}
                                          title="Search"
                                          fdprocessedid="zu4i6t"
                                        />
                                      </section>
                                      <section
                                        className="amsearch-result-section"
                                        data-amsearch-js="results"
                                        style={{}}
                                      >
                                        {/* <section
                                          className="amsearch-items-section"
                                          data-bind="foreach: $data.searchItems()"
                                        >
                                          <div data-bind="afterRender: $parent.updateHtml, html: $data.html">
                                            <section
                                              className="amsearch-item-container amsearch-collapsible-section -category"
                                              data-bind="mageInit: {'amsearchCollapsible':{}}"
                                              data-amcollapse-js="wrapper"
                                              data-amsearch-analytics="block"
                                            >
                                              <p
                                                className="amsearch-block-header amsearch-collapsible-title"
                                                data-role="title"
                                              >
                                                <span className="amsearch-title">
                                                  Categories
                                                </span>{" "}
                                              </p>
                                              <ul
                                                className="amsearch-tab-items amsearch-collapsible-content"
                                                data-role="content"
                                              >
                                                <li className="amsearch-item">
                                                  <a
                                                    className="amsearch-link item-name"
                                                    href="https://www.muftijeans.in/shirts-for-men/check-shirts.html"
                                                    title="Casual Shirts for Men > Check Shirts for Men"
                                                  >
                                                    Check
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men
                                                  </a>
                                                  <p className="amsearch-description">
                                                    Stylish Check{" "}
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men The ch...
                                                  </p>
                                                </li>
                                                <li className="amsearch-item">
                                                  <a
                                                    className="amsearch-link item-name"
                                                    href="https://www.muftijeans.in/t-shirts-for-men.html"
                                                    title="T-Shirts for Men"
                                                  >
                                                    T-
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men
                                                  </a>
                                                  <p className="amsearch-description">
                                                    T-
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men by MUFTI Mufti
                                                    bring...
                                                  </p>
                                                </li>
                                                <li className="amsearch-item">
                                                  <a
                                                    className="amsearch-link item-name"
                                                    href="https://www.muftijeans.in/shirts-for-men.html"
                                                    title="Casual Shirts for Men"
                                                  >
                                                    Casual
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men
                                                  </a>
                                                  <p className="amsearch-description">
                                                    MUFTI: Discover Trending
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men ...
                                                  </p>
                                                </li>
                                                <li className="amsearch-item">
                                                  <a
                                                    className="amsearch-link item-name"
                                                    href="https://www.muftijeans.in/shirts-for-men/printed-shirts.html"
                                                    title="Casual Shirts for Men > Printed Shirts for Men"
                                                  >
                                                    Printed
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men
                                                  </a>
                                                  <p className="amsearch-description">
                                                    Explore the Mufti’s Printed{" "}
                                                    <span className="amsearch-highlight">
                                                      Shirt
                                                    </span>
                                                    s htmlFor Men ...
                                                  </p>
                                                </li>
                                              </ul>
                                            </section>
                                          </div>
                                        </section> */}

                                        <section className="amsearch-products-section -grid">
                                          <p
                                            className="amsearch-block-header amsearch-collapsible-title"
                                            data-role="title"
                                          >
                                            <span className="amsearch-title">
                                              Products
                                              <span className="amsearch-count">
                                                1396
                                              </span>
                                            </span>{" "}
                                            <a
                                              href="https://www.muftijeans.in/catalogsearch/result/?q=shirt"
                                              title="View All"
                                              className="amsearch-link -view-all"
                                            >
                                              View All{" "}
                                              <span className="amsearch-count">
                                                1391
                                              </span>
                                            </a>
                                          </p>
                                          <ul className="amsearch-product-list">
                                            <li
                                              className="amsearch-item product-item"
                                              data-click-url="https://www.muftijeans.in/white-graphic-print-t-shirt-mfk-9422-q-white.html"
                                            >
                                              <a
                                                className="amsearch-image"
                                                title="White Graphic Print T-Shirt"
                                                href="https://www.muftijeans.in/white-graphic-print-t-shirt-mfk-9422-q-white.html"
                                              >
                                                <span
                                                  className="product-image-container product-image-container-85464"
                                                  style={{ width: 1000 }}
                                                >
                                                  <span
                                                    className="product-image-wrapper"
                                                    style={{
                                                      paddingBottom: "133.3%",
                                                    }}
                                                  >
                                                    <img
                                                      className="product-image-photo"
                                                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/1000x1333/16-04-24_mfk-9422-q-02-white_1_mfk-9422-q-02-white.jpg"
                                                      loading="lazy"
                                                      width="1000"
                                                      height="1333"
                                                      alt="White Graphic Print T-Shirt"
                                                    />
                                                  </span>
                                                </span>
                                                <style>{`
                                                                                            .product-image-container-85464 {
                                                                                                width: 1000px;
                                                                                            }

                                                                                            .product-image-container-85464 span.product-image-wrapper {
                                                                                                padding-bottom: 133.3%;
                                                                                            }
                                                                                        `}</style>
                                              </a>
                                              <section className="amsearch-description product details product-item-details">
                                                <a
                                                  className="amsearch-link product-item-link"
                                                  href="https://www.muftijeans.in/white-graphic-print-t-shirt-mfk-9422-q-white.html"
                                                  title="White Graphic Print T-Shirt"
                                                >
                                                  White Graphic Print T-Shi...
                                                </a>
                                                <div className="amsearch-wrapper-inner">
                                                  <div
                                                    className="price-box price-final_price"
                                                    data-role="priceBox"
                                                    data-product-id="85464"
                                                    data-price-box="product-id-85464"
                                                  >
                                                    <span className="normal-price">
                                                      <span className="price-container price-final_price tax weee">
                                                        <span className="price-label" />
                                                        <span
                                                          id="product-price-85464"
                                                          data-price-amount="699"
                                                          data-price-type="finalPrice"
                                                          className="price-wrapper "
                                                        >
                                                          <span className="price">
                                                            ₹699
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>{" "}
                                                    <span className="old-price sly-old-price no-display">
                                                      <span className="price-container price-final_price tax weee">
                                                        <span className="price-label">
                                                          Regular Price
                                                        </span>
                                                        <span
                                                          id="old-price-85464"
                                                          data-price-amount="1399"
                                                          data-price-type="oldPrice"
                                                          className="price-wrapper "
                                                        >
                                                          <span className="price">
                                                            ₹1,399
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="product actions product-item-actions"
                                                    item-actions="1"
                                                    data-amsearch-js="product-item"
                                                  >
                                                    <div className="actions-primary">
                                                      <form
                                                        className="amasty-xsearch-form"
                                                        data-role="tocart-form"
                                                        action="https://www.muftijeans.in/checkout/cart/add/uenc/aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9hbWFzdHlfeHNlYXJjaC9hdXRvY29tcGxldGUvaW5kZXgvP3E9JTIwc2hpcnQmdWVuYz1hSFIwY0hNNkx5OTNkM2N1YlhWbWRHbHFaV0Z1Y3k1cGJpOXphR2x5ZEhNdFptOXlMVzFsYmk1b2RHMXMmZm9ybV9rZXk9Qkc2dk9sUHc0OHQzRlg3byZfPTE3MjgxMzIwNjg0NzI%2C/product/85464/"
                                                        method="post"
                                                      >
                                                        <input
                                                          type="hidden"
                                                          name="product"
                                                          value="85464"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name="uenc"
                                                          value="aHR0cHM6Ly93d3cubXVmdGlqZWFucy5pbi9jaGVja291dC9jYXJ0L2FkZC91ZW5jL2FIUjBjSE02THk5M2QzY3ViWFZtZEdscVpXRnVjeTVwYmk5aGJXRnpkSGxmZUhObFlYSmphQzloZFhSdlkyOXRjR3hsZEdVdmFXNWtaWGd2UDNFOUpUSXdjMmhwY25RbWRXVnVZejFoU0ZJd1kwaE5Oa3g1T1ROa00yTjFZbGhXYldSSGJIRmFWMFoxWTNrMWNHSnBPWHBoUjJ4NVpFaE5kRnB0T1hsTVZ6RnNZbWsxYjJSSE1YTW1abTl5YlY5clpYazlRa2MyZGs5c1VIYzBPSFF6UmxnM2J5WmZQVEUzTWpneE16SXdOamcwTnpJJTJDL3Byb2R1Y3QvODU0NjQv"
                                                        />
                                                        <input
                                                          name="form_key"
                                                          type="hidden"
                                                          value="Oh5Dn3Bcs3geVw09"
                                                        />
                                                        <button
                                                          type="submit"
                                                          title="ADD TO BAG"
                                                          className="action tocart primary"
                                                          fdprocessedid="x65cdc"
                                                        >
                                                          <span>
                                                            ADD TO BAG
                                                          </span>
                                                        </button>
                                                      </form>
                                                    </div>
                                                    <div
                                                      data-role="add-to-links"
                                                      className="actions-secondary"
                                                    >
                                                      <a
                                                        href="#"
                                                        className="amsearch-button -clear -wishlist -icon"
                                                        title="Add to Wish List"
                                                        aria-label="Add to Wish List"
                                                        role="button"
                                                      />
                                                      <a
                                                        href="#"
                                                        className="amsearch-button -clear -compare -icon"
                                                        title="Add to Compare"
                                                        aria-label="Add to Compare"
                                                        role="button"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            </li>

                                            {products.length > 0 ? (
                                              products.map((product) => (
                                                <Searchbarproduct
                                                  key={product._id}
                                                  product={product}
                                                />
                                              ))
                                            ) : (
                                              <p>No products found</p>
                                            )}
                                          </ul>
                                        </section>
                                      </section>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="actions-search-mufti">
                            <a
                              href="javascript:void(0)"
                              id="loginPanel"
                              className="srchbx"
                            />
                          </div>
                        </div>
                      </li>

                      <li className="lgnbx" style={{ display: usericn }}>
                        <div className="dropdown dropdown-toplinks">
                          <a
                            href="JavaScript:void(0)"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            title="My Account"
                          />
                          <div className="dropdown-menu">
                            <ul className="header links">
                             
                              <li>
                                <a
                                  href="/account"
                                  id="idh4vjpNH3"
                                >
                                  My Account
                                </a>
                              </li>
                              
                              
                              
                            {" "}
                              <li onClick={()=>{
                                localStorage.removeItem("token");
                                navigate("/");
                              }}
                                className="link authorization-link"
                                data-label="or"
                              >
                                {" "}
                                <a href="">
                                  Sign Out
                                </a>
                              </li>{" "}
                            </ul>
                          </div>
                        </div>
                      </li>

                      {/* <li
                        className="lgnbx"
                        style={{ display: usericnsign }}
                        onClick={() => {
                          setMainSignpage(true);
                        }}
                      >
                        <div className="dropdown dropdown-toplinks">
                          <a
                            href="JavaScript:void(0)"
                            className="ajax-register-link dropdown-toggle"
                            data-toggle="dropdown"
                            title="My Account"
                          />
                        </div>
                      </li> */}

                      <li className="lgnbx" style={{ display: usericnsign }}>
                        <div className="dropdown dropdown-toplinks">
                          <a
                            href="JavaScript:void(0)"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            title="My Account"
                          />
                          <div className="dropdown-menu">
                            <ul className="header links">
                              <li onClick={()=>{
                        setMainSignpage(true);
                        setSignupformpop(true);
                        setLoginformpop(false);
                      }}>
                                <a
                                  href="#"
                                  id="idh4vjpNH3"
                                >
                                  Create Account
                                </a>
                              </li>{" "}
                              <li onClick={()=>{
                        setMainSignpage(true);
                        setLoginformpop(true);
                        setSignupformpop(false);
                      }}
                                className="link authorization-link"
                                data-label="or"
                              >
                                {" "}
                                <a href="#">
                                  Login Account
                                </a>
                              </li>{" "}
                            </ul>
                          </div>
                        </div>
                      </li>

                      <Mybags />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="widget block block-static-block">
          <div
            data-content-type="html"
            data-appearance="default"
            data-element="main"
            data-decoded="true"
          >
            <div className="cart-checkout-offer11 ticker-top">
              <div
                className="cartresultMarquee"
                style={{ textAlign: "center" }}
              >
                <strong>
                  Get 10% Off Your First Purchase | Use Code: Casamanolo10
                </strong>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* {MainSidebar === 'true' && <Sidebar/>} */}

      {MainSidebar && <Sidebar />}

      {MainSignpage && <Signup />}
    </>
  );
}

export default Navbar;

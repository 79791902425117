import React, { useContext, useState } from "react";
import { MyContext } from "../Context/Mycontext";
import { Link } from "react-router-dom";

function Phonenav() {

    const context = useContext(MyContext);

    const {navshow,setnavshow} =context;

   





  return (
    <>
    
        


        <div className="sidebar sidebar-main desktop-filter">
          
          <div className="custom_menu_top_img" onClick={()=>{
            setnavshow("active")
          }}>
            <div className="user-image desk-user-image">
              <a className="naviLevel" href="javascript:void(0)">
                <svg width="36" height="36" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" opacity="0.05" />
                    <path
                      fill="#000"
                      d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className={`custom_menu_top ${navshow}`} >
            <div className="mufti-userprofile">
              <a  onClick={()=>{
            setnavshow("")
          }}
                className="mblclosemenu dskclose logedin"
                href="javascript:void(0)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="close-icon"
                  fill="#ffffff"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" opacity="0.05" />
                    <path
                      fill="#ffffff"
                      d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                    />
                  </g>
                </svg>
              </a>
              <div className="user-image1">
                <a className="naviLevel" href="javascript:void(0)">
                  <svg width="36" height="36" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" opacity="0.05" />
                      <path
                        fill="#fff"
                        d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
           
          </div>


          <div className={`block block-collapsible-nav ${navshow}`}>
            <div className="title block-collapsible-nav-title">
              <strong> My Account</strong>
            </div>
            <div
              className="content block-collapsible-nav-content"
              id="block-collapsible-nav"
            >
              <ul className="nav items">
                
                <li className="nav item">
                  <span className="delimiter" />
                </li>
                <li className="nav item">
                  <span className="delimiter" />
                </li>



                <Link to="/myorder">
                
                
                
                <li className="nav item customer-order-list" onClick={()=>{
            setnavshow("")
          }}>
                  <a
                    className="my-orders"
                    href="https://www.muftijeans.in/sales/order/history/"
                  >
                    <img src="https://d4kuloxg8pkbr.cloudfront.net/icons/my-orders.png" />
                    My Orders
                  </a>
                </li>
                </Link>
                
                
                


                <Link to="/account" onClick={()=>{
            setnavshow("")
          }}>
                <li className="nav item customer-profile">
                  <a href="">
                    <img src="https://d4kuloxg8pkbr.cloudfront.net/icons/profile.png" />
                    Profile
                  </a>
                  {/* <ul className="nav-sub-menu">
                  <Link to="/account">
                    <li className="nav item ">
                      <a href="">
                        Account Information
                      </a>
                    </li>

                    </Link>
                    


                    <Link to="/updatecom">
                    <li className="nav item">
                      <a href="">
                        Update Communication
                      </a>
                    </li>
                    </Link>


                  </ul> */}
                </li>
                </Link>
                
                <Link to="/updatecom" onClick={()=>{
            setnavshow("")
          }}>
                <li className="nav item customer-manage-address">
                  <a href="">
                    <img src="https://d4kuloxg8pkbr.cloudfront.net/icons/profile.png" />
                    Update Communication
                  </a>
                </li>

                </Link>
                

                <Link to="/address" onClick={()=>{
            setnavshow("")
          }}>
                <li className="nav item customer-manage-address">
                  <a href="https://www.muftijeans.in/customer/address/">
                    <img src="https://d4kuloxg8pkbr.cloudfront.net/icons/manage-address.png" />
                    Manage Address
                  </a>
                </li>

                </Link>



                <Link to="/myorder" onClick={()=>{
            setnavshow("")
          }}>

                <li className="nav item customer-wishlist-list">
                  <a href="https://www.muftijeans.in/wishlist/">
                    <img src="https://d4kuloxg8pkbr.cloudfront.net/icons/wishlist.png" />
                    Wishlist
                  </a>
                </li>

                </Link>
                <style>{` .block-dashboard-info .box-actions { display: none; }`}</style>
              </ul>
            </div>
          </div>




        </div>


   
    </>
  );
}

export default Phonenav;

import React, { useEffect, useState } from 'react'

import "./Home.css"


function Home() {

   
 


     // Desktop slider states
     const [currentSlide, setCurrentSlide] = useState(0);
     const [currentSlide1, setCurrentSlide1] = useState(0);
     const [currentSlideMobi, setCurrentSlideMobi] = useState(0);
     const [currentSlideMobi2, setCurrentSlideMobi2] = useState(0);
 
     // Array of desktop images
     const images = [
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/SLIDING-BANNERS-new-03.jpg?v=1232",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/fit-in/SLIDING-BANNERS-new-02.jpg?v=1232",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/fit-in/AW24-road-to-style-banners.jpg?v=1232",
     ];
 
     const images2 = [
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/3.jpeg",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/1.jpeg",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/2.jpeg",
     ];
 
     // Array of mobile images
     const imagesmobi = [
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/fit-in/SLIDING-BANNERS-new-06.jpg?v=1230",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/fit-in/AW24-road-to-style-banners-mobile.jpg?v=1230",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/fit-in/SLIDING-BANNERS-new-06.jpg?v=1230",
     ];
 
     const imagesmobi2 = [
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/3.jpeg",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/1.jpeg",
         "https://d118ps6mg0w7om.cloudfront.net/media/boolfly/banner/4.jpeg",
     ];
 
     // useEffect to manage all sliders
     useEffect(() => {
         // Functions to update each slide independently
         const nextSlide = () => setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
         const nextSlide1 = () => setCurrentSlide1((prevSlide) => (prevSlide + 1) % images2.length);
         const nextSlidemobi = () => setCurrentSlideMobi((prevSlide) => (prevSlide + 1) % imagesmobi.length);
         const nextSlidemobi2 = () => setCurrentSlideMobi2((prevSlide) => (prevSlide + 1) % imagesmobi2.length);
 
         // Set up intervals for each slider
         const interval1 = setInterval(nextSlide, 4500);    // 4.5 seconds
         const interval2 = setInterval(nextSlide1, 4500);   // 4.5 seconds
         const interval3 = setInterval(nextSlidemobi, 7000); // 3.5 seconds
         const interval4 = setInterval(nextSlidemobi2, 7000); // 4 seconds
 
         // Cleanup intervals on unmount
         return () => {
             clearInterval(interval1);
             clearInterval(interval2);
             clearInterval(interval3);
             clearInterval(interval4);
         };
     }, []); // Only runs once on component mount
      
  return (



   
   
 
<><div>

            <div className="responsive-banner-slider">
                <div className="banner-image-container">
                    <a href="/product" tabIndex={0}>
                        <img className="desktop-banner" src={images[currentSlide]} alt="AW-24 New arrival" loading="lazy" />
                        
                        

                        <img className="mobile-banner" src={imagesmobi[currentSlide]} alt="AW-24 New arrival" loading="lazy" />
                        
                    </a>
                    
                    
                </div>
            </div>





            
        <br />




        <div className="responsive-banner-slider">
                <div className="banner-image-container">
                    <a href="/product" tabIndex={0}>
                        <img className="desktop-banner" src={images2[currentSlide]} alt="AW-24 New arrival" loading="lazy" />
                        
                        

                        <img className="mobile-banner" src={imagesmobi2[currentSlide]} alt="AW-24 New arrival" loading="lazy" />
                        
                    </a>
                    
                    
                </div>
            </div>

           
        
        
           
        
            <div className="">
                <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                  <div className="  ">
                    <div className="strybxinrmuk ">
                        
                      <h2 className="" style={{textAlign:"center",color:"light",padding:"px"}}>Categories</h2>
                      <div className="strybxinrmuk-fullwidth">
                        <ul>
                          <li>
                            <div className="cat_wrappermuk">
                              <div className="imgbxmuk">
                                <a href="/product">
                                  <img className=" " alt="Muftijeans New Arrival Shirts" src="https://d118ps6mg0w7om.cloudfront.net/media/images/c-t-shirts.jpeg" loading="lazy" />
                                </a>
                              </div>
                              { /* overlaymuk with item name */ }
                              <div className="overlaymuk">T-shirts</div>
                            </div>
                          </li>
                          <li>
                            <div className="cat_wrappermuk">
                              <div className="imgbxmuk">
                                <a href="/product">
                                  <img className=" " alt="Muftijeans Sweatshirts" src="https://d118ps6mg0w7om.cloudfront.net/media/images/d-sweaters.jpeg" loading="lazy" />
                                </a>
                              </div>
                              { /* overlaymuk with item name */ }
                              <div className="overlaymuk">Sweatshirts</div>
                            </div>
                          </li>
                          <li>
                            <div className="cat_wrappermuk">
                              <div className="imgbxmuk">
                                <a href="/product">
                                  <img className=" " alt="Muftijeans New Arrival Shirts" src="https://d118ps6mg0w7om.cloudfront.net/media/images/c-shirts.jpeg" loading="lazy" />
                                </a>
                              </div>
                              { /* overlaymuk with item name */ }
                              <div className="overlaymuk">Full-Sleeve Shirts</div>
                            </div>
                          </li>
                          <li>
                            <div className="cat_wrappermuk">
                              <div className="imgbxmuk">
                                <a href="/product">
                                  <img className=" " alt="Muftijeans New Arrival Jeans" src="https://d118ps6mg0w7om.cloudfront.net/media/images/c-jeans.jpeg" loading="lazy" />
                                </a>
                              </div>
                              { /* overlaymuk with item name */ }
                              <div className="overlaymuk">Jeans</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        
            </div>
            
              
                <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                   



                    <div className="new-in homepage-product-slider">
                        <div className="widget block block-static-block">
                            <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                <div className="block widget block-products-list grid">
                                    <div className="block-title">
                                        <h2>Winter Pastels</h2>
                                    </div>
                                    <div className="block-content">
                                        <div className="products wrapper grid products-grid products-widget-product-grid">
                                            <div className="products list items product-items similar-product slick-initialized slick-slider" id="similar-product"><button className="slick-prev slick-arrow" aria-label="Previous" type="button" style={{}} fdprocessedid="fdowu">Previous</button>
                                                <div className="slick-list draggable">
                                                    <div className="slick-track" style={{ opacity: "1", width: 3762, transform: "translate3d(-342px, 0px, 0px)" }}>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-95537" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/5/fit-in/1000x1333/05-04-24_mfs-14863-s-165-multi_1_mfs-14863-s-165-multi.jpg" loading="lazy" width="1000" height="1333" alt="Multi Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-95537 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-95537 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Multi Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Multi Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="95537" data-price-box="product-id-95537">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-96415" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/1/fit-in/1000x1333/01-04-24_mfs-14870-s-88-beige_1_mfs-14870-s-88-beige.jpg" loading="lazy" width="1000" height="1333" alt="Beige Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-96415 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-96415 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Beige Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="96415" data-price-box="product-id-96415">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-99640" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/a/w/fit-in/1000x1333/aw24_lot2_mfk-10228-t-26-lilac_1_mfk-10228-t-26-lilac.jpg" loading="lazy" width="1000" height="1333" alt="Lilac Floral Print Slim Fit Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-99640 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-99640 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Lilac Floral Print Slim Fit Polo" href="/product" tabIndex={0}>Lilac Floral Print Slim
                                                                                F...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="99640" data-price-box="product-id-99640">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-99640-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-104210" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-10235-t-24-off-white.jpg" loading="lazy" width="1000" height="1333" alt="Off White Logo Print Half Sleeve Casual Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-104210 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-104210 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Logo Print Half Sleeve Casual Polo" href="/product" tabIndex={0}>Off White Logo Print
                                                                                Half...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="104210" data-price-box="product-id-104210">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-104210-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-95537" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/5/fit-in/1000x1333/05-04-24_mfs-14863-s-165-multi_1_mfs-14863-s-165-multi.jpg" loading="lazy" width="1000" height="1333" alt="Multi Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-95537 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-95537 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Multi Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Multi Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="95537" data-price-box="product-id-95537">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-96415" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/1/fit-in/1000x1333/01-04-24_mfs-14870-s-88-beige_1_mfs-14870-s-88-beige.jpg" loading="lazy" width="1000" height="1333" alt="Beige Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-96415 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-96415 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Beige Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="96415" data-price-box="product-id-96415">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-99640" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/a/w/fit-in/1000x1333/aw24_lot2_mfk-10228-t-26-lilac_1_mfk-10228-t-26-lilac.jpg" loading="lazy" width="1000" height="1333" alt="Lilac Floral Print Slim Fit Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-99640 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-99640 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Lilac Floral Print Slim Fit Polo" href="/product" tabIndex={0}>Lilac Floral Print Slim
                                                                                F...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="99640" data-price-box="product-id-99640">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-99640-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-104210" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-10235-t-24-off-white.jpg" loading="lazy" width="1000" height="1333" alt="Off White Logo Print Half Sleeve Casual Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-104210 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-104210 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Logo Print Half Sleeve Casual Polo" href="/product" tabIndex={0}>Off White Logo Print
                                                                                Half...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="104210" data-price-box="product-id-104210">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-104210-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-95537" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/5/fit-in/1000x1333/05-04-24_mfs-14863-s-165-multi_1_mfs-14863-s-165-multi.jpg" loading="lazy" width="1000" height="1333" alt="Multi Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-95537 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-95537 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Multi Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Multi Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="95537" data-price-box="product-id-95537">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-96415" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/1/fit-in/1000x1333/01-04-24_mfs-14870-s-88-beige_1_mfs-14870-s-88-beige.jpg" loading="lazy" width="1000" height="1333" alt="Beige Digital Print Slim Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-96415 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-96415 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Digital Print Slim Fit Casual Shirt" href="/product" tabIndex={-1}>Beige Digital Print
                                                                                Slim ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="96415" data-price-box="product-id-96415">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-99640" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/a/w/fit-in/1000x1333/aw24_lot2_mfk-10228-t-26-lilac_1_mfk-10228-t-26-lilac.jpg" loading="lazy" width="1000" height="1333" alt="Lilac Floral Print Slim Fit Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-99640 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-99640 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Lilac Floral Print Slim Fit Polo" href="/product" tabIndex={0}>Lilac Floral Print Slim
                                                                                F...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="99640" data-price-box="product-id-99640">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-99640-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-104210" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-10235-t-24-off-white.jpg" loading="lazy" width="1000" height="1333" alt="Off White Logo Print Half Sleeve Casual Polo" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-104210 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-104210 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Logo Print Half Sleeve Casual Polo" href="/product" tabIndex={0}>Off White Logo Print
                                                                                Half...</a>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="104210" data-price-box="product-id-104210">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-104210-final_price" data-price-amount="2299" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹2,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div><button className="slick-next slick-arrow" aria-label="Next" type="button" style={{}} fdprocessedid="6c8rxf">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <style>{`
                                    @media only screen and (min-width: 1024px) {
                                        .products-grid .product-item {
                                            width: 100% !important;
                                            padding: 5px !important;
                                        }
                                    }
                                `}</style>
                            </div>
                        </div>
                    </div>
                    <div className="new-in homepage-product-slider">
                        <div className="widget block block-static-block">
                            <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                <div className="block widget block-products-list grid">
                                    <div className="block-title">
                                        <h2>4 TRIPLE O FIVE O (Oversized)</h2>
                                    </div>
                                    <div className="block-content">
                                        <div className="products wrapper grid products-grid products-widget-product-grid">
                                            <div className="products list items product-items similar-product slick-initialized slick-slider" id="similar-product"><button className="slick-prev slick-arrow" aria-label="Previous" type="button" style={{}} fdprocessedid="86se2l">Previous</button>
                                                <div className="slick-list draggable">
                                                    <div className="slick-track" style={{ opacity: "1", width: 3762, transform: "translate3d(-342px, 0px, 0px)" }}>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98950" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5003-s-01-black_2.jpg" loading="lazy" width="1000" height="1333" alt="Black Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98950 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98950 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Black Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Black Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98950" data-price-box="product-id-98950">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98954" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5002-s-88-beige_1.jpg" loading="lazy" width="1000" height="1333" alt="Beige Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98954 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98954 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Beige Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98954" data-price-box="product-id-98954">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98369" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4039-s-88-beige_2.jpg" loading="lazy" width="1000" height="1333" alt="Beige Large Check Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98369 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98369 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Large Check Loose Fit Casual Shirt" href="/product" tabIndex={0}>Beige Large Check Loose
                                                                                F...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                2999</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98369" data-price-box="product-id-98369">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98369-final_price" data-price-amount="1499" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98369-final_price" data-price-amount="2999" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,999</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98370" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4016-s-24-off-white_2.jpg" loading="lazy" width="1000" height="1333" alt="Off White Textured Plain Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98370 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98370 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Textured Plain Loose Fit Casual Shirt" href="/product" tabIndex={0}>Off White Textured
                                                                                Plain ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98370" data-price-box="product-id-98370">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98370-final_price" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98370-final_price" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98950" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5003-s-01-black_2.jpg" loading="lazy" width="1000" height="1333" alt="Black Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98950 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98950 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Black Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Black Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98950" data-price-box="product-id-98950">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98954" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5002-s-88-beige_1.jpg" loading="lazy" width="1000" height="1333" alt="Beige Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98954 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98954 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Beige Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98954" data-price-box="product-id-98954">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98369" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4039-s-88-beige_2.jpg" loading="lazy" width="1000" height="1333" alt="Beige Large Check Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98369 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98369 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Large Check Loose Fit Casual Shirt" href="/product" tabIndex={0}>Beige Large Check Loose
                                                                                F...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                2999</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98369" data-price-box="product-id-98369">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98369-final_price" data-price-amount="1499" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98369-final_price" data-price-amount="2999" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,999</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98370" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4016-s-24-off-white_2.jpg" loading="lazy" width="1000" height="1333" alt="Off White Textured Plain Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98370 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98370 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Textured Plain Loose Fit Casual Shirt" href="/product" tabIndex={0}>Off White Textured
                                                                                Plain ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98370" data-price-box="product-id-98370">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98370-final_price" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98370-final_price" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98950" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5003-s-01-black_2.jpg" loading="lazy" width="1000" height="1333" alt="Black Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98950 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98950 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Black Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Black Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98950" data-price-box="product-id-98950">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98954" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5002-s-88-beige_1.jpg" loading="lazy" width="1000" height="1333" alt="Beige Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98954 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98954 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Beige Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98954" data-price-box="product-id-98954">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98369" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4039-s-88-beige_2.jpg" loading="lazy" width="1000" height="1333" alt="Beige Large Check Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98369 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98369 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Large Check Loose Fit Casual Shirt" href="/product" tabIndex={0}>Beige Large Check Loose
                                                                                F...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                2999</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98369" data-price-box="product-id-98369">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98369-final_price" data-price-amount="1499" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98369-final_price" data-price-amount="2999" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,999</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98370" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4016-s-24-off-white_2.jpg" loading="lazy" width="1000" height="1333" alt="Off White Textured Plain Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98370 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98370 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Textured Plain Loose Fit Casual Shirt" href="/product" tabIndex={0}>Off White Textured
                                                                                Plain ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98370" data-price-box="product-id-98370">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98370-final_price" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98370-final_price" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-2" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98950" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5003-s-01-black_2.jpg" loading="lazy" width="1000" height="1333" alt="Black Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98950 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98950 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Black Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Black Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98950" data-price-box="product-id-98950">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" style={{ width: 171 }} tabIndex={-1}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={-1}> <span className="product-image-container product-image-container-98954" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-5002-s-88-beige_1.jpg" loading="lazy" width="1000" height="1333" alt="Beige Relaxed Fit Drawstring Trouser" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98954 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98954 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Relaxed Fit Drawstring Trouser" href="/product" tabIndex={-1}>Beige Relaxed Fit
                                                                                Drawstr...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98954" data-price-box="product-id-98954">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98369" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4039-s-88-beige_2.jpg" loading="lazy" width="1000" height="1333" alt="Beige Large Check Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98369 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98369 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Beige Large Check Loose Fit Casual Shirt" href="/product" tabIndex={0}>Beige Large Check Loose
                                                                                F...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                2999</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98369" data-price-box="product-id-98369">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98369-final_price" data-price-amount="1499" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98369-final_price" data-price-amount="2999" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,999</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: 171 }}>
                                                            <div>
                                                                <div className="item product product-item " style={{ width: "100%", display: "inline-block" }}>
                                                                    <div className="product-item-info"><a href="/product" className="product photo product-item-photo" tabIndex={0}> <span className="product-image-container product-image-container-98370" style={{ width: 1000 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.3%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_bndr-4016-s-24-off-white_2.jpg" loading="lazy" width="1000" height="1333" alt="Off White Textured Plain Loose Fit Casual Shirt" /></span></span>
                                                                            <style>{`
                                                                                .product-image-container-98370 {
                                                                                    width: 1000px;
                                                                                }

                                                                                .product-image-container-98370 span.product-image-wrapper {
                                                                                    padding-bottom: 133.3%;
                                                                                }
                                                                            `}</style>

                                                                        </a>
                                                                        <div className="product details product-item-details">
                                                                            <a className="product-item-link" title="Off White Textured Plain Loose Fit Casual Shirt" href="/product" tabIndex={0}>Off White Textured
                                                                                Plain ...</a>
                                                                            <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper"><span className="price">₹
                                                                                                3299</span></span></span></span>
                                                                            </div>
                                                                            <div className="price-box price-final_price" data-role="priceBox" data-product-id="98370" data-price-box="product-id-98370">
                                                                                <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label" />
                                                                                        <span id="old-price-98370-final_price" data-price-amount="1649" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,649</span></span>
                                                                                    </span></span> <span className="old-price sly-old-price no-display">
                                                                                    <span className="price-container price-final_price tax weee">
                                                                                        <span className="price-label">Regular
                                                                                            Price</span> <span id="old-price-98370-final_price" data-price-amount="3299" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹3,299</span></span>
                                                                                    </span></span>
                                                                            </div>
                                                                            <div className="buyfree-label">-50% OFF
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><button className="slick-next slick-arrow" aria-label="Next" type="button" style={{}} fdprocessedid="jn8e7k">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <style>{`
                                    @media only screen and (min-width: 1024px) {
                                        .products-grid .product-item {
                                            width: 100% !important;
                                            padding: 5px !important;
                                        }
                                    }
                                `}</style>
                            </div>
                        </div>
                    </div>

                    <div className="newarrivals-sale">
                        <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                            <div className="newarrivals-sale-txt">
                                <div onClick={event => { window.location.href = 'https://www.muftijeans.in/new-arrival.html'; }} className="newarrivals-sale-lft"><a href="/product">
                                        <img src="https://doemivmlhd3v6.cloudfront.net/images/OFFER-WEBSITE-BANNERS-02.jpg?v=435345346" style={{ width: "100%" }} />
                                    </a></div>
                                    <div onClick={event => { window.location.href = 'https://www.muftijeans.in/new-arrival.html'; }} className="newarrivals-sale-lft"><a href="/product">
                                        <img src="https://doemivmlhd3v6.cloudfront.net/images/OFFER-WEBSITE-BANNERS-02.jpg?v=435345346" style={{ width: "100%" }} />
                                    </a></div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="storelocater-newletter">
                        <div className="storelocater-newletter-box">
                            <div className="storelocater-newletter-lft">
                                <div className="storelocater-newletter-lftsec">
                                    <div className="storelocater-ico"> </div>
                                    <div className="storelocater-content">
                                        <h3>Store Locator</h3>
                                        <p>Your ultimate fashion destination is right around the corner.</p>
                                        <a className="storelink" href="/product">Find Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="storelocater-newletter-rht">
                                <div className="storelocater-newletter-rhtsec">
                                    <div className="sgntop">Like to hear from us!</div>
                                    <div className="sgnfldbxp">
                                        <div className="block newsletter">
                                            <div className="content">
                                                <form className="form subscribe" noValidate action="https://www.muftijeans.in/newsletter/subscriber/new/" method="post" id="newsletter-validate-detail-footer">
                                                    <div className="field form-group newsletter">
                                                        <div className="control"><input name="email" type="email" id="newsletter-footer" placeHolder="Your email address" data-validate="{required:true, 'validate-email':true}" fdprocessedid="jk4mfvg" />
                                                        </div>
                                                    </div>
                                                    <div className="actions"><button className="action subscribe btn btn-primary btn-sm" title="Subscribe" type="submit" fdprocessedid="f0vvhd"><span>Subscribe</span></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sgnbtm">By entering your email, you are agreeing to Mufti’s Privacy
                                        Policy.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></> 
 
 
 
 
 
 
 
  )
}

export default Home

import React, { useContext, useEffect } from "react";
import "./Checkout.css"

import { Link } from "react-router-dom";
import Checkoutform from "./Checkoutform";
import Checkaddress from "./Checkaddress";
import Checkorder from "./Checkorder";
import { MyContext } from "../Context/Mycontext";

function Checkout() {


  useEffect(() => {
    // Function to remove and add classes to the body tag
    const removeAndAddClass = () => {
      const body = document.body;
      body.className = "";
      body.classList.add(
        "checkout-index-index", "onestepcheckout-index-index", "page-layout-1column"
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();
  }, []);



  useEffect(() => {
    // Function to remove old head content and replace it with new one
    const replaceHeadTag = () => {
      const head = document.querySelector("head");
  
      // Remove the old head content
      while (head.firstChild) {
        head.removeChild(head.firstChild);
      }
  
      // Define the new head content
      const newHeadContent = `
        <meta httpequiv="origin-trial" content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9">
        <meta charSet="utf-8">
        <meta name="title" content="Checkout">
        <meta name="robots" content="INDEX, FOLLOW, ARCHIVE">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
        <meta name="format-detection" content="telephone=no">
        <meta name="twitter:card" content="summary_large_image">
        <meta name="twitter:site" content="@muftijeans">
        <meta name="twitter:title" content="Checkout">
        <meta name="twitter:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
        <meta name="twitter:url" content="https://www.muftijeans.in/">
        <meta property="og:title" content="Checkout">
        <meta property="og:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
        <meta property="og:url" content="https://www.muftijeans.in/">
        <title>Checkout</title>
        <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/_cache/merged/75a7ad91dec3cbd4a1a9b75f9a5bf47b.min.css">
        <link rel="stylesheet" type="text/css" media="screen and (min-width: 768px)" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/styles-l.min.css">
        <link rel="stylesheet" type="text/css" media="print" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/print.min.css">
        <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/_cache/merged/161b37fc16b2af05bfc55815c8ca59d2.min.css">
        <link rel="icon" type="image/x-icon" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
        <link rel="shortcut icon" type="image/x-icon" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
        <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/light/opensans-300.woff2">
        <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/regular/opensans-400.woff2">
        <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/semibold/opensans-600.woff2">
        <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/bold/opensans-700.woff2">
        <link rel="preload" as="font" crossOrigin="anonymous" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/Luma-Icons.woff2">
        <meta name="google-site-verification" content="BaylKQFecuyMjXFmIOJNtNP0nVZE7ijQhtX4uJPer6A">
        <meta name="facebook-domain-verification" content="yyehg4kpch6ge5rq5xyr7qxd52ts4y">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap">
        <meta name="google-site-verification" content="googlecb8d2cd7f72f5378">
        <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/custom.min.css">
        <link rel="stylesheet" type="text/css" media="all" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Condensed">
      `;
  
      // Add new content to the head
      head.innerHTML = newHeadContent;
    };
  
    replaceHeadTag();
  }, []);
   


  return (
    <>
     

<main id="maincontent" className="page-main"> <a id="contentarea" tabIndex={-1} />
            <div className="page-title-wrapper">
                <h1 className="page-title"><span className="base" data-ui-id="page-title-wrapper">Checkout</span></h1>
                <p className="checkout-description">Please enter your details below to complete your purchase.</p>
            </div>
            <div className="columns">
                <div className="column main"><input name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" /> <span />
                   
                    <div id="checkout" data-bind="scope:'checkout'" className="checkout-container">

                        <div className="opc-estimated-wrapper" data-bind="blockLoader: isLoading">
                            <div className="estimated-block">
                                <span className="estimated-label" data-bind="i18n: 'Estimated Total'">Estimated Total</span>
                                <span className="estimated-price" data-bind="text: getValue()">₹3,599</span>
                            </div>
                            <div className="minicart-wrapper">
                                <button type="button" className="action showcart" data-bind="click: showSidebar" data-toggle="opc-summary" fdprocessedid="t3tmva">
                                    <span className="counter qty">
                                        <span className="counter-number" data-bind="text: getQuantity()">1</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                       
                        <div data-role="checkout-messages" className="messages" data-bind="visible: isVisible(), click: removeAll">
                            
                        </div>
                        

                        <div className="opc-wrapper one-step-checkout-wrapper">
                            <div className="opc one-step-checkout-container" id="checkoutSteps">
                                <div className="col-mp mp-6 mp-sm-5 mp-xs-12">
                                    <div className="row-mp">
                                       
                                        <div data-bind="scope: 'checkout.osc-steps-before'">

                                        </div>
                                        
                                       
                                            <Checkoutform />

                                            <Checkaddress/>
                                   
                                        



                                       
                                       
                                    </div>
                                </div>

                                
                               

                               <Checkorder/>


                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </main>




        <style>{`
                        .shippingTip-base-tipRed {
                            display: inline-block;
                            color: #000;
                            font-weight: 600;
                        }

                        .checkout-banner {
                            padding: 10px 0;
                            text-align: center;
                            font-size: 18px;
                            background: #f5f5f5;
                        }
                    `}</style>



    </>
  );
}

export default Checkout;

import React, { useContext, useEffect, useState } from "react";

import "./Productone.css";
import { Link, useParams } from "react-router-dom";
import Productreleted from "./Productreleted";
import Productmedia from "./Productmedia";
import { MyContext } from "../Context/Mycontext";

function Productone() {
  const context = useContext(MyContext);

  const { url2, delhiPincodes } = context;

  const [selectedSize, setSelectedSize] = useState(null); // State to hold the selected size

  const handleSizeClick = (size) => {
    setSelectedSize(size); // Update selected size
  };

  // State to store the input value
  const [inputPincode, setInputPincode] = useState("");
  const [inputPincodea, setInputPincodea] = useState("none");
  const [Quantity, setQuantity] = useState(1);

  // State to store the check result
  const [isPincodeValid, setIsPincodeValid] = useState("none");

  // Function to handle input change
  const handleInputChange = (e) => {
    setInputPincode(e.target.value);
  };

  // Function to check the pincode
  const checkPincode = () => {
    if (delhiPincodes.includes(Number(inputPincode))) {
      setIsPincodeValid("none");
      setInputPincodea("block"); // Pincode found
    } else {
      setIsPincodeValid("block");
      setInputPincodea("none"); // Pincode not found
    }
  };

  useEffect(() => {
    // Function to remove and add classes to the body tag
    const removeAndAddClass = () => {
      const body = document.body;
      body.className = "";

      body.classList.add(
        "page-product-configurable",
        "catalog-product-view",
        "product-red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red",
        "categorypath-t-shirts-for-men",
        "category-t-shirts-for-men",
        "page-layout-1column"
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();
  }, []);

  useEffect(() => {
    // Function to remove the old head content and replace it with a new one
    const replaceHeadTag = () => {
      // Get the current head element
      const head = document.querySelector("head");

      // Remove the old head content
      while (head.firstChild) {
        head.removeChild(head.firstChild);
      }

      // Define the new head content
      const newHeadContent = `
            <meta http-equiv="origin-trial"
                content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9">
            <meta charset="utf-8">
            <meta name="title" content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online at Muftijeans">
            <meta name="description"
                content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online in India at Muftijeans. Find your size and discounted price. Free Shipping, 7 Days Returns, Easy Exchange">
            <meta name="keywords" content="Red &amp; Yellow Tipped Collar Pique Polo T-shirt">
            <meta name="robots" content="INDEX, FOLLOW, ARCHIVE">
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
            <meta name="format-detection" content="telephone=no">
            <meta name="twitter:card" content="summary_large_image">
            <meta name="twitter:site" content="@muftijeans">
            <meta name="twitter:title" content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online at Muftijeans">
            <meta name="twitter:description"
                content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online in India at Muftijeans. Find your size and discounted price. Free Shipping, 7 Days Returns, Easy Exchange">
            <meta name="twitter:image"
                content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
            <meta name="twitter:url"
                content="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html">
            <meta property="og:title" content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online at Muftijeans">
            <meta property="og:description"
                content="Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online in India at Muftijeans. Find your size and discounted price. Free Shipping, 7 Days Returns, Easy Exchange">
            <meta property="og:image"
                content="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/265x265/1_mfk-9307-q-03-red_6.jpg">
            <meta property="og:url"
                content="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html">
            <meta property="og:type" content="product">
            <meta property="product:availability" content="instock">
            <meta property="product:category" content="T-Shirts for Men">
            <meta property="product:condition" content="new">
            <meta property="product:price:currency" content="INR">
            <meta property="product:price:amount" content="2099">
            <meta property="product:sale_price:currency" content="INR">
            <meta property="product:sale_price:amount" content="1049">
            <meta property="product:size" content="M-38">
            <meta property="product:pretax_price:currency" content="INR">
            <meta property="product:pretax_price:amount" content="1049">
            <title>Buy Red &amp; Yellow Tipped Collar Pique Polo T-shirt Online at Muftijeans</title>
            <link rel="stylesheet" type="text/css" media="all"
                href="https://www.muftijeans.in/static/version1728012928/_cache/merged/07bf3ba70a0b5688e9a3da1ea6a28bf3.min.css">
            <link rel="stylesheet" type="text/css" media="screen and (min-width: 768px)"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/styles-l.min.css">
            <link rel="stylesheet" type="text/css" media="print"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/print.min.css">
            <link rel="stylesheet" type="text/css" media="all"
                href="https://www.muftijeans.in/static/version1728012928/_cache/merged/161b37fc16b2af05bfc55815c8ca59d2.min.css">
            <link rel="icon" type="image/x-icon"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
            <link rel="shortcut icon" type="image/x-icon"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/Magento_Theme/favicon.ico">
            <link rel="preload" as="font" crossorigin="anonymous"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/light/opensans-300.woff2">
            <link rel="preload" as="font" crossorigin="anonymous"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/regular/opensans-400.woff2">
            <link rel="preload" as="font" crossorigin="anonymous"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/semibold/opensans-600.woff2">
            <link rel="preload" as="font" crossorigin="anonymous"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/opensans/bold/opensans-700.woff2">
            <link rel="preload" as="font" crossorigin="anonymous"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/fonts/Luma-Icons.woff2">
            <link rel="canonical"
                href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html">
            <meta name="google-site-verification" content="BaylKQFecuyMjXFmIOJNtNP0nVZE7ijQhtX4uJPer6A">
            <meta name="facebook-domain-verification" content="yyehg4kpch6ge5rq5xyr7qxd52ts4y">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
            <link rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap">
            <meta name="google-site-verification" content="googlecb8d2cd7f72f5378">
            <link rel="stylesheet" type="text/css" media="all"
                href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/custom.min.css">
            <link rel="stylesheet" type="text/css" media="all"
                href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">
            <meta property="og:type" content="product">
            <meta property="og:title" content="Red &amp; Yellow Tipped Collar Pique Polo T-Shirt">
            <meta property="og:image"
                content="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/265x265/1_mfk-9307-q-03-red_6.jpg">
            <meta property="og:description" content="Red &amp; Yellow Tipped Collar Pique Polo T-Shirt In Cotton">
            <meta property="og:url"
                href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html">
            <meta property="product:price:amount" content="1049">
            <meta property="product:price:currency" content="INR">
          `;

      // Add the new content to the head
      head.innerHTML = newHeadContent;
    };

    replaceHeadTag();
  }, []);

  const { id } = useParams(); // Get product ID from the URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product data based on the id
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${url2}api/products/getproductone/${id}`);
        const data = await response.json();

        console.log(data, "mukeemm s data cmni");
        setProduct(data); // Assuming API returns product details
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <p>Loading product...</p>;
  }

  const handleAddToBag = () => {
    const productId = id; // assuming product has _id field

    // Get existing products from localStorage or set it to an empty array if it doesn't exist
    let existingProducts =
      JSON.parse(localStorage.getItem("bagProducts")) || [];

    // Add new product ID to the list
    if (!existingProducts.includes(productId)) {
      existingProducts.push(productId);
      localStorage.setItem("bagProducts", JSON.stringify(existingProducts));
      alert("Product added to the bag!");
    } else {
      alert("Product is already in the bag!");
    }
  };

  return (
    <>
      <main id="maincontent" className="page-main">
        <a id="contentarea" tabIndex={-1} />
        <div className="columns">
          <div className="column main">
            <input name="form_key" type="hidden" value="jxrdhQCrryeg5Os0" />
            <div
              id="authenticationPopup"
              data-bind="scope:'authenticationPopup', style: {display: 'none'}"
              style={{ display: "none" }}
            ></div>
            <span />
            <div className="main-product-section">
              <Productmedia />

              <div className="product-info-main">
                <div className="page-title-wrapper product">
                  <h1 className="page-title">
                    <span
                      className="base"
                      data-ui-id="page-title-wrapper"
                      itemprop="name"
                    >
                      {product.name}
                    </span>
                  </h1>
                </div>
                <div className="product-info-price">
                  <div
                    className="price-box price-final_price"
                    data-role="priceBox"
                    data-product-id="85430"
                    data-price-box="product-id-85430"
                  >
                    <span className="normal-price special-price">
                      <span className="price-container price-final_price tax weee">
                        <span
                          className="price-label"
                          style={{ display: "inline" }}
                        />
                        <span
                          id="product-price-85430"
                          data-price-amount="1049"
                          data-price-type="finalPrice"
                          className="price-wrapper "
                        >
                          <span className="price">₹{product.price}</span>
                        </span>
                      </span>
                    </span>
                    <span className="old-price sly-old-price no-display">
                      <span className="price-container price-final_price tax weee">
                        <span className="price-label">Regular Price</span>
                        <span
                          id="old-price-85430"
                          data-price-amount="2099"
                          data-price-type="oldPrice"
                          className="price-wrapper "
                        >
                          <span className="price">₹2,099</span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="promotion-label-pdp">
                  <div className="buyfree-label">-50% OFF</div>
                  <p>
                    <a
                      className="view-products"
                      href="https://www.muftijeans.in/sale-ss23.html"
                    >
                      View Products
                    </a>
                  </p>
                </div>
                <div className="price-info season_id_">
                  Inclusive of all taxes.
                </div>
                <div className="pdp-sizechart-box">
                  <a
                    id="pdp-size-chart"
                    className="pdp-sizechart"
                    href="javascript:void(0)"
                  >
                    Size Chart
                  </a>
                </div>

                <div
                  id="prdinfo"
                  className="product-add-form division-id-1761"
                  data-cat="1761"
                  data-colorg="1829"
                >
                  <form
                    id="product_addtocart_form"
                    onSubmit={(event) => event.preventDefault()}
                  >
                    <div
                      className="product-options-wrapper"
                      id="product-options-wrapper"
                      data-hasrequired="* Required Fields"
                    >
                      <div className="fieldset" tabIndex={0}>
                        <div
                          className="swatch-opt"
                          data-role="swatch-options"
                          data-rendered="true"
                        >
                          <div
                            className="swatch-attribute mufti_colourgroup"
                            data-attribute-code="mufti_colourgroup"
                            data-attribute-id="310"
                            data-option-selected="1829"
                          >
                            <span
                              id="option-label-mufti_colourgroup-310"
                              className="swatch-attribute-label"
                            >
                              Colours
                            </span>
                            <span className="swatch-attribute-selected-option">
                              Red
                            </span>
                            <div
                              aria-activedescendant="option-label-mufti_colourgroup-310-item-1829"
                              tabIndex={0}
                              aria-invalid="false"
                              aria-required="true"
                              role="listbox"
                              aria-labelledby="option-label-mufti_colourgroup-310"
                              className="swatch-attribute-options clearfix"
                            >
                              <a href="https://www.muftijeans.in/navy-white-tipped-collar-pique-polo-t-shirt-mfk-9307-q-navy.html">
                                <div
                                  className="swatch-option image"
                                  id="option-label-mufti_colourgroup-310-item-1806"
                                  index="0"
                                  aria-checked="false"
                                  aria-describedby="option-label-mufti_colourgroup-310"
                                  tabIndex={0}
                                  data-option-type="2"
                                  data-option-id="1806"
                                  data-option-label="Navy"
                                  aria-label="Navy"
                                  role="option"
                                  data-thumb-width="50"
                                  data-thumb-height="50"
                                  data-option-tooltip-thumb="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-05-navy.png"
                                  data-option-tooltip-value="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-05-navy.png"
                                  style={{
                                    background:
                                      "url(https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-05-navy.png) no-repeat center",
                                    backgroundSize: "initial",
                                    width: 50,
                                    height: 50,
                                  }}
                                  title="Navy"
                                />
                              </a>
                              <div
                                className="swatch-option image selected"
                                id="option-label-mufti_colourgroup-310-item-1829"
                                index="1"
                                aria-checked="true"
                                aria-describedby="option-label-mufti_colourgroup-310"
                                tabIndex={0}
                                data-option-type="2"
                                data-option-id="1829"
                                data-option-label="Red"
                                aria-label="Red"
                                role="option"
                                data-thumb-width="50"
                                data-thumb-height="50"
                                data-option-tooltip-thumb="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-03-red.png"
                                data-option-tooltip-value="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-03-red.png"
                                style={{
                                  background:
                                    "url(https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/0/3/fit-in/50x50/03-06-24_sw_mfk-9307-q-03-red.png) no-repeat center",
                                  backgroundSize: "initial",
                                  width: 50,
                                  height: 50,
                                }}
                                title="Red"
                              />
                            </div>
                          </div>
                          <div
                            className="swatch-attribute size"
                            data-attribute-code="size"
                            data-attribute-id="223"
                          >
                            <span
                              id="option-label-size-223"
                              className="swatch-attribute-label"
                            >
                              Size
                            </span>
                            <span className="swatch-attribute-selected-option" />
                            <div
                              aria-activedescendant=""
                              tabIndex={0}
                              aria-invalid="false"
                              aria-required="true"
                              role="listbox"
                              aria-labelledby="option-label-size-223"
                              className="swatch-attribute-options clearfix"
                            >
                              {product.sizes.map((sizeOption, index) => (
                                <div className="swatch-detail" key={index}>
                                  <div
                                    className={`swatch-option text ${
                                      selectedSize === sizeOption
                                        ? "selected"
                                        : ""
                                    }`}
                                    index={index}
                                    tabIndex={0}
                                    data-option-label={sizeOption} // You can add a custom data attribute if needed
                                    aria-label={sizeOption}
                                    onClick={() => handleSizeClick(sizeOption)} // Accessibility improvement
                                  >
                                    {sizeOption} {/* Display the size value */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-options-bottom">
                      <div id="pinbox">
                        <fieldset className="fieldset ram15072023">
                          <div className="delivery-area">
                            <div className="legend">
                              <span>Check Estimated Delivery Date</span>
                            </div>
                            <div className="field required">
                              <div className="delivery-box">
                                <input
                                  name="postcode"
                                  type="number"
                                  pattern="[0-9]*"
                                  inputMode="numeric"
                                  maxLength={6}
                                  placeholder="Enter Pincode"
                                  value={inputPincode} // Controlled input
                                  onChange={handleInputChange} // Handle input change
                                />
                                <a
                                  className="action-check-delivery"
                                  href="javascript:void(0);"
                                  title="Check"
                                  onClick={checkPincode} // Check pincode on click
                                >
                                  <span>Check</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div id="pinexist" style={{}}>
                        <div
                          className="check-availability"
                          id="closepincodeoption"
                          style={{ display: isPincodeValid }}
                        >
                          <span className="check-availability-label">
                            <ul className="pincode-serviceability-list">
                              <li className="pincode-serviceabilityItem">
                                We’re sorry we don’t deliver to your pincode.{" "}
                                <br />
                                Would you like to{" "}
                                <a
                                  href="/storelocator"
                                  style={{ textDecoration: "underline" }}
                                >
                                  find a store
                                </a>{" "}
                                near you?
                              </li>
                            </ul>
                          </span>{" "}
                          <a className="cello-sprite" />{" "}
                          <div
                            className="col-lg-8 check-availability-text-box-container"
                            style={{ display: "none" }}
                          >
                            <a href="javascript:void(0)">Change</a>
                          </div>
                        </div>
                      </div>

                      <div id="pinexist" style={{}}>
                        <div
                          className="check-availability"
                          id="closepincodeoption"
                          style={{ display: inputPincodea }}
                        >
                          <span className="check-availability-label">
                            <ul className="pincode-serviceability-list">
                              <li className="pincode-serviceabilityItem">
                                <span className="icon-date" />
                                <h4 className="pincode-serviceabilityTitle">
                                  Wear Mufti On or Before Sat Oct 19
                                </h4>
                              </li>
                              <li className="pincode-serviceabilityItem mufti-no-display">
                                <span className="icon-pay" />
                                <h4 className="pincode-serviceabilityTitle">
                                  Pay on delivery available
                                </h4>
                              </li>
                              <li className="pincode-serviceabilityItem mufti-no-display">
                                <span className="exch-date" />
                                <h4 className="pincode-serviceabilityTitle">
                                  Easy 30 days returns and exchanges
                                </h4>
                              </li>
                            </ul>
                          </span>{" "}
                          <a className="cello-sprite" />{" "}
                        </div>
                      </div>

                      <div className="box-tocart">
                        <div className="fieldset">
                          <div className="field qty">
                            <div
                              id="custom-qty"
                              className="control"
                              data-bind="scope: 'qty-counter'"
                            >
                              <div className="input-group">
                                <span className="input-group__addon">
                                  <button
                                    className="input-group__button input-group__button--decrease"
                                    data-bind="click: decreaseQty"
                                    fdprocessedid="ruzcp"
                                  >
                                    <span
                                      className="input-group__icon input-group__icon--decrease"
                                      onClick={() => {
                                        if (Quantity > 1) {
                                          setQuantity(Quantity - 1);
                                        }
                                      }}
                                    >
                                      -
                                    </span>
                                  </button>
                                </span>
                                <input
                                  data-bind="value: qty(), attr: {'data-validate': getDataValidator(), 'title': $t('Qty')}"
                                  type="number"
                                  name="qty"
                                  id="qty"
                                  maxLength={12}
                                  className="input-group__input"
                                  value={Quantity}
                                />
                                <span className="input-group__addon">
                                  <button
                                    className="input-group__button input-group__button--increase"
                                    data-bind="click: increaseQty"
                                    fdprocessedid="szy32"
                                  >
                                    <span
                                      className="input-group__icon input-group__icon--increase"
                                      onClick={() => {
                                        setQuantity(Quantity + 1);
                                      }}
                                    >
                                      +
                                    </span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="actions">
                            <button
                              onClick={handleAddToBag}
                              type="button"
                              title="ADD TO BAG"
                              className="action primary tocart"
                              id="product-addtocart-button"
                              fdprocessedid="9ihnsk"
                            >
                              <span>ADD TO BAG</span>
                            </button>

                            <Link
                              to={`/checkout/${product._id}?quantity=${Quantity}&size=${selectedSize}&price=${product.price}`}
                            >
                              <button
                                type="submit"
                                title="BUY NOW"
                                id="buy-now"
                                className="action primary buy-now-btn ajax-register-link desk-buy-now"
                              >
                                <span className="pdp_buynow">BUY NOW</span>
                              </button>
                            </Link>

                            <div
                              id="instant-purchase"
                              data-bind="scope:'instant-purchase'"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product info detailed information-para">
                      <div
                        className="description-tab mage-accordion-disabled"
                        id="description-tab"
                        role="tablist"
                      >
                        <div
                          data-role="collapsible"
                          className="more-detail-collapsible active"
                          role="tab"
                          data-collapsible="true"
                          aria-selected="false"
                          aria-expanded="true"
                        >
                          <div data-role="trigger" tabIndex={0}>
                            <h3>Description</h3>
                          </div>
                          <div
                            data-role="content"
                            role="tabpanel"
                            aria-hidden="false"
                          >
                            <div className="product attribute description">
                              <div className="value">
                                <div
                                  data-content-type="html"
                                  data-appearance="default"
                                  data-element="main"
                                  data-decoded="true"
                                >
                                  Red & Yellow Tipped Collar Pique Polo T-Shirt
                                  In Cotton
                                </div>
                              </div>
                            </div>
                            <div className="additional-attributes-wrapper">
                              <ul
                                className="additional-attributes-list"
                                id="product-attribute-specs-list"
                              >
                                <li className="list-item">
                                  <span className="data" data-label="Colours">
                                    Red
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span className="data" data-label="Fit">
                                    Slim Fit
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span className="data" data-label="Sleeve">
                                    Half Sleeve
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span className="data" data-label="Pattern">
                                    Plain
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span className="data" data-label="Febric">
                                    Cotton
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span
                                    className="data"
                                    data-label="Fabric Blend"
                                  >
                                    100% Cotton
                                  </span>
                                </li>
                                <li className="list-item">
                                  <span className="data" data-label="Collar">
                                    Polo
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="custom-ajax-data">
              <Productreleted />
            </div>
          </div>
        </div>
      </main>

      <style>{`
                                .pdp-sizechart-box .buyfree-label {
                                    background: #414042;
                                    position: relative;
                                }

                                .pdp-sizechart-box .buyfree-label {
                                    clear: both;
                                    display: table-cell;
                                }

                                .pdp-sizechart-box .buyfree-label {
                                    font-size: 16px;
                                    display: inline-block !important;
                                    background: #414042;
                                    color: #fff;
                                    padding: 10px 10px;
                                    margin: 0 10px;
                                    line-height: 8px;
                                    vertical-align: top;
                                }

                                .pdp-sizechart-box .buyfree-label {
                                    margin: 8px 0px;
                                }

                                .pdp-sizechart-box p a {
                                    font-size: 15px;
                                    /* font-weight: 700; */
                                    text-decoration: underline;
                                    color: #007bdb;
                                }

                                .pdp-sizechart-box .fa.fa-angle-right {
                                    /* font-weight: 700; */
                                    color: #007bdb;
                                    margin-right: 2px;
                                }

                                .pdp-sizechart-box p {
                                    display: inline-block;
                                    padding-top: 10px;
                                    margin-left: 2px;
                                }
                            `}</style>

      <style>{`
                                            .bottomMenu {
                                                position: fixed;
                                                bottom: 20px;
                                                color: white;
                                                width: 100%;
                                                height: auto;
                                                /* border-top: 1px solid #ccc; */
                                                background: #fff;
                                                z-index: 99;
                                                transition: all .5s;
                                            }

                                            .hide {
                                                opacity: 0;
                                            }

                                            .show {
                                                opacity: 1;
                                            }
                                        `}</style>
    </>
  );
}

export default Productone;

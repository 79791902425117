import React from 'react'

function Productmedia() {
  return (
   <>
   
   
   
   
 <div className="product media">
                {" "}
                <a id="gallery-prev-area" tabIndex={-1} />
                <div className="action-skip-wrapper">
                  {" "}
                  <a
                    className="action skip gallery-next-area"
                    href="#gallery-next-area"
                  >
                    <span> Skip to the end of the images gallery</span>
                  </a>
                </div>
                <div className="action-skip-wrapper">
                  {" "}
                  <a
                    className="action skip gallery-prev-area"
                    href="#gallery-prev-area"
                  >
                    <span> Skip to the beginning of the images gallery</span>
                  </a>
                </div>{" "}
                <a id="gallery-next-area" tabIndex={-1} />
                <style>{`
                                .main-image-slider.slick-slider {
                                    padding: 0px !important;
                                }

                                @media only screen and (max-width: 767px) {
                                    .placeholder__image-1 {
                                        display: none;
                                    }
                                }
                            `}</style>
                <link
                  rel="preload"
                  fetchpriority="high"
                  as="image"
                  href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-9307-q-03-red_6.jpg"
                  type="image/webp"
                />
                <link
                  rel="preload"
                  fetchpriority="high"
                  as="image"
                  href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_mfk-9307-q-03-red_6.jpg"
                  type="image/webp"
                />
                <div className="product-image-carousels">
                  <div
                    className="gallery-placeholder__image"
                    style={{ display: "none" }}
                  >
                    {" "}
                    <img
                      fetchpriority="high"
                      className="placeholder__image-0"
                      alt="Red & Yellow Tipped Collar Pique Polo T-Shirt"
                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-9307-q-03-red_6.jpg"
                      width="1000"
                      height="1333"
                      style={{ width: 489 }}
                    />{" "}
                    <img
                      fetchpriority="high"
                      className="placeholder__image-1"
                      alt="Red & Yellow Tipped Collar Pique Polo T-Shirt"
                      src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_mfk-9307-q-03-red_6.jpg"
                      width="1000"
                      height="1333"
                      style={{ width: 489 }}
                    />
                  </div>
                  <div
                    className="main-image-slider mz-gallery-placeholder gallery-placeholder mz-main-slider slick-initialized slick-slider"
                    data-gallery-role="gallery-placeholder"
                  >
                    <button
                      className="slick-prev slick-arrow"
                      aria-label="Previous"
                      type="button"
                      fdprocessedid="ubqu7"
                    >
                      Previous
                    </button>
                    <div className="slick-list draggable">
                      <div
                        className="slick-track"
                        style={{
                          opacity: "1",
                          width: 8964,
                          transform: "translate3d(-996px, 0px, 0px)",
                        }}
                      >
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="6"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/7_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="-2"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/1000x1333/7_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="7"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/8_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="-1"
                          id=""
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/1000x1333/8_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-current slick-active"
                          data-data-index="0"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/1_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="0"
                          aria-hidden="false"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-active"
                          data-data-index="1"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/2_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="1"
                          aria-hidden="false"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="2"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/3_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="2"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/1000x1333/3_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="3"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/4_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="3"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/1000x1333/4_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="4"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/5_mfk-9307-q-03-red_4.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="4"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/fit-in/1000x1333/5_mfk-9307-q-03-red_4.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="5"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/6_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="5"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/fit-in/1000x1333/6_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="6"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/7_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="6"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/1000x1333/7_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide"
                          data-data-index="7"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/8_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="7"
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/1000x1333/8_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="0"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/1_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="8"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/1000x1333/1_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="1"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/2_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="9"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/1000x1333/2_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="2"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/3_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="10"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/1000x1333/3_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="3"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/4_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="11"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/1000x1333/4_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="4"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/5_mfk-9307-q-03-red_4.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="12"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/fit-in/1000x1333/5_mfk-9307-q-03-red_4.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="5"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/6_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="13"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/fit-in/1000x1333/6_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="6"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/7_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="14"
                          aria-hidden="true"
                          style={{ width: 488 }}
                          id=""
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/1000x1333/7_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                        <a
                          className="main-image slick-slide slick-cloned"
                          data-data-index="7"
                          href="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/8_mfk-9307-q-03-red_5.jpg"
                          data-pswp-width="1500"
                          data-pswp-height="2000"
                          data-slick-index="15"
                          id=""
                          aria-hidden="true"
                          style={{ width: 488 }}
                        >
                          <img
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/1000x1333/8_mfk-9307-q-03-red_5.jpg"
                            alt="null"
                            width="1000"
                            height="1333"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <button
                      className="slick-next slick-arrow"
                      aria-label="Next"
                      type="button"
                      fdprocessedid="8t3mye"
                    >
                      Next
                    </button>
                  </div>
                  <div className="mz-gallery-thumbs-wrap">
                    <div className="thumbnails-slider slick-initialized slick-slider">
                      <button
                        className="slick-prev slick-arrow"
                        aria-label="Previous"
                        type="button"
                        fdprocessedid="id9w6"
                      >
                        Previous
                      </button>
                      <div className="slick-list draggable">
                        <div
                          className="slick-track"
                          style={{
                            opacity: "1",
                            width: 3432,
                            transform: "translate3d(-936px, 0px, 0px)",
                          }}
                        >
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-6"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/562x750/3_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-5"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/562x750/4_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-4"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/fit-in/562x750/5_mfk-9307-q-03-red_4.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-3"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/fit-in/562x750/6_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-2"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/562x750/7_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="-1"
                            id=""
                            aria-hidden="true"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/562x750/8_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-current slick-active"
                            data-slick-index="0"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/562x750/1_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-active"
                            data-slick-index="1"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/562x750/2_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-active"
                            data-slick-index="2"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/562x750/3_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-active"
                            data-slick-index="3"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/562x750/4_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-active"
                            data-slick-index="4"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/fit-in/562x750/5_mfk-9307-q-03-red_4.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-active"
                            data-slick-index="5"
                            aria-hidden="false"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/fit-in/562x750/6_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide"
                            data-slick-index="6"
                            aria-hidden="true"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/562x750/7_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide"
                            data-slick-index="7"
                            aria-hidden="true"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/562x750/8_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="8"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/562x750/1_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="9"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/2/_/fit-in/562x750/2_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="10"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/3/_/fit-in/562x750/3_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="11"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/4/_/fit-in/562x750/4_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="12"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/5/_/fit-in/562x750/5_mfk-9307-q-03-red_4.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="13"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/6/_/fit-in/562x750/6_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="14"
                            aria-hidden="true"
                            style={{ width: 146 }}
                            id=""
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/7/_/fit-in/562x750/7_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="thumbnail-button slick-slide slick-cloned"
                            data-slick-index="15"
                            id=""
                            aria-hidden="true"
                            style={{ width: 146 }}
                          >
                            <img
                              src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/8/_/fit-in/562x750/8_mfk-9307-q-03-red_5.jpg"
                              width="562"
                              height="750"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="slick-next slick-arrow"
                        aria-label="Next"
                        type="button"
                        fdprocessedid="1ii5lci"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>  
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   </>
  )
}

export default Productmedia

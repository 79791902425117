import React from "react";

function Mybagsidebar() {
  return (
    <>
      <div
        className="block block-minicart block-quickcart empty ui-dialog-content ui-widget-content"
        data-role="dropdownDialog"
        id="ui-id-1"
        style={{ display: "" }}
      >
        <div
          id="minicart-content-wrapper"
          className="quickcart-content-wrapper"
          data-bind="scope: 'minicart_content'"
        >
          {/* ko template: getTemplate() */}
          <div className="block-title">
            <strong>
              <span className="text">
                {/* ko i18n: 'My Bag' */}
                <span>My Bag </span>
                {/* /ko */}
              </span>
              <span
                className="qty"
                data-bind="css: { empty: !!getCartParam('summary_count') == false },\n                             attr: { title: $t('Items in Bag') }"
                title="Items in Bag"
              >
                {/* ko text: getCartParam('summary_count') */}1{/* /ko */}
              </span>
            </strong>
          </div>

          <div className="block-content block-content-quickcart">
            <div className="quickcart-top">
              <span className="text">
                {/* ko i18n: 'My Bag' */}
                <span>My Bag</span>
                {/* /ko */}
              </span>

              <button
                type="button"
                id="btn-minicart-close"
                className="action close"
                data-action="close"
                data-bind="\n                        attr: {\n                            title: $t('Close')\n                        },\n                        click: closeMinicart()\n                    "
                title="Close"
              >
                <span>
                  {/* ko i18n: 'Close' */}
                  <span>Close</span>
                  {/* /ko */}
                </span>
              </button>
            </div>

            <strong className="subtitle">
              {/* ko i18n: 'Recently added item(s)' */}
              <span>Recently added item(s)</span>
              {/* /ko */}
            </strong>
            <div
              data-action="scroll"
              className="minicart-items-wrapper quickcart-items-wrapper overflowed"
              style={{ height: "125.575px" }}
            >
              <ol
                id="mini-cart"
                className="minicart-items quickcart-items"
                data-bind="foreach: { data: getCartItems(), as: 'item' }"
              >
                {/* ko foreach: $parent.getRegion($parent.getItemRenderer(item.product_type)) */}
                {/* ko template: {name: getTemplate(), data: item, afterRender: function() {$parents[1].initSidebar()}} */}
                <li
                  className="item product product-item quickcart-product-item odd last"
                  data-role="product-item"
                  data-collapsible="true"
                >
                  <div className="product">
                    {/* ko if: product_has_url */}
                    <a
                      data-bind="attr: {href: product_url, title: product_name}"
                      tabIndex={-1}
                      className="product-item-photo"
                      href="https://www.muftijeans.in/jet-black-skinny-fit-denim-deluxe-stretch-jeans-mft-29407-black.html"
                      title="Jet Black Skinny Fit Denim Deluxe Stretch Jeans"
                    >
                      {/* ko foreach: $parent.getRegion('itemImage') */}
                      {/* ko template: {name: getTemplate(), data: item.product_image} */}
                      <span
                        className="product-image-container"
                        data-bind="style: {width: width/2 + 'px'}"
                        style={{ width: 150 }}
                      >
                        <span
                          className="product-image-wrapper"
                          data-bind="style: {'padding-bottom': height/width*100 + '%'}"
                          style={{ paddingBottom: "150%" }}
                        >
                          <img
                            className="product-image-photo"
                            data-bind="attr: {src: src, alt: alt}, style: {width: 'auto', height: 'auto'}"
                            src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/300x450/1_mft-29407-96-jet-black.jpg"
                            alt="Jet Black Skinny Fit Denim Deluxe Stretch Jeans"
                            style={{ width: "auto", height: "auto" }}
                          />
                        </span>
                      </span>
                      {/* /ko */}
                      {/* /ko */}
                    </a>
                    {/* /ko */}
                    {/* ko ifnot: product_has_url */}
                    {/* /ko */}

                    <div className="product-item-details">
                      <strong className="product-item-name">
                        {/* ko if: product_has_url */}
                        <a
                          data-bind="attr: {href: product_url}, html: product_name"
                          href="https://www.muftijeans.in/jet-black-skinny-fit-denim-deluxe-stretch-jeans-mft-29407-black.html"
                        >
                          Jet Black Skinny Fit Denim Deluxe Stretch Jeans
                        </a>
                        {/* /ko */}
                        {/* ko ifnot: product_has_url */}
                        {/* /ko */}
                      </strong>

                      <div className="product actions">
                        <div className="secondary">
                          <a
                            href="#"
                            data-bind="attr: {'data-cart-item': item_id, title: $t('Remove item')}"
                            className="action delete"
                            data-cart-item="2749977"
                            title="Remove item"
                          >
                            <span className="icon-line-cross">
                              <i className="fa fa-trash" aria-hidden="true" />
                            </span>
                          </a>
                        </div>
                      </div>

                      <div
                        className="product options"
                        role="tablist"
                        data-collapsible="true"
                      >
                        {/* ko if: options.length */}

                        <div
                          className="content"
                          data-role="title"
                          role="tab"
                          aria-selected="false"
                          aria-expanded="false"
                          tabIndex={0}
                        >
                          <dl className="product options list">
                            {/* ko foreach: { data: options, as: 'option' } */}
                            <div className="option-wrapper">
                              <dt className="label">
                                {/* ko text: option.label */}Size{/* /ko */}
                              </dt>
                              <dd className="values">
                                {/* ko if: Array.isArray(option.value) */}
                                {/* /ko */}
                                {/* ko ifnot: Array.isArray(option.value) */}
                                <span data-bind="html: option.value">28</span>
                                {/* /ko */}
                              </dd>
                            </div>

                            <div className="option-wrapper">
                              <dt className="label">
                                {/* ko text: option.label */}Colours{/* /ko */}
                              </dt>
                              <dd className="values">
                                {/* ko if: Array.isArray(option.value) */}
                                {/* /ko */}
                                {/* ko ifnot: Array.isArray(option.value) */}
                                <span data-bind="html: option.value">
                                  Jet Black
                                </span>
                                {/* /ko */}
                              </dd>
                            </div>
                            {/* /ko */}
                          </dl>
                        </div>
                        {/* /ko */}
                      </div>

                      <div className="product-item-pricing">
                        {/* ko if: canApplyMsrp */}
                        {/* /ko */}
                        {/* ko ifnot: canApplyMsrp */}
                        {/* ko foreach: $parent.getRegion('priceSidebar') */}
                        {/* ko template: {name: getTemplate(), data: item.product_price, as: 'price'} */}
                        <div className="price-container">
                          <span
                            className="price-wrapper"
                            data-bind="html: price"
                          >
                            <span
                              className="price-including-tax"
                              data-label="Incl. Tax"
                            >
                              <span className="minicart-price ram">
                                <span className="price">₹4,099</span>
                              </span>
                            </span>{" "}
                          </span>
                        </div>
                        {/* /ko */}

                        {/* ko template: {name: getTemplate(), data: item.product_price, as: 'price'} */}
                        {/* ko if: item.mp_brand */}
                        <div
                          className="mp-brand-item"
                          data-bind="style: {'margin-top': '10px'}"
                          style={{ marginTop: 10 }}
                        >
                          {/* ko if: item.brand_logo */}
                          {/* /ko */}
                          {/* ko if: item.brand_name */}
                          {/* /ko */}
                          {/* ko if: item.show_desc */}
                          {/* /ko */}
                        </div>
                        {/* /ko */}
                        {/* /ko */}
                        {/* /ko */}
                        {/* /ko */}
                        <div className="details-qty qty">
                          <label
                            className="label"
                            data-bind="i18n: 'Qty', attr: {\n                        for: 'cart-item-'+item_id+'-qty'}"
                            htmlFor="cart-item-2749977-qty"
                          >
                            Quantity
                          </label>
                          <span className="item-minus qty-update" />
                          <input
                            data-bind="attr: {\n                           id: 'cart-item-'+item_id+'-qty',\n                           'data-cart-item': item_id,\n                           'data-item-qty': qty,\n                           'data-cart-item-id': product_sku\n                           }, value: qty"
                            type="number"
                            size={4}
                            className="item-qty cart-item-qty"
                            maxLength={12}
                            disabled={true}
                            id="cart-item-2749977-qty"
                            data-cart-item="2749977"
                            data-item-qty="1"
                            data-cart-item-id="8905310443276"
                          />
                          <div className="spinner" />
                          <button
                            data-bind="attr: {\n                               id: 'update-cart-item-'+item_id,\n                               'data-cart-item': item_id,\n                               title: $t('Update')\n                               }"
                            className="update-cart-item"
                            style={{ display: "none" }}
                            id="update-cart-item-2749977"
                            data-cart-item="2749977"
                            title="Update"
                          >
                            <span data-bind="i18n: 'Update'">Update</span>
                          </button>
                          <span className="item-plus qty-update" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ko if: $data.message */}
                  {/* /ko */}
                </li>
                {/* /ko */}
                {/* /ko */}
              </ol>
            </div>

            {/* ko ifnot: getCartParam('summary_count') */}
            {/* /ko */}

            {/* ko if: getCartParam('summary_count') */}

            <div className="quickcart-bottom-container">
              {/* ko if: getCartParam('weltpixel_quickcart_cmsblock') */}
              {/* /ko */}
              {/* ko if: getCartParam('weltpixel_quickcart_carousel_enabled') */}
              {/* /ko */}
              {/* ko if: getCartParam('weltpixel_quickcart_message_enabled') */}
              {/* /ko */}
              <div className="items-total">
                <span className="count">
                  {/* ko text: getCartParam('summary_count') */}1{/* /ko */}
                </span>
                {/* ko if: getCartParam('summary_count') == 1 */}
                {/* ko i18n: 'item' */}
                <span>item</span>
                {/* /ko */}
                {/* /ko */}
                {/* ko if: getCartParam('summary_count') > 1 */}
                {/* /ko */}
              </div>

              {/* ko if: getCartParam('weltpixel_quickcart_coupon_enabled') */}
              {/* ko foreach: getRegion('couponlink') */}
              {/* /ko */}
              {/* /ko */}
              {/* ko if: getCartParam('weltpixel_quickcart_coupon_enabled') */}
              {/* ko foreach: getRegion('couponlinkDefalt') */}
              {/* ko template: getTemplate() */}
              {/* ko ifnot: cart().weltpixel_quickcart_has_coupon_code */}
              <a
                className="coupon-code-main coupon-click-btn"
                data-trigger="open-coupon-list-minicart"
              >
                <p className="coupon-code-title">
                  APPLY COUPON
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </p>
              </a>

              {/* /ko */}
              {/* ko if: cart().weltpixel_quickcart_has_coupon_code */}
              {/* /ko */}

              {/* /ko */}
              {/* /ko */}
              {/* /ko */}

              {/* ko if: getCartParam('possible_onepage_checkout') */}
              {/* ko foreach: getRegion('subtotalContainer') */}
              {/* ko template: getTemplate() */}
              {/* ko foreach: {data: elems, as: 'element'} */}
              {/* ko if: hasTemplate() */}
              {/* ko template: getTemplate() */}
              {/* ko if: (cart().show_savings_enabled && cart().total_saved_amount_no_html > 0 ) */}
              {/* /ko */}

              {/* /ko */}
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}

              {/* ko template: getTemplate() */}
              {/* ko foreach: {data: elems, as: 'element'} */}
              {/* ko if: hasTemplate() */}
              {/* ko template: getTemplate() */}
              <div className="subtotal">
                <span className="label">
                  {/* ko i18n: 'Total:' */}
                  <span>Total:</span>
                  {/* /ko */}
                </span>

                {/* ko foreach: elems */}
                {/* ko template: getTemplate() */}

                <div className="amount price-container">
                  {/* ko if: displaySubtotal() */}
                  {/* ko if: display_cart_subtotal_excl_tax */}
                  {/* /ko */}

                  {/* ko if: !display_cart_subtotal_excl_tax && display_cart_subtotal_incl_tax */}
                  <span
                    className="price-wrapper"
                    data-bind="html: cart().subtotal_incl_tax"
                  >
                    <span className="price">₹4,099</span>
                  </span>
                  {/* /ko */}

                  {/* ko if: !display_cart_subtotal_excl_tax && !display_cart_subtotal_incl_tax */}
                  {/* /ko */}
                  {/* /ko */}
                  {/* ko ifnot: displaySubtotal() */}
                  {/* /ko */}
                </div>
                {/* /ko */}
                {/* /ko */}
              </div>
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}

              {/* ko foreach: getRegion('extraInfo') */}
              {/* ko template: getTemplate() */}
              {/* ko foreach: {data: elems, as: 'element'} */}
              {/* /ko */}
              {/* /ko */}
              {/* /ko */}

              {/* ko if: getCartParam('weltpixel_quickcart_discount_amount') */}
              {/* /ko */}

              {/* ko if: getCartParam('summary_count') */}
              <div className="actions action-viewcart bottom-aligned">
                <div className="secondary">
                  <a
                    className="action viewcart"
                    data-bind="attr: {href: shoppingCartUrl}"
                    href="https://www.muftijeans.in/checkout/cart/"
                  >
                    <span>
                      {/* ko i18n: 'Go To Bag' */}
                      <span>Go To Bag</span>
                      {/* /ko */}
                    </span>
                  </a>
                </div>
              </div>
              {/* /ko */}

              {/* ko if: getCartParam('possible_onepage_checkout') */}
              <div className="actions action-checkout">
                <div className="secondary">
                  <a
                    className="action viewcart"
                    id="top-cart-btn-checkout"
                    data-bind="attr: {title: $t('Checkout')}"
                    title="Checkout"
                  >
                    <span>
                      {/* ko i18n: 'Checkout' */}
                      <span>Checkout</span>
                      {/* /ko */}
                    </span>
                  </a>
                  <div
                    className="extra-actions"
                    data-bind="html: getCartParam('extra_actions')"
                  ></div>
                </div>
              </div>
              {/* /ko */}

              <div id="minicart-widgets" className="minicart-widgets">
                {/* ko foreach: getRegion('promotion') */}
                {/* ko template: getTemplate() */}
                {/* ko foreach: {data: elems, as: 'element'} */}
                {/* /ko */}
                {/* /ko */}
                {/* /ko */}
              </div>
            </div>
            {/* /ko */}
          </div>
          {/* ko foreach: getRegion('sign-in-popup') */}
          {/* /ko */}

          {/* /ko */}
        </div>
      </div>
      <div
        className="modals-overlay-minicart"
        style={{ zIndex: "901", overflow: "hidden" }}
      />
    </>
  );
}

export default Mybagsidebar;

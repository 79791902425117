import React, { createContext, useEffect, useState } from "react";

// Create the context
export const MyContext = createContext();

// Context provider component
const MyContextProvider = (props) => {
  // Set initial state
  const [MainSidebar, setMainSidebar] = useState(false);
  const [MainSignpage, setMainSignpage] = useState(false);
  const [Mybagsidebar, setMybagsidebar] = useState(false);
  const [userdetails, setUserDetails] = useState();
  const [Signupformpop, setSignupformpop] = useState(false);
  const [Loginformpop,setLoginformpop ] = useState(false);
  const [forgetpasspop,setforgetpasspop ] = useState(false);
  const [navshow,setnavshow]=useState("");
  const [addressid,setaddressid]=useState("");
  const [url2,seturl2 ] = useState("https://dhancash.uw.r.appspot.com/");






 // Initialize as null
  const [token, settoken] = useState(null);

  const url = "http://localhost:5000";

  useEffect(() => {
    GetUser(); // Fetch user details on component mount
  }, []); // Empty dependency array ensures this runs once

  const GetUser = async () => {
    try {
      const tokens = localStorage.getItem("token");
      settoken(tokens); // Set the token in state

      console.log(tokens,"this is my tken")

      if (tokens) {
        const response = await fetch(`${url}/api/user/Getuser`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auto-token": tokens,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const json = await response.json();
        setUserDetails(json); // Set user details in state
        console.log("Fetched user details:", json); // Log fetched user details
      } else {
        console.log("No token found, user details will not be fetched.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };



  const replaceHeadContent = () => {

    const removeAndAddClass = () => {
      const body = document.body;
      body.className = "";
      body.classList.add(
        "cms-home", "cms-index-index", "page-layout-1column"
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();

    const head = document.head;
    
    // Clear current <head> content
    while (head.firstChild) {
      head.removeChild(head.firstChild);
    }

    // Insert new head content
    head.innerHTML = `
      <meta http-equiv="origin-trial"
        content="A/kargTFyk8MR5ueravczef/wIlTkbVk1qXQesp39nV+xNECPdLBVeYffxrM8TmZT6RArWGQVCJ0LRivD7glcAUAAACQeyJvcmlnaW4iOiJodHRwczovL2dvb2dsZS5jb206NDQzIiwiZmVhdHVyZSI6IkRpc2FibGVUaGlyZFBhcnR5U3RvcmFnZVBhcnRpdGlvbmluZzIiLCJleHBpcnkiOjE3NDIzNDIzOTksImlzU3ViZG9tYWluIjp0cnVlLCJpc1RoaXJkUGFydHkiOnRydWV9">
      <meta charset="utf-8">
      <meta name="title" content="Mufti Menswear – Online Store for Men's Fashion Clothing">
      <meta name="description" content="Shop for the latest collection of Mufti Menswear at our online clothing store.">
      <meta name="keywords" content="Men's clothing, Men's fashion, online store, casual wear, formal wear">
      <meta name="robots" content="INDEX, FOLLOW, ARCHIVE">
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
      <meta name="twitter:card" content="summary_large_image">
      <meta name="twitter:site" content="@muftijeans">
      <meta name="twitter:title" content="Mufti Menswear – Online Store for Men's Fashion Clothing">
      <meta name="twitter:description" content="Shop for the latest collection of Mufti Menswear">
      <meta name="twitter:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
      <meta name="twitter:url" content="https://www.muftijeans.in/">
      <meta property="og:title" content="Mufti Menswear – Online Store for Men's Fashion Clothing">
      <meta property="og:description" content="Browse the latest collection of stylish Men’s clothing.">
      <meta property="og:image" content="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/images/logo.svg">
      <meta property="og:url" content="https://www.muftijeans.in/">
      <meta property="og:type" content="website">
      <title>Mufti Menswear – Online Store for Men's Fashion Clothing</title>
      <link rel="icon" type="image/x-icon" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/favicon.ico">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css">
      <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/frontend/Muftijeans/theme/en_US/css/custom.min.css" >
      <link rel="stylesheet" type="text/css" media="all" href="https://www.muftijeans.in/static/version1728012928/_cache/merged/190bcc8d56f3341e8ff445e106d7dcfc.min.css" >
    `;
  };












const delhiPincodes = [
  110001, // Connaught Place
  110002, // Daryaganj
  110003, // Defence Colony
  110004, // Patel Nagar
  110005, // Karol Bagh
  110006, // Chandni Chowk
  110007, // Kamla Nagar
  110008, // Rajinder Nagar
  110009, // Model Town
  110010, // Delhi Cantonment
  110011, // Gole Market
  110012, // Inderpuri
  110013, // Jangpura
  110014, // Lajpat Nagar
  110015, // Punjabi Bagh
  110016, // Hauz Khas
  110017, // Malviya Nagar
  110018, // Janakpuri
  110019, // Kalkaji
  110020, // Okhla Industrial Area
  110021, // Chanakyapuri
  110022, // Vasant Vihar
  110023, // R.K. Puram
  110024, // Lajpat Nagar
  110025, // Jamia Nagar
  110026, // Paschim Vihar
  110027, // Rajouri Garden
  110028, // Naraina
  110029, // Safdarjung Enclave
  110030, // Mehrauli
  110031, // Krishna Nagar
  110032, // Shahdara
  110033, // Shakti Nagar
  110034, // Pitampura
  110035, // Ashok Vihar
  110036, // Alipur
  110037, // Mahipalpur
  110038, // Palam
  110039, // Narela
  110040, // Bawana
  110041, // Mundka
  110042, // Burari
  110043, // Najafgarh
  110044, // Badarpur
  110045, // Dabri
  110046, // Sagarpur
  110047, // Arjan Garh
  110048, // Greater Kailash
  110049, // South Extension
  110051, // Preet Vihar
  110052, // Ashok Vihar
  110053, // Yamuna Vihar
  110054, // Civil Lines
  110055, // Paharganj
  110056, // Shastri Nagar
  110057, // Vasant Kunj
  110058, // Janakpuri
  110059, // Uttam Nagar
  110060, // Rajender Nagar
  110061, // Chhatarpur
  110062, // Sangam Vihar
  110063, // Vikaspuri
  110064, // Hari Nagar
  110065, // East Of Kailash
  110066, // R.K. Puram
  110067, // Munirka
  110068, // Saket
  110069, // Malviya Nagar
  110070, // Vasant Kunj
  110071, // Dwarka
  110072, // Najafgarh
  110073, // Kapashera
  110074, // Chattarpur
  110075, // Dwarka
  110076, // Sarita Vihar
  110077, // Dwarka
  110078, // Dwarka Sector 6
];





  return (
    <MyContext.Provider
      value={{
        delhiPincodes,
        MainSidebar,
        setMainSidebar,
        MainSignpage,
        setMainSignpage,
        Mybagsidebar,
        setMybagsidebar,
        userdetails, // Include user details in context value
        token, replaceHeadContent , // Include token in context value
        Signupformpop,
        setSignupformpop,
        Loginformpop,
        setLoginformpop,
        GetUser,
        forgetpasspop,setforgetpasspop,
        url2,
        navshow,setnavshow,
        addressid,setaddressid
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export default MyContextProvider;

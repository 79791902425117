import React, { useContext, useEffect, useState } from 'react'
import BagProducts from './BagProducts';
import { MyContext } from '../Context/Mycontext';

function Mybags() {



  const context = useContext(MyContext);

  const {url2}=context;

    const [Mybagshow, setMybagshow] = useState("none");



    const Mybagshowtrue = () => {
        setMybagshow("block");
      };
    
      const Mybagshowclose = () => {
        setMybagshow("none");
        console.log("mukeem is good ");
      };




      const [products, setProducts] = useState([]);

  // URL to your backend API (assuming you have an API endpoint to fetch products by IDs)
  const url = "http://localhost:5000/api/products/getproductsbyids";

  // Fetch products by IDs stored in localStorage
  useEffect(() => {
    // Get product IDs from localStorage
    const storedProductIds = JSON.parse(localStorage.getItem('bagProducts')) || [];

    console.log(storedProductIds,"mukeem")

    if (storedProductIds.length > 0) {
      fetchProducts(storedProductIds);
    }
  }, []);

  // Function to fetch products by IDs
  const fetchProducts = async (productIds) => {
    try {
      const response = await fetch(`${url2}api/products/getproductsbyids`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: productIds }), // Send the IDs in the request body
      });

      const result = await response.json();
      if (response.ok) {
        setProducts(result);

        console.log("this is work")
        
        // Assuming the result contains the array of product objects
      } else {
        console.error(result.message || 'Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };













  return (
    <>
    
    
    
    <li
                      className="shpng_bag"
                      title="Shopping Bag"
                      
                    >
                      <div className="header">
                        <div
                          data-block="minicart"
                          className="minicart-wrapper quickcart-wrapper minicart-weltpixel active"
                        >
                          <a
                            className="action showcart minicart__clk active"
                            href=""
                            data-bind="scope: 'minicart_content'"
                            onClick={(event) => {
                              event.preventDefault();
                              Mybagshowtrue(); // Call the function to show the bag
                            }}
                          >
                            <span className="text">My Cart</span>
                            <span
                              className="counter qty"
                              data-bind="css: { empty: !!getCartParam('summary_count') == false }, blockLoader: isLoading"
                            >
                              <span className="counter-number">1</span>
                              <span className="counter-label">
                                1<span>items</span>
                              </span>
                            </span>
                          </a>

                       
                         

                          <div
                            tabIndex={-1}
                            role="dialog"
                            className="ui-dialog ui-corner-all ui-widget ui-widget-content ui-front mage-dropdown-dialog"
                            aria-describedby="ui-id-1"
                            style={{}}
                          >
                            <div
                              
                              className=" block-minicart block-quickcart empty ui-dialog-content ui-widget-content"
                              data-role="dropdownDialog"
                              id="ui-id-1"
                              style={{ display: Mybagshow }}
                            >
                              <div
                              
                                id="minicart-content-wrapper"
                                className="quickcart-content-wrapper"
                                data-bind="scope: 'minicart_content'"
                              >
                                <div className="block-title">
                                  <strong>
                                    <span className="text">
                                      <span >My Bag</span>
                                    </span>
                                    <span
                                      className="qty"
                                      title="Items in Bag"
                                    ></span>
                                  </strong>
                                </div>

                                <div className="block-content block-content-quickcart">
                                  <div className="quickcart-top">
                                    <span className="text">
                                      <span>My Bag</span>
                                    </span>

                                    <button
                                      onClick={Mybagshowclose}
                                      type="button"
                                      id="btn-minicart-close"
                                      className="action close"
                                      
                                     
                                    >
                                      <span>
                                        <span>Close</span>
                                      </span>
                                    </button>
                                  </div>

                                  <strong className="subtitle">
                                    <span>Recently added item(s)</span>
                                  </strong>
                                  <div
                                    data-action="scroll"
                                    className="minicart-items-wrapper quickcart-items-wrapper overflowed"
                                    style={{ height: "161.863px" }}
                                  >
                                    <ol
                                      id="mini-cart"
                                      className="minicart-items quickcart-items"
                                    >
                                      <li
                                        className="item product product-item quickcart-product-item odd last"
                                        data-role="product-item"
                                        data-collapsible="true"
                                      >
                                        <div className="product">
                                          <a
                                            data-bind="attr: {href: product_url, title: product_name}"
                                            tabIndex={-1}
                                            className="product-item-photo"
                                            href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html"
                                            title="Red &amp; Yellow Tipped Collar Pique Polo T-Shirt"
                                          >
                                            <span
                                              className="product-image-container"
                                              data-bind="style: {width: width/2 + 'px'}"
                                              style={{ width: 150 }}
                                            >
                                              <span
                                                className="product-image-wrapper"
                                                data-bind="style: {'padding-bottom': height/width*100 + '%'}"
                                                style={{
                                                  paddingBottom: "150%",
                                                }}
                                              >
                                                <img
                                                  className="product-image-photo"
                                                  data-bind="attr: {src: src, alt: alt}, style: {width: 'auto', height: 'auto'}"
                                                  src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/300x450/1_mfk-9307-q-03-red_5.jpg"
                                                  alt="Red & Yellow Tipped Collar Pique Polo T-Shirt"
                                                  style={{
                                                    width: "auto",
                                                    height: "auto",
                                                  }}
                                                />
                                              </span>
                                            </span>
                                          </a>

                                          <div className="product-item-details">
                                            <strong className="product-item-name">
                                              <a
                                                data-bind="attr: {href: product_url}, html: product_name"
                                                href="https://www.muftijeans.in/red-yellow-tipped-collar-pique-polo-t-shirt-mfk-9307-q-red.html"
                                              >
                                                Red & Yellow Tipped Collar Pique
                                                Polo T-Shirt
                                              </a>
                                            </strong>

                                            <div className="product actions">
                                              <div className="secondary">
                                                <a
                                                  href="#"
                                                  data-bind="attr: {'data-cart-item': item_id, title: $t('Remove item')}"
                                                  className="action delete"
                                                  data-cart-item="2735465"
                                                  title="Remove item"
                                                >
                                                  <span className="icon-line-cross">
                                                    <i
                                                      className="fa fa-trash"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                </a>
                                              </div>
                                            </div>

                                            <div
                                              className="product options"
                                              role="tablist"
                                              data-collapsible="true"
                                            >
                                              <div
                                                className="content"
                                                data-role="title"
                                                role="tab"
                                                aria-selected="false"
                                                aria-expanded="false"
                                                tabIndex={0}
                                              >
                                                <dl className="product options list">
                                                  <div className="option-wrapper">
                                                    <dt className="label">
                                                      Colours
                                                    </dt>
                                                    <dd className="values">
                                                      <span data-bind="html: option.value">
                                                        Red
                                                      </span>
                                                    </dd>
                                                  </div>

                                                  <div className="option-wrapper">
                                                    <dt className="label">
                                                      Size
                                                    </dt>
                                                    <dd className="values">
                                                      <span data-bind="html: option.value">
                                                        M-38
                                                      </span>
                                                    </dd>
                                                  </div>
                                                </dl>
                                              </div>
                                            </div>

                                            <div className="product-item-pricing">
                                              <div className="price-container">
                                                <span
                                                  className="price-wrapper"
                                                  data-bind="html: price"
                                                >
                                                  {" "}
                                                  <span
                                                    className="price-including-tax"
                                                    data-label="Incl. Tax"
                                                  >
                                                    <span className="minicart-old-price">
                                                      <span className="price">
                                                        ₹2,099
                                                      </span>
                                                    </span>{" "}
                                                    <span className="minicart-price ram">
                                                      {" "}
                                                      <span className="price">
                                                        ₹1,049
                                                      </span>
                                                    </span>{" "}
                                                    <span
                                                      className="buyfree-label"
                                                      style={{
                                                        display: "inline-block",
                                                        fontSize: 14,
                                                        float: "right",
                                                        paddingLeft: 5,
                                                      }}
                                                    >
                                                      (50% Off)
                                                    </span>{" "}
                                                  </span>{" "}
                                                </span>
                                              </div>

                                              <div className="details-qty qty">
                                                <label
                                                  className="label"
                                                  data-bind="i18n: 'Qty', attr: {\n                    for: 'cart-item-'+item_id+'-qty'}"
                                                  htmlFor="cart-item-2735465-qty"
                                                >
                                                  Quantity
                                                </label>
                                                <span className="item-minus qty-update" />
                                                <input
                                                  data-bind="attr: {\n                       id: 'cart-item-'+item_id+'-qty',\n                       'data-cart-item': item_id,\n                       'data-item-qty': qty,\n                       'data-cart-item-id': product_sku\n                       }, value: qty"
                                                  type="number"
                                                  size={4}
                                                  className="item-qty cart-item-qty"
                                                  maxLength={12}
                                                  disabled={true}
                                                  id="cart-item-2735465-qty"
                                                  data-cart-item="2735465"
                                                  data-item-qty="1"
                                                  data-cart-item-id="8905310245566"
                                                />
                                                <div className="spinner" />
                                                <button
                                                  data-bind="attr: {\n                           id: 'update-cart-item-'+item_id,\n                           'data-cart-item': item_id,\n                           title: $t('Update')\n                           }"
                                                  className="update-cart-item"
                                                  style={{ display: "none" }}
                                                  id="update-cart-item-2735465"
                                                  data-cart-item="2735465"
                                                  title="Update"
                                                >
                                                  <span data-bind="i18n: 'Update'">
                                                    Update
                                                  </span>
                                                </button>
                                                <span className="item-plus qty-update" />
                                              </div>
                                            </div>
                                          </div>




                                          
                                        </div>
                                      </li>
                                      <BagProducts/>
                                      



                                    </ol>
                                  </div>

                                  <div className="quickcart-bottom-container">
                                    <div className="items-total">
                                      <span className="count">1</span>

                                      <span>item</span>
                                    </div>

                                    <a
                                      className="coupon-code-main coupon-click-btn"
                                      data-trigger="open-coupon-list-minicart"
                                    >
                                      <p className="coupon-code-title">
                                        APPLY COUPON
                                        <i
                                          className="fa fa-angle-right"
                                          aria-hidden="true"
                                        />
                                      </p>
                                    </a>

                                    <div className="minicart-savings total-savings">
                                      <span
                                        className="price-wrapper"
                                        data-bind="html: cart().message"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        You have saved{" "}
                                        <span className="price">₹1,050</span>
                                      </span>
                                    </div>

                                    <div className="subtotal">
                                      <span className="label">
                                        <span>Total:</span>
                                      </span>

                                      <div className="amount price-container">
                                        <span
                                          className="price-wrapper"
                                          data-bind="html: cart().subtotal_incl_tax"
                                        >
                                          <span className="price">₹1,049</span>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="actions action-viewcart bottom-aligned">
                                      <div className="secondary">
                                        <a
                                          className="action viewcart"
                                          data-bind="attr: {href: shoppingCartUrl}"
                                          href="https://www.muftijeans.in/checkout/cart/"
                                        >
                                          <span>
                                            <span>Go To Bag</span>
                                          </span>
                                        </a>
                                      </div>
                                    </div>

                                    <div className="actions action-checkout">
                                      <div className="secondary">
                                        <a
                                          className="action viewcart"
                                          id="top-cart-btn-checkout"
                                          data-bind="attr: {title: $t('Checkout')}"
                                          title="Checkout"
                                        >
                                          <span>
                                            <span>Checkout</span>
                                          </span>
                                        </a>
                                        <div
                                          className="extra-actions"
                                          data-bind="html: getCartParam('extra_actions')"
                                        ></div>
                                      </div>
                                    </div>

                                    <div
                                      id="minicart-widgets"
                                      className="minicart-widgets"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className=""
                              style={{ zIndex: "901", overflow: "hidden" }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
    
    
    
    
    
    
    </>
  )
}

export default Mybags

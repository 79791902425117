import React from 'react'

function Checkoutform() {
  return (
<>


 <div className="col-mp mp-12" style={{display:"none"}}>
                                            { /* ko template: getAddressTemplate() */ }

                                            <div id="shipping" className="checkout-shipping-address" data-bind="fadeVisible: visible()">
                                                <div className="step-title" data-role="title">
                                                    <i className="fa fa-home" />
                                                    { /* ko if: window.checkoutConfig.oscConfig.isUsedMaterialDesign */ }{ /* /ko */ }
                                                    <span data-bind="i18n: 'Shipping Address'">Shipping Address</span>
                                                </div>
                                                <div id="checkout-step-shipping" className="step-content" data-role="content">

                                                    { /* ko if: (!quoteIsVirtual) */ }
                                                    { /* ko foreach: getRegion('customer-email') */ }
                                                    { /* ko template: getTemplate() */ }

                                                    { /* ko foreach: getRegion('amazon-button-region') */ }{ /* /ko */ }


                                                    { /* ko ifnot: isCustomerLoggedIn() */ }{ /* /ko */ }
                                                    { /* /ko */ }
                                                    { /*/ko*/ }
                                                    { /*/ko*/ }

                                                    { /* ko foreach: getRegion('address-list') */ }
                                                    { /* ko template: getTemplate() */ }

                                                    { /* ko if: (visible)*/ }{ /* /ko */ }
                                                    { /* /ko */ }
                                                    { /*/ko*/ }

                                                    { /* ko foreach: getRegion('address-list-additional-addresses') */ }
                                                    { /* ko template: getTemplate() */ }
                                                    { /* ko foreach: {data: elems, as: 'element'} */ }{ /* /ko */ }
                                                    { /* /ko */ }
                                                    { /*/ko*/ }

                                                    { /* Address form pop up */ }
                                                    { /* ko if: (!isFormInline) */ }{ /* /ko */ }

                                                    { /* ko foreach: getRegion('before-form') */ }
                                                    { /* ko template: getTemplate() */ }
                                                    { /* ko foreach: {data: elems, as: 'element'} */ }{ /* /ko */ }
                                                    { /* /ko */ }
                                                    { /*/ko*/ }

                                                    { /* ko if: !isAmazonAccountLoggedIn() */ }
                                                    { /* Inline address form */ }
                                                    { /* ko if: (isFormInline) */ }
                                                    { /* ko template: 'Mageplaza_Osc/container/address/shipping/form' */ }

                                                    <form className="form form-shipping-address" id="co-shipping-form" data-bind="attr: {'data-hasrequired': $t('* Required Fields')}" data-hasrequired="* Required Fields">
                                                        { /* ko foreach: getRegion('before-fields') */ }
                                                        { /* ko template: getTemplate() */ }
                                                        { /* ko foreach: {data: elems, as: 'element'} */ }{ /* /ko */ }
                                                        { /* /ko */ }
                                                        { /*/ko*/ }
                                                        <div id="shipping-new-address-form" className="fieldset address">
                                                            { /* ko foreach: getRegion('additional-fieldsets') */ }
                                                            { /* ko template: getTemplate() */ }
                                                            { /* ko foreach: {data: elems, as: 'element'} */ }
                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.postcode">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="XS51JWF">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Zip/Postal
                                                                        Code</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }
                                                                    <input className="input-text" name="postcode" type="number" pattern="[0-9]*" inputMode="numeric" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': getDescriptionId(),\n        'aria-required': required,\n        'aria-invalid': error() ? true : 'false',\n        id: uid,\n        disabled: disabled\n    }" aria-required="true" aria-invalid="false" id="XS51JWF" fdprocessedid="8bma3d" />{ /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.firstname">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="LNOGXS4">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">First
                                                                        Name</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }

                                                                    <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="firstname" aria-describedby="notice-LNOGXS4" id="LNOGXS4" fdprocessedid="y4ta1" />
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.lastname">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="K5JTSEN">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Last
                                                                        Name</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }

                                                                    <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="lastname" aria-describedby="notice-K5JTSEN" id="K5JTSEN" fdprocessedid="go1v5p" />
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.telephone">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="CYSPKYD">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Phone
                                                                        Number</span>{ /* /ko */ }</label>

                                                                <div className="control _with-tooltip" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }
                                                                    <input className="input-text" type="number" pattern="[0-9]*" inputMode="numeric" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': getDescriptionId(),\n        'aria-required': required,\n        'aria-invalid': error() ? true : 'false',\n        id: uid,\n        disabled: disabled\n    }" name="telephone" aria-required="true" aria-invalid="false" id="CYSPKYD" fdprocessedid="w71zhs" />{ /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }
                                                                    { /* ko template: element.tooltipTpl */ }
                                                                    <div className="field-tooltip toggle">

                                                                        { /* ko if: (tooltip.link)*/ }{ /* /ko */ }

                                                                        <span className="label" data-bind="attr: { id: $data.tooltipId ? $data.tooltipId : 'tooltip-label' }" id="tooltip-CYSPKYD">{ /* ko i18n: 'Tooltip' */ }<span>Tooltip</span>{ /* /ko */ }</span>
                                                                        { /* ko if: (!tooltip.link)*/ }
                                                                        <span className="field-tooltip-action action-help" tabIndex={0} data-toggle="dropdown" data-bind="\n                mageInit: {'dropdown':{'activeClass': '_active', 'parent': '.field-tooltip.toggle'}},\n                attr: { 'aria-labelledby': $data.tooltipId ? $data.tooltipId : 'tooltip-label' }\n            " aria-labelledby="tooltip-CYSPKYD" aria-haspopup="true" aria-expanded="false" role="button">
                                                                        </span>
                                                                        { /* /ko */ }

                                                                        <div className="field-tooltip-content" data-target="dropdown" data-bind="i18n: tooltip.description" aria-hidden="true">For delivery questions.
                                                                        </div>
                                                                    </div>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.country_id">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="A8NXFDC">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Country</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }
                                                                    <select className="select" data-bind="\n    attr: {\n        name: inputName,\n        id: uid,\n        disabled: disabled,\n        'aria-describedby': getDescriptionId(),\n        'aria-required': required,\n        'aria-invalid': error() ? true : 'false',\n        placeholder: placeholder\n    },\n    hasFocus: focused,\n    optgroup: options,\n    value: value,\n    optionsCaption: caption,\n    optionsValue: 'value',\n    optionsText: 'label',\n    optionsAfterRender: function(option, item) {\n        if (item && item.disabled) {\n            ko.applyBindingsToNode(option, {attr: {disabled: true}}, item);\n        }\n    }" name="country_id" id="A8NXFDC" aria-required="true" aria-invalid="false" fdprocessedid="p51t4" disabled={true}>
                                                                        <option data-title="India" value="IN">India
                                                                        </option>
                                                                        <option data-title="\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500" value="delimiter" disabled={true}>──────────
                                                                        </option>
                                                                    </select>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.region" style={{ display: "none" }}>

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="LN3SACU">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">State/Province</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }

                                                                    <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="region" aria-describedby="notice-LN3SACU" id="LN3SACU" />
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.region_id">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="I2P2J13">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">State/Province</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }
                                                                    <select className="select" data-bind="\n    attr: {\n        name: inputName,\n        id: uid,\n        disabled: disabled,\n        'aria-describedby': getDescriptionId(),\n        'aria-required': required,\n        'aria-invalid': error() ? true : 'false',\n        placeholder: placeholder\n    },\n    hasFocus: focused,\n    optgroup: options,\n    value: value,\n    optionsCaption: caption,\n    optionsValue: 'value',\n    optionsText: 'label',\n    optionsAfterRender: function(option, item) {\n        if (item && item.disabled) {\n            ko.applyBindingsToNode(option, {attr: {disabled: true}}, item);\n        }\n    }" name="region_id" id="I2P2J13" aria-required="true" aria-invalid="false" fdprocessedid="vk7sdv" disabled={true}>
                                                                        <option value={true}>Please select a region, state
                                                                            or province.</option>
                                                                        <option data-title="Telangana" value="522">
                                                                            Telangana</option>
                                                                        <option data-title="Uttarakhand" value="523">
                                                                            Uttarakhand</option>
                                                                        <option data-title="Andaman & Nicobar" value="520">Andaman & Nicobar</option>
                                                                        <option data-title="Andhra Pradesh" value="485">
                                                                            Andhra Pradesh</option>
                                                                        <option data-title="Arunachal Pradesh" value="486">Arunachal Pradesh</option>
                                                                        <option data-title="Assam" value="487">Assam
                                                                        </option>
                                                                        <option data-title="Bihar" value="488">Bihar
                                                                        </option>
                                                                        <option data-title="Chandigarh" value="489">
                                                                            Chandigarh</option>
                                                                        <option data-title="Chhattisgarh" value="490">
                                                                            Chhattisgarh</option>
                                                                        <option data-title="Dadra" value="521">Dadra
                                                                        </option>
                                                                        <option data-title="Dadra and Nagar Haveli" value="491">Dadra and Nagar Haveli</option>
                                                                        <option data-title="Daman and Diu" value="492">
                                                                            Daman and Diu</option>
                                                                        <option data-title="Delhi" value="493">Delhi
                                                                        </option>
                                                                        <option data-title="Goa" value="494">Goa
                                                                        </option>
                                                                        <option data-title="Gujarat" value="495">Gujarat
                                                                        </option>
                                                                        <option data-title="Haryana" value="496">Haryana
                                                                        </option>
                                                                        <option data-title="Himachal Pradesh" value="497">Himachal Pradesh</option>
                                                                        <option data-title="Jammu and Kashmir" value="498">Jammu and Kashmir</option>
                                                                        <option data-title="Jharkhand" value="499">
                                                                            Jharkhand</option>
                                                                        <option data-title="Karnataka" value="500">
                                                                            Karnataka</option>
                                                                        <option data-title="Kerala" value="501">Kerala
                                                                        </option>
                                                                        <option data-title="Lakshadweep" value="502">
                                                                            Lakshadweep</option>
                                                                        <option data-title="Madhya Pradesh" value="503">
                                                                            Madhya Pradesh</option>
                                                                        <option data-title="Maharashtra" value="504">
                                                                            Maharashtra</option>
                                                                        <option data-title="Manipur" value="505">Manipur
                                                                        </option>
                                                                        <option data-title="Meghalaya" value="506">
                                                                            Meghalaya</option>
                                                                        <option data-title="Mizoram" value="507">Mizoram
                                                                        </option>
                                                                        <option data-title="Nagaland" value="508">
                                                                            Nagaland</option>
                                                                        <option data-title="Odisha" value="510">Odisha
                                                                        </option>
                                                                        <option data-title="Pondicherry" value="511">
                                                                            Pondicherry</option>
                                                                        <option data-title="Punjab" value="512">Punjab
                                                                        </option>
                                                                        <option data-title="Rajasthan" value="513">
                                                                            Rajasthan</option>
                                                                        <option data-title="Sikkim" value="514">Sikkim
                                                                        </option>
                                                                        <option data-title="Tamil Nadu" value="515">
                                                                            Tamil Nadu</option>
                                                                        <option data-title="Tripura" value="516">Tripura
                                                                        </option>
                                                                        <option data-title="Uttar Pradesh" value="517">
                                                                            Uttar Pradesh</option>
                                                                        <option data-title="Uttaranchal" value="518">
                                                                            Uttaranchal</option>
                                                                        <option data-title="West Bengal" value="519">
                                                                            West Bengal</option>
                                                                    </select>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear required _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.city">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="LJ7080X">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">City</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }

                                                                    <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="city" aria-describedby="notice-LJ7080X" id="LJ7080X" fdprocessedid="aj87wo" readOnly />
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear not-required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.custom_attributes.mposc_field_1" style={{ display: "none" }}>

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="G0MW845">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Locality</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }

                                                                    <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="custom_attributes[mposc_field_1]" aria-describedby="notice-G0MW845" id="G0MW845" fdprocessedid="931j55" />
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <div className="field col-mp mp-6 mp-clear _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.custom_attributes.locality_1">

                                                                <label className="label" data-bind="attr: { for: element.uid }" htmlFor="KSKBP90">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Locality</span>{ /* /ko */ }</label>

                                                                <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                    { /* ko ifnot: element.hasAddons() */ }
                                                                    { /* ko template: element.elementTmpl */ }
                                                                    <select className="select" data-bind="\n    attr: {\n        name: inputName,\n        id: uid,\n        disabled: disabled,\n        'aria-describedby': getDescriptionId(),\n        'aria-required': required,\n        'aria-invalid': error() ? true : 'false',\n        placeholder: placeholder\n    },\n    hasFocus: focused,\n    optgroup: options,\n    value: value,\n    optionsCaption: caption,\n    optionsValue: 'value',\n    optionsText: 'label',\n    optionsAfterRender: function(option, item) {\n        if (item && item.disabled) {\n            ko.applyBindingsToNode(option, {attr: {disabled: true}}, item);\n        }\n    }" name="custom_attributes[locality_1]" id="KSKBP90" aria-required="true" aria-invalid="false" fdprocessedid="017bk">
                                                                        <option value={true}>Select Locality</option>
                                                                    </select>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                    { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                    { /* ko if: element.notice */ }{ /* /ko */ }

                                                                    { /* ko if: element.error() */ }{ /* /ko */ }

                                                                    { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                </div>
                                                            </div>
                                                            { /* /ko */ }{ /* /ko */ }

                                                            { /* ko if: hasTemplate() */ }{ /* ko template: getTemplate() */ }
                                                            <fieldset className="field street col-mp mp-6 required admin__control-fields" data-bind="css: additionalClasses">
                                                                <legend className="label">
                                                                    <span data-bind="i18n: element.label">Street
                                                                        Address</span>
                                                                </legend>
                                                                <div className="control">
                                                                    { /* ko foreach: { data: elems, as: 'element' } */ }

                                                                    { /* ko if: element.visible() */ }

                                                                    { /* ko ifnot: (element.input_type == 'checkbox' || element.input_type == 'radio') */ }
                                                                    { /* ko template: $parent.fieldTemplate */ }
                                                                    <div className="field _required" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.street.0">

                                                                        <label className="label" data-bind="attr: { for: element.uid }" htmlFor="LSSNCNG">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Street
                                                                                Address: Line
                                                                                1</span>{ /* /ko */ }</label>

                                                                        <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                            { /* ko ifnot: element.hasAddons() */ }
                                                                            { /* ko template: element.elementTmpl */ }

                                                                            <input className="input-text pac-target-input" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeHolder: placeHolder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="street[0]" aria-describedby="notice-LSSNCNG" id="LSSNCNG" fdprocessedid="jlcu4" placeholder="Enter a location" autoComplete="new-password" />
                                                                            { /* /ko */ }
                                                                            { /* /ko */ }

                                                                            { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                            { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                            { /* ko if: element.notice */ }{ /* /ko */ }

                                                                            { /* ko if: element.error() */ }{ /* /ko */ }

                                                                            { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                        </div>
                                                                    </div>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: (element.input_type == 'checkbox' || element.input_type == 'radio') */ }{ /* /ko */ }

                                                                    { /* /ko */ }



                                                                    { /* ko if: element.visible() */ }

                                                                    { /* ko ifnot: (element.input_type == 'checkbox' || element.input_type == 'radio') */ }
                                                                    { /* ko template: $parent.fieldTemplate */ }
                                                                    <div className="field additional" data-bind="visible: visible, attr: {'name': element.dataScope}, css: additionalClasses" name="shippingAddress.street.1">

                                                                        <label className="label" data-bind="attr: { for: element.uid }" htmlFor="F8FJW95">{ /* ko if: element.label */ }<span data-bind="i18n: element.label">Street
                                                                                Address: Line
                                                                                2</span>{ /* /ko */ }</label>

                                                                        <div className="control" data-bind="css: {'_with-tooltip': element.tooltip}">
                                                                            { /* ko ifnot: element.hasAddons() */ }
                                                                            { /* ko template: element.elementTmpl */ }

                                                                            <input className="input-text" type="text" data-bind="\n    value: value,\n    valueUpdate: 'keyup',\n    hasFocus: focused,\n    attr: {\n        name: inputName,\n        placeholder: placeholder,\n        'aria-describedby': noticeId,\n        id: uid,\n        disabled: disabled\n    }" required name="street[1]" aria-describedby="notice-F8FJW95" id="F8FJW95" fdprocessedid="7kmp05" />
                                                                            { /* /ko */ }
                                                                            { /* /ko */ }

                                                                            { /* ko if: element.hasAddons() */ }{ /* /ko */ }

                                                                            { /* ko if: element.tooltip */ }{ /* /ko */ }

                                                                            { /* ko if: element.notice */ }{ /* /ko */ }

                                                                            { /* ko if: element.error() */ }{ /* /ko */ }

                                                                            { /* ko if: element.warn() */ }{ /* /ko */ }
                                                                        </div>
                                                                    </div>
                                                                    { /* /ko */ }
                                                                    { /* /ko */ }

                                                                    { /* ko if: (element.input_type == 'checkbox' || element.input_type == 'radio') */ }{ /* /ko */ }

                                                                    { /* /ko */ }

                                                                    { /* /ko */ }

                                                                    { /* ko if: validateWholeGroup */ }{ /* /ko */ }
                                                                </div>
                                                            </fieldset>
                                                            { /* /ko */ }{ /* /ko */ }
                                                            { /* /ko */ }
                                                            { /* /ko */ }
                                                            { /*/ko*/ }

                                                            { /* ko if: (isCustomerLoggedIn) */ }
                                                            <div className="field choice col-mp mp-12" data-bind="visible: !isFormInline" style={{ display: "none" }}>
                                                                <input type="checkbox" className="checkbox" id="shipping-save-in-address-book" data-bind="checked: saveInAddressBook" />
                                                                <label className="label" htmlFor="shipping-save-in-address-book">
                                                                    <span data-bind="i18n: 'Save in address book'">Save
                                                                        in address book</span>
                                                                </label>
                                                            </div>
                                                            { /* /ko */ }

                                                            <div className="mp-clear" />
                                                        </div>
                                                    </form>
                                                    { /* /ko */ }
                                                    { /* /ko */ }

                                                    { /* ko if: (!isCustomerLoggedIn() && !quoteIsVirtual) */ }{ /*/ko*/ }

                                                    <div className="mp-clear" />

                                                    { /* ko scope: 'checkout.steps.shipping-step.billingAddress' */ }
                                                    { /* ko template: 'Mageplaza_Osc/container/address/billing/checkbox' */ }

                                                    <div className="billing-address-same-as-shipping-block field choice col-mp mp-12" data-bind="visible: canUseShippingAddress()" style={{ display: "none" }}>
                                                        <input type="checkbox" name="billing-address-same-as-shipping" data-bind="checked: isAddressSameAsShipping, click: useShippingAddress, attr: {id: 'billing-address-same-as-shipping'}" id="billing-address-same-as-shipping" />
                                                        <label data-bind="attr: {for: 'billing-address-same-as-shipping'}" htmlFor="billing-address-same-as-shipping"><span data-bind="i18n: 'My billing and shipping address are the same'">My
                                                                billing and shipping address are the same</span></label>
                                                    </div>{ /* /ko */ }
                                                    { /*/ko*/ }
                                                    { /*/ko*/ }

                                                    <div className="mp-clear" />
                                                </div>
                                            </div>{ /* /ko */ }
                                        </div>












</>
  )
}

export default Checkoutform

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Phonenav from "./Phonenav";
import { MyContext } from "../Context/Mycontext";
import Useraddress from "./Useraddress/Useraddress";

function Profile() {
  const context = useContext(MyContext);

  const { navshow, setnavshow } = context;

  useEffect(() => {
    // Function to remove old classes and add new ones to the body tag
    const removeAndAddClass = () => {
      const body = document.body;

      // Clear all previous classes from the body element
      body.className = "";

      // Add new classes to the body element
      body.classList.add(
        "account",
        "customer-account-index",
        "page-layout-2columns-left"
      );
    };

    // Call the function inside useEffect
    removeAndAddClass();
  }, []);

  return (
    <>
      <main id="maincontent" className="page-main">
        {" "}
        <a id="contentarea" tabIndex={-1} />
        <div className="page messages">
          <div data-placeholder="messages" />
          <div data-bind="scope: 'messages'">
            {/* ko if: cookieMessages && cookieMessages.length > 0 */}
            {/* /ko */}
            {/* ko if: messages().messages && messages().messages.length > 0 */}
            {/* /ko */}
          </div>
        </div>
        <div className="columns">
          <div
            className="column main"
            style={{ display: navshow === "active" ? "none" : "" }}
          >
            <div className="page-title-wrapper">
              <h1 className="page-title">
                <span className="base" data-ui-id="page-title-wrapper">
                  My Dashboard
                </span>
              </h1>
            </div>
            <input name="form_key" type="hidden" value="Oh5Dn3Bcs3geVw09" />
            <div
              id="authenticationPopup"
              data-bind="scope:'authenticationPopup', style: {display: 'none'}"
              style={{ display: "none" }}
            >
              {" "}
              {/* ko template: getTemplate() */}
              {/* /ko */}
            </div>{" "}
            <span />
            <div className="block block-dashboard-info my-account-main">
              <div className="block-content">
                <div className="row">
                  <div className="box box-information col-sm-6 col-xs-12">
                    <div className="block-title--myac">
                      <h3>Contact Information</h3>
                    </div>
                    <div className="box-content">
                      <p>
                        mukeem md
                        <br /> mohdmukeem9971@gmail.com
                        <br />
                      </p>
                    </div>
                    <div className="box-actions">
                      <a
                        href="https://www.muftijeans.in/customer/account/edit/changepass/1/"
                        className="action btn btn-xs btn-default change-password"
                      >
                        Change Password
                      </a>{" "}
                      <a
                        className="action btn btn-xs btn-secondary margin-left10 edit"
                        href="https://www.muftijeans.in/customer/account/edit/"
                      >
                        <span>Edit</span>
                      </a>
                    </div>
                  </div>
                  <div className="box box-newsletter col-sm-6 col-xs-12">
                    <div className="block-title--myac">
                      <h3>Newsletters</h3>
                    </div>
                    <div className="box-content">
                      <p> You don't subscribe to our newsletter. </p>
                      <div className="margin-top15">
                        <a
                          className="action btn btn-xs btn-secondary edit"
                          href="https://www.muftijeans.in/newsletter/manage/"
                        >
                          <span>Edit</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block block-dashboard-addresses my-account-main">
              <div className="block-title--myac">
                <h3>
                  <span>Address Book</span>
                </h3>
              </div>
              <div className="block-content row">


               <Useraddress/>



               

                


              </div>
              <div className="margin-top20">
                <a
                  className="action btn btn-secondary edit"
                  href="https://www.muftijeans.in/customer/address/"
                >
                  <span>Manage Addresses</span>
                </a>
              </div>
            </div>
            <section
              className="amsearch-overlay-block"
              data-bind=" scope: 'index = amsearch_overlay_section', mageInit: { 'Magento_Ui/js/core/app': { components: { 'amsearch_overlay_section': { component: 'Amasty_Xsearch/js/components/overlay' } } } }"
            >
              <div
                className="amsearch-overlay"
                data-bind=" event: { click: hide }, afterRender: initNode"
              />
            </section>
          </div>

          {/* <div className="sidebar sidebar-main desktop-filter">
                    <div className="custom_menu_top_img">
                        <div className="user-image desk-user-image"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#000" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                        </path>
                                    </g>
                                </svg></a></div>
                    </div>
                    <div className="custom_menu_top">
                        <div className="mufti-userprofile"><a className="mblclosemenu dskclose logedin" href="javascript:void(0)"><svg width="24" height="24" viewBox="0 0 24 24" className="close-icon" fill="#ffffff">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M0 0h24v24H0z" opacity="0.05" />
                                        <path fill="#ffffff" d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z">
                                        </path>
                                    </g>
                                </svg></a>
                            <div className="user-image1"><a className="naviLevel" href="javascript:void(0)"><svg width="36" height="36" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M0 0h24v24H0z" opacity="0.05" />
                                            <path fill="#fff" d="M5.808 18.004l8.895-.039c-.304-1.27-1.778-1.775-3.036-1.97a.5.5 0 01-.423-.49l-.012-1.071a3.94 3.94 0 01-.977.126 3.97 3.97 0 01-.981-.127l.007 1.063a.5.5 0 01-.426.499c-.843.125-2.695.563-3.047 2.01zm1.448-7.444c0 1.654 1.345 3 2.999 3a3.003 3.003 0 002.998-3v-1.5c0-1.654-1.345-3-2.998-3a3.003 3.003 0 00-2.999 3v1.5zm4.983 4.52c2.275.462 3.52 1.653 3.52 3.383a.497.497 0 01-.497.496L5.252 19H5.25a.497.497 0 01-.5-.494c0-1.716 1.31-2.975 3.529-3.425l-.008-1.052a4 4 0 01-2.014-3.468v-1.5c0-2.206 1.793-4 3.998-4a4.003 4.003 0 013.998 4v1.5a4 4 0 01-2.026 3.475l.012 1.046zM16.252 6a.5.5 0 010-1h2.498a.5.5 0 010 1h-2.498zm2.498 3a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498zm0 4a.5.5 0 010 1h-2.498a.5.5 0 010-1h2.498z">
                                            </path>
                                        </g>
                                    </svg></a></div>
                        </div>
                    </div>
                    <div className="block block-collapsible-nav">
                        <div className="title block-collapsible-nav-title"><strong> My Account</strong></div>
                        <div className="content block-collapsible-nav-content" id="block-collapsible-nav">
                            <ul className="nav items">
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item"><span className="delimiter" /></li>
                                <li className="nav item customer-order-list"><a className="my-orders" href="https://www.muftijeans.in/sales/order/history/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/my-orders.png" />My Orders</a>
                                </li>
                                <li className="nav item customer-profile"><a href="https://www.muftijeans.in/customer/account/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/profile.png" />Profile</a>
                                    <ul className="nav-sub-menu">
                                        <li className="nav item"><a href="https://www.muftijeans.in/customer/account/">Account
                                                Information</a></li>
                                        <li className="nav item"><a href="https://www.muftijeans.in/mobilelogin/index/updatemobile/">Update
                                                Communication</a></li>
                                    </ul>
                                </li>
                                <li className="nav item customer-manage-address"><a href="https://www.muftijeans.in/customer/address/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/manage-address.png" />Manage
                                        Address</a></li>
                                <li className="nav item customer-wishlist-list"><a href="https://www.muftijeans.in/wishlist/"><img src="https://d4kuloxg8pkbr.cloudfront.net/icons/wishlist.png" />Wishlist</a>
                                </li>
                                <style>{`
                                    .block-dashboard-info .box-actions {
                                        display: none;
                                    }
                                `}</style>
                            </ul>
                        </div>
                    </div>
                </div> */}

          <Phonenav />
        </div>
      </main>
    </>
  );
}

export default Profile;

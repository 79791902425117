import React from 'react'

function Productreleted() {
  return (
    <>
    
     <div className="block related" data-limit="0" data-shuffle="0" data-shuffle-weighted="0">
                            <div className="block-title title">
                                <h3 id="block-related-heading" role="heading" aria-level="2">Related Products</h3>
                            </div>
                            
                            <div className="block-content content" aria-labelledby="block-related-heading">
                                <div className="products wrapper grid products-grid products-related">
                                    <div className="products list items product-items" id="similar-product">
                                        <div className="item product product-item" id="product-item_99118" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/navy-multi-tipped-collar-pique-polo-t-shirt-mfk-9302-q-navy.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-99118" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9302-q-05-navy_1_mfk-9302-q-05-navy.jpg" loading="lazy" width="500" height="667" alt="Navy & Multi Tipped Collar Pique Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-99118 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-99118 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Navy & Multi Tipped Collar Pique Polo T-Shirt" href="https://www.muftijeans.in/navy-multi-tipped-collar-pique-polo-t-shirt-mfk-9302-q-navy.html" data-full-name="Navy & Multi Tipped Collar Pique Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Navy & Multi Tipped Collar
                                                            Pique Polo T-...</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,099</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="99118" data-price-box="product-id-99118"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-99118" data-price-amount="1049" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,049</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-99118" data-price-amount="2099" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,099</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_99117" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/white-multi-tipped-collar-pique-polo-t-shirt-mfk-9302-q-white.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-99117" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9302-q-02-white_1_mfk-9302-q-02-white.jpg" loading="lazy" width="500" height="667" alt="White & Multi Tipped Collar Pique Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-99117 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-99117 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="White & Multi Tipped Collar Pique Polo T-Shirt" href="https://www.muftijeans.in/white-multi-tipped-collar-pique-polo-t-shirt-mfk-9302-q-white.html" data-full-name="White & Multi Tipped Collar Pique Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">White & Multi Tipped Collar
                                                            Pique Polo T...</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,099</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="99117" data-price-box="product-id-99117"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-99117" data-price-amount="1049" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,049</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-99117" data-price-amount="2099" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,099</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85436" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/black-stripe-textured-jersey-mfk-9321-q-black.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85436" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9321-q-01-black_1_mfk-9321-q-01-black.jpg" loading="lazy" width="500" height="667" alt="Black Stripe Textured Jersey" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85436 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85436 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Black Stripe Textured Jersey" href="https://www.muftijeans.in/black-stripe-textured-jersey-mfk-9321-q-black.html" data-full-name="Black Stripe Textured Jersey" data-default-max-length="40" data-mobile-max-length="20">Black Stripe Textured Jersey</a>
                                                    </div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,099</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85436" data-price-box="product-id-85436"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85436" data-price-amount="1049" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,049</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85436" data-price-amount="2099" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,099</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85438" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/maroon-stripe-textured-jersey-mfk-9321-q-maroon.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85438" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9321-q-57-maroon_1_mfk-9321-q-57-maroon.jpg" loading="lazy" width="500" height="667" alt="Maroon Stripe Textured Jersey" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85438 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85438 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Maroon Stripe Textured Jersey" href="https://www.muftijeans.in/maroon-stripe-textured-jersey-mfk-9321-q-maroon.html" data-full-name="Maroon Stripe Textured Jersey" data-default-max-length="40" data-mobile-max-length="20">Maroon Stripe Textured
                                                            Jersey</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,099</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85438" data-price-box="product-id-85438"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85438" data-price-amount="1049" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,049</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85438" data-price-amount="2099" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,099</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85453" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/green-textured-polo-t-shirt-mfk-9332-q-green.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85453" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9332-q-04-green_1_mfk-9332-q-04-green.jpg" loading="lazy" width="500" height="667" alt="Green Textured Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85453 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85453 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Green Textured Polo T-Shirt" href="https://www.muftijeans.in/green-textured-polo-t-shirt-mfk-9332-q-green.html" data-full-name="Green Textured Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Green Textured Polo T-Shirt</a>
                                                    </div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,199</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85453" data-price-box="product-id-85453"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85453" data-price-amount="1099" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,099</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85453" data-price-amount="2199" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,199</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85455" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/light-blue-textured-polo-t-shirt-mfk-9332-q-light-blue.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85455" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9332-q-163-light-blue_1.jpg" loading="lazy" width="500" height="667" alt="Light Blue Textured Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85455 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85455 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Light Blue Textured Polo T-Shirt" href="https://www.muftijeans.in/light-blue-textured-polo-t-shirt-mfk-9332-q-light-blue.html" data-full-name="Light Blue Textured Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Light Blue Textured Polo
                                                            T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,199</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85455" data-price-box="product-id-85455"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85455" data-price-amount="1099" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,099</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85455" data-price-amount="2199" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,199</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_86503" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/mustard-textured-solid-jersey-polo-t-shirt-mfk-9322-q-mustard.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-86503" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9322-q-12-yellow_6.jpg" loading="lazy" width="500" height="667" alt="Mustard Textured Solid Jersey Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-86503 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-86503 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Mustard Textured Solid Jersey Polo T-Shirt" href="https://www.muftijeans.in/mustard-textured-solid-jersey-polo-t-shirt-mfk-9322-q-mustard.html" data-full-name="Mustard Textured Solid Jersey Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Mustard Textured Solid Jersey
                                                            Polo T-Shi...</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,199</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="86503" data-price-box="product-id-86503"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-86503" data-price-amount="1099" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,099</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-86503" data-price-amount="2199" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,199</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_86504" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/turquoise-textured-solid-jersey-polo-t-shirt-mfk-9322-q-turquoise.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-86504" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9322-q-20-turq_6.jpg" loading="lazy" width="500" height="667" alt="Turquoise Textured Solid Jersey Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-86504 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-86504 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Turquoise Textured Solid Jersey Polo T-Shirt" href="https://www.muftijeans.in/turquoise-textured-solid-jersey-polo-t-shirt-mfk-9322-q-turquoise.html" data-full-name="Turquoise Textured Solid Jersey Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Turquoise Textured Solid Jersey
                                                            Polo T-S...</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,199</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="86504" data-price-box="product-id-86504"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-86504" data-price-amount="1099" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,099</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-86504" data-price-amount="2199" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,199</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87462" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/rust-jersey-graphic-tee-mfk-9436-q-orange.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87462" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9436-q-10-rust_6.jpg" loading="lazy" width="500" height="667" alt="Rust Jersey Graphic Tee " /></span></span>
                                                    <style>{`
                                                        .product-image-container-87462 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87462 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Rust Jersey Graphic Tee " href="https://www.muftijeans.in/rust-jersey-graphic-tee-mfk-9436-q-orange.html" data-full-name="Rust Jersey Graphic Tee " data-default-max-length="40" data-mobile-max-length="20">Rust Jersey Graphic Tee </a>
                                                    </div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,399</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87462" data-price-box="product-id-87462"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87462" data-price-amount="699" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹699</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87462" data-price-amount="1399" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,399</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87452" data-shuffle-group="">
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/white-solid-knitted-jersey-t-shirt-mfk-9403-q-white.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87452" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9403-q-02-white_1.jpg" loading="lazy" width="500" height="667" alt="White Solid Knitted Jersey T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-87452 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87452 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="White Solid Knitted Jersey T-Shirt" href="https://www.muftijeans.in/white-solid-knitted-jersey-t-shirt-mfk-9403-q-white.html" data-full-name="White Solid Knitted Jersey T-Shirt" data-default-max-length="40" data-mobile-max-length="20">White Solid Knitted Jersey
                                                            T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,599</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87452" data-price-box="product-id-87452"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87452" data-price-amount="799" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹799</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87452" data-price-amount="1599" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,599</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87451" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/black-solid-knitted-jersey-t-shirt-mfk-9403-q-black.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87451" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9403-q-01-black_1.jpg" loading="lazy" width="500" height="667" alt="Black Solid Knitted Jersey T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-87451 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87451 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Black Solid Knitted Jersey T-Shirt" href="https://www.muftijeans.in/black-solid-knitted-jersey-t-shirt-mfk-9403-q-black.html" data-full-name="Black Solid Knitted Jersey T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Black Solid Knitted Jersey
                                                            T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,599</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87451" data-price-box="product-id-87451"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87451" data-price-amount="799" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹799</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87451" data-price-amount="1599" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,599</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87450" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/olive-solid-slub-jersey-graphic-tee-mfk-9343-q-olive.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87450" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9343-q-17-olive_1_mfk-9343-q-17-olive.jpg" loading="lazy" width="500" height="667" alt="Olive Solid Slub Jersey Graphic Tee" /></span></span>
                                                    <style>{`
                                                        .product-image-container-87450 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87450 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Olive Solid Slub Jersey Graphic Tee" href="https://www.muftijeans.in/olive-solid-slub-jersey-graphic-tee-mfk-9343-q-olive.html" data-full-name="Olive Solid Slub Jersey Graphic Tee" data-default-max-length="40" data-mobile-max-length="20">Olive Solid Slub Jersey Graphic
                                                            Tee</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,699</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87450" data-price-box="product-id-87450"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87450" data-price-amount="849" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹849</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87450" data-price-amount="1699" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,699</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87449" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/navy-solid-slub-jersey-graphic-tee-mfk-9343-q-navy.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87449" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9343-q-05-navy_1_mfk-9343-q-05-navy.jpg" loading="lazy" width="500" height="667" alt="Navy Solid Slub Jersey Graphic Tee" /></span></span>
                                                    <style>{`
                                                        .product-image-container-87449 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87449 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Navy Solid Slub Jersey Graphic Tee" href="https://www.muftijeans.in/navy-solid-slub-jersey-graphic-tee-mfk-9343-q-navy.html" data-full-name="Navy Solid Slub Jersey Graphic Tee" data-default-max-length="40" data-mobile-max-length="20">Navy Solid Slub Jersey Graphic
                                                            Tee</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,699</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87449" data-price-box="product-id-87449"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87449" data-price-amount="849" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹849</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87449" data-price-amount="1699" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,699</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_87448" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/green-cut-sew-pique-polo-t-shirt-mfk-9313-q-green.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-87448" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9313-q-04-green_6.jpg" loading="lazy" width="500" height="667" alt="Green Cut & Sew Pique Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-87448 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-87448 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Green Cut & Sew Pique Polo T-Shirt" href="https://www.muftijeans.in/green-cut-sew-pique-polo-t-shirt-mfk-9313-q-green.html" data-full-name="Green Cut & Sew Pique Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Green Cut & Sew Pique Polo
                                                            T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,099</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="87448" data-price-box="product-id-87448"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-87448" data-price-amount="1049" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,049</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-87448" data-price-amount="2099" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,099</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_86046" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/turquoise-slub-jersey-graphic-tee-mfk-9347-q-turquoise.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-86046" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9347-q-20-turq_7.jpg" loading="lazy" width="500" height="667" alt="Turquoise Slub Jersey Graphic Tee" /></span></span>
                                                    <style>{`
                                                        .product-image-container-86046 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-86046 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Turquoise Slub Jersey Graphic Tee" href="https://www.muftijeans.in/turquoise-slub-jersey-graphic-tee-mfk-9347-q-turquoise.html" data-full-name="Turquoise Slub Jersey Graphic Tee" data-default-max-length="40" data-mobile-max-length="20">Turquoise Slub Jersey Graphic
                                                            Tee</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,499</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="86046" data-price-box="product-id-86046"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-86046" data-price-amount="749" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹749</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-86046" data-price-amount="1499" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_86044" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/rust-taped-slub-jersey-henley-mfk-9327-q-rust.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-86044" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/m/f/fit-in/500x667/mfk-9327-rust-1.jpg" loading="lazy" width="500" height="667" alt="Rust Taped Slub Jersey Henley" /></span></span>
                                                    <style>{`
                                                        .product-image-container-86044 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-86044 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Rust Taped Slub Jersey Henley" href="https://www.muftijeans.in/rust-taped-slub-jersey-henley-mfk-9327-q-rust.html" data-full-name="Rust Taped Slub Jersey Henley" data-default-max-length="40" data-mobile-max-length="20">Rust Taped Slub Jersey
                                                            Henley</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,499</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="86044" data-price-box="product-id-86044"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-86044" data-price-amount="749" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹749</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-86044" data-price-amount="1499" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,499</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_86043" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/teal-white-military-pique-polo-t-shirt-mfk-9308-q-teal.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-86043" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/_/fit-in/500x667/1_mfk-9308-q-75-teal_6.jpg" loading="lazy" width="500" height="667" alt="Teal & White Military Pique Polo T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-86043 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-86043 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Teal & White Military Pique Polo T-Shirt" href="https://www.muftijeans.in/teal-white-military-pique-polo-t-shirt-mfk-9308-q-teal.html" data-full-name="Teal & White Military Pique Polo T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Teal & White Military Pique
                                                            Polo T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹2,199</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="86043" data-price-box="product-id-86043"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-86043" data-price-amount="1099" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹1,099</span></span>
                                                            </span></span> <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-86043" data-price-amount="2199" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹2,199</span></span>
                                                            </span></span> </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85466" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/black-graphic-t-shirt-mfk-9424-q-black.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85466" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9424-q-01-black_1_mfk-9424-q-01-black.jpg" loading="lazy" width="500" height="667" alt="Black Graphic T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85466 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85466 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Black Graphic T-Shirt" href="https://www.muftijeans.in/black-graphic-t-shirt-mfk-9424-q-black.html" data-full-name="Black Graphic T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Black Graphic T-Shirt</a></div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,399</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85466" data-price-box="product-id-85466"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85466" data-price-amount="699" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹699</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85466" data-price-amount="1399" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,399</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85465" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/olive-graphic-print-t-shirt-mfk-9422-q-olive.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85465" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9422-q-17-olive_1_mfk-9422-q-17-olive.jpg" loading="lazy" width="500" height="667" alt="Olive Graphic Print T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85465 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85465 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="Olive Graphic Print T-Shirt" href="https://www.muftijeans.in/olive-graphic-print-t-shirt-mfk-9422-q-olive.html" data-full-name="Olive Graphic Print T-Shirt" data-default-max-length="40" data-mobile-max-length="20">Olive Graphic Print T-Shirt</a>
                                                    </div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,399</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85465" data-price-box="product-id-85465"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85465" data-price-amount="699" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹699</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85465" data-price-amount="1399" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,399</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item product product-item" id="product-item_85464" data-shuffle-group="" style={{}}>
                                            <div className="product-item-info ">{ /* related_products_list*/ } <a href="https://www.muftijeans.in/white-graphic-print-t-shirt-mfk-9422-q-white.html" className="product photo product-item-photo"> <span className="product-image-container product-image-container-85464" style={{ width: 500 }}><span className="product-image-wrapper" style={{ paddingBottom: "133.4%" }}><img className="product-image-photo" src="https://d118ps6mg0w7om.cloudfront.net/media/catalog/product/1/6/fit-in/500x667/16-04-24_mfk-9422-q-02-white_1_mfk-9422-q-02-white.jpg" loading="lazy" width="500" height="667" alt="White Graphic Print T-Shirt" /></span></span>
                                                    <style>{`
                                                        .product-image-container-85464 {
                                                            width: 500px;
                                                        }

                                                        .product-image-container-85464 span.product-image-wrapper {
                                                            padding-bottom: 133.4%;
                                                        }
                                                    `}</style>
                                                </a>
                                                <div className="product details product-item-details">
                                                    <div className="related-product-name"><a className="product-item-link" title="White Graphic Print T-Shirt" href="https://www.muftijeans.in/white-graphic-print-t-shirt-mfk-9422-q-white.html" data-full-name="White Graphic Print T-Shirt" data-default-max-length="40" data-mobile-max-length="20">White Graphic Print T-Shirt</a>
                                                    </div>
                                                    <div className="price-box"><span className="old-price sly-old-price"><span className="price-container price-final_price tax"><span className="price-wrapper "><span className="price">₹1,399</span></span></span></span>
                                                    </div>
                                                    <div className="price-box price-final_price" data-role="priceBox" data-product-id="85464" data-price-box="product-id-85464"> <span className="normal-price"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label" /> <span id="product-price-85464" data-price-amount="699" data-price-type="finalPrice" className="price-wrapper "><span className="price">₹699</span></span> </span></span>
                                                        <span className="old-price sly-old-price no-display"> <span className="price-container price-final_price tax weee">
                                                                <span className="price-label">Regular Price</span> <span id="old-price-85464" data-price-amount="1399" data-price-type="oldPrice" className="price-wrapper "><span className="price">₹1,399</span></span>
                                                            </span></span>
                                                    </div>
                                                    <div className="buyfree-label">-50% OFF</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Productreleted

import React from 'react'

import  Logo from './LOGOnew.png';

function Footer() {
  return (
<>

<footer className="page-footer">
            <div className="footer content">
                <div className="footerbtm home-aboutus-other">
                    <div className="inrbx_wdth">
                        <div className="ftrbtmstorybx">
                            <div className="mbl_logo bnrimg_mbl"><img src={Logo} alt="Mufti Jeans" width="180" height="24" /></div>
                            <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                <h4>The Spirit Of casamanolo</h4>
                                <p>The year was 1992, and a young Kamal Khushlani, a film enthusiast, was getting
                                    curious about the world of fashion. What started as sourcing looks htmlFor a portfolio
                                    shoot soon turned into an intrepid journey through contemporary menswear. Along this
                                    path, he decided to change the brand name to be true to his roots and unabashedly
                                    Indian. He called it casamanolo because it stood htmlFor non-uniform wear. Born at the turn
                                    of the century and Airborne Since 1998, casamanolo has redefined menswear constantly over
                                    the last twenty-five years. Inspired by the transformation in his patrons' lives,
                                    Khushlani searched his soul - how did we get here? Days and nights of research
                                    brought him back to his origin, Mumbai - the source of his spirit, his muse. Today,
                                    casamanolo offers a range of shirts, t-shirts, jeans, and outerwear, infused with the
                                    city of Mumbai’s ever-changing, ever-evolving spirit. Each product strives to
                                    deliver creative excellence through casamanolo's bold and expressive style while being
                                    contemporary and innovative at heart, making casamanolo the brand htmlFor a man who seeks a
                                    refined sense of style.</p>
                            </div>
                        </div>
                        <div className="ftrbtmtopbx">
                            <div className="ftrlft">
                                <div className="ftrlnkbx">
                                    <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                        <h3 className="dstp active">Corporate</h3>
                                        <ul className="ftrlnk" style={{ display: "block" }}>
                                            <li><a href="/about-us">About Us</a></li>
                                            <li><a href="/career">Career</a></li>
                                            <li><a href="/storelocator">Store Locator</a></li>
                                            <li><a href="">Blog</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="ftrlnkbx">
                                    <h3 className="dstp active">LET US HELP YOU</h3>
                                    <ul className="ftrlnk" style={{ display: "block" }}>
                                        <li><a href="/order-status">Order Status</a></li>
                                        <li><a href="/shipment-policy">Shipment Policy</a></li>
                                        <li><a href="/return-policy">Return Policy</a></li>
                                    </ul>
                                </div>
                                <div className="ftrlnkbx">
                                    <h3 className="dstp active">Feedback</h3>
                                    <ul className="ftrlnk" style={{ display: "block" }}>
                                        <li><a href="/faq">FAQ's</a></li>
                                        <li><a href="/contact">Feedback Form</a></li>
                                    </ul>
                                </div>
                                <div className="ftrlnkbx cntctbx">
                                    <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                        <h3 className="dstp active">Registered and Corporate Office Address</h3>
                                        <div className="hdngbx">Credo Brands Marketing Limited</div>
                                        <div className="para">B-8, MIDC Central Road, Marol, Next to MIDC Police Station,
                                            Andheri (E), Mumbai - 400 093, Maharashtra, India</div>
                                        <div className="hdngbx">Email: <a href="mailto:ecommerce@casamanolo.in" target="_blank" rel="noopener">ecommerce@casamanolo.in</a></div>
                                        <div className="hdngbx">CIN: L18101MH1999PLC119669</div>
                                        <div className="para">Toll Free: <a href="tel:18002668384">1800 266
                                                8384</a><br />(Monday - Saturday | 11AM - 6PM)</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ftrrht">
                                <div className="followus"> Follow US</div>
                                <div className="sclbxbtm">
                                    <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                                        <ul className="sclbxnw">
                                            <li><a href="" target="_blank" rel="nofollow,noopener"><img src="https://d4kuloxg8pkbr.cloudfront.net/images/fb_ico.png" alt="Follow on Facebook" width="25" height="25" loading="lazy" /></a></li>
                                            <li><a href="" target="_blank" rel="nofollow,noopener"><img src="https://d4kuloxg8pkbr.cloudfront.net/images/insta_ico.png" alt="Follow on Insta" width="25" height="25" loading="lazy" /></a>
                                            </li>
                                            <li><a href="" target="_blank" rel="nofollow,noopener"><img src="https://d4kuloxg8pkbr.cloudfront.net/images/youtube_ico.png" alt="Follow on Youtube" width="25" height="25" loading="lazy" /></a></li>
                                            <li><a href="" target="_blank" rel="nofollow,noopener"><img src="https://d4kuloxg8pkbr.cloudfront.net/images/twit_ico.png" alt="Follow in Twitter" width="25" height="25" loading="lazy" /></a></li>
                                            <li><a href="" target="_blank" rel="nofollow,noopener"><img src="https://d4kuloxg8pkbr.cloudfront.net/images/linked_ico.png" alt="Follow in Linkedin" width="25" height="25" loading="lazy" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footerrht">
                                    <ul className="btmlnklst">
                                        <li><a href="">Terms of use</a>
                                        </li>
                                        <li><a href="">Privacy Policy</a></li>
                                        <li className="footer-logo-muftijeans bnrimg_dstp"><img src={Logo} alt="Mufti Jeans" width="180" height="24" /></li>
                                        <li>© 2024 casamanolo. All Rights Reserved.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerbtm mfooterbtmwhite home-aboutus-other">
                    <div className="inrbx_wdth">
                        <div data-content-type="html" data-appearance="default" data-element="main">
                            <div className="section">
                                <div className="mufti-jeans-explore-tn">
                                    <div className="mufti-jeans-explore-top-nw">
                                        <h2><span>casamanolo: Discover Trending Shirts htmlFor Men</span></h2>
                                    </div>
                                    <div className="mufti-jeans-explore-mid">
                                        <p>Shirts define the style of a man, especially in fashion. Trending shirts htmlFor
                                            men have everything, whether you are updating a wardrobe or looking forward
                                            to fashion. Casual shirts htmlFor men create great appeal with relaxed and, at
                                            the same time, stylish looks. They offer comfort without losing even a
                                            single drop of style, making them perfect htmlFor daily wear and casual outings.
                                            Our expressive casualwear htmlFor men comes in different styles, unique prints,
                                            and eye-catching colours using new and innovative fabric constructions Keep
                                            up to date with the most current global trends with casamanolo collection of
                                            shirts htmlFor men. From bold patterns to subtle colours, shop everyday men's
                                            casual shirts to match every taste. Remember: a well-chosen gents shirt can
                                            easily take you from a day in the office to a night on the town. With the
                                            right selection of shirts htmlFor men, you’ll always be dressed to impress. </p>

                                        <h2 className="hdngnw">Types of Shirts htmlFor Men Available at casamanolo</h2>
                                        <h3>Printed Shirt</h3>
                                        <p>Men’s Fashion is an ever-changing, ever-evolving canvas; and our <a href="/shirts-for-men/printed-shirts.html">Printed shirts</a> become the
                                            very instruments of expression. They come in abstract patterns and quirky
                                            motifs that help you to sport your sense of style uniquely. They go
                                            perfectly with jeans or chinos and are versatile in any wardrobe. </p>
                                        <h3>Denim Shirt</h3>
                                        <p>Looking for a rugged feel? Nothing beats the classic <a href="/shirts-for-men/denim-shirts.html">denim shirt</a>. These pieces
                                            set the right vibe on any occasion, be it a casual day out or a laid-back
                                            day at the office.</p>
                                        <h3>Stripe Shirt</h3>
                                        <p><a href="/shirts-for-men/stripe-shirts.html">Stripe shirts</a> have a classic
                                            feel in timeless designs. They ease into the shift from formal to casual,
                                            with style and flair, and hence are indispensable.</p>
                                        <h3>Plain Shirt</h3>
                                        <p>For absolute simplicity and versatility, no one does it better than a <a href="/shirts-for-men/plain-shirts.html">plain shirt</a>. These are the
                                            must-haves in every wardrobe and act as a canvas that is dressed up with a
                                            tie and a blazer to make them fit htmlFor formal events and dressed down with
                                            jeans htmlFor casual dressing.</p>
                                        <h3>Check Shirt</h3>
                                        <p><a href="/shirts-for-men/check-shirts.html">Check shirts</a> are the common
                                            link between tradition and modernity. From small, tight checks to bold, big
                                            patterns, they make htmlFor varied looks that can be casual and smart. They
                                            especially appeal htmlFor their ability to add depth and visual interest to the
                                            outfit. </p>
                                        <h3>Floral Shirt</h3>
                                        <p><a href="/shirts-for-men/floral-shirts.html">Floral shirts</a> bring a fresh
                                            and vibrant feel to any outfit, whether it's casual office wear or evening
                                            attire. They are ideal htmlFor summer events and vacations, adding a pop of
                                            colour and lively patterns to your wardrobe on warm summer days. </p>
                                        <h2 className="hdngnw">Trends in Men's Shirt Sleeve Lengths</h2>
                                        <p>Look no further than our selection of full- and half-sleeve shirts htmlFor a
                                            well-balanced wardrobe that combines comfort and style. Our <a href="/shirts-for-men/full-sleeved-shirts.html">full-sleeve shirts</a>
                                            are ideal htmlFor the office or for a night out since they combine basic utility
                                            with a contemporary twist. They're adaptable enough to work in any situation
                                            and will always keep you looking put together. However, on those hot, sunny
                                            days, our <a href="/shirts-for-men/half-sleeved-shirts.html">half-sleeve
                                                shirts</a> are ideal. In addition to being fashionable enough to wear to
                                            the beach, a summer BBQ, or a laid-back weekend get-together, they are made
                                            to keep you cool and comfortable. At casamanolo, we present you with a superior
                                            selection of shirts with an extensive array of customisation possibilities
                                            by fusing comfort and style.</p>
                                        <h2 className="hdngnw">Choose Right Shirt Fabric htmlFor Your Needs</h2>
                                        <p>When it comes to choosing the perfect shirt, the fabric makes all the
                                            difference. We at casamanolo are dedicated to offering choices that complement
                                            your tastes and way of life. There is a <a href="/shirts-for-men/linen-shirts.html">linen shirt</a> to fit any
                                            style thanks to the wide range of colours and designs we provide. Whether
                                            your style is more modest or bold colours are your thing, there are
                                            countless ways to show off your individuality with our linen selection. On
                                            the contrary, our cotton shirts are made to be multipurpose wardrobe
                                            staples. Our cotton shirts collection covers all the bases htmlFor any event,
                                            from traditional solid colours to chic stripes and checks, as well as
                                            expressive prints. Our <a href="/shirts-for-men/cotton-shirts.html">cotton
                                                shirts</a> are a wise addition to your wardrobe since they are made from
                                            high-quality materials and offer long-lasting comfort and durability in
                                            addition to their fashionable appearance. With our diverse range of linen
                                            and cotton shirts, you can effortlessly create versatile outfits that
                                            reflect your individuality. Explore our collection today and discover
                                            timeless pieces that elevate your style game while ensuring you stay
                                            comfortable and confident.</p>
                                        <h2 className="hdngnw">Shop casamanolo Shirts htmlFor Men Online</h2>
                                        <p>Discover our improved web interface and product-specific filters that make
                                            buying men’s shirts online simple. Our collection showcases the latest men's
                                            shirts that effortlessly add charm and refinement to your outfit. casamanolo
                                            range of shirts is suitable htmlFor both formal and casual events. casamanolo
                                            shirts are made with premium fabrics and meticulous attention to detail to
                                            complement your sense of style. We offer a wide variety to match your
                                            preferences, be it expressive patterns, contemporary checks and stripes, or
                                            timeless solids. Our shirts are fitted to perfection, flattering every body
                                            type and boosting your confidence. Shopping htmlFor stylish shirts for men is
                                            convenient with casamanolo's online store. Explore our collection today and find
                                            the perfect shirt that complements your unique style. With casamanolo, you can
                                            easily upgrade your wardrobe and stay ahead of the fashion curve. Our
                                            commitment to quality and customer satisfaction ensures that every purchase
                                            is a worthwhile investment in your style journey.</p>
                                        <h2 className="hdngnw">FREQUENTLY ASKED QUESTIONS</h2>
                                        <h3 className="h3faq">1. What makes casamanolo shirts stand out from other brands? </h3>
                                        <p>casamanolo makes a large variety of shirts that consider global fashion trends,
                                            uncompromising quality and prints that are unique, elegant and expressive of
                                            your style. Our shirts collection is made in various fabrics, colours,
                                            textures and prints. We offer shirts that are suited htmlFor work and play. Wear
                                            our shirts to an evening out, or htmlFor casual Fridays at work, let us
                                            accompany you to every important milestone in life.</p>
                                        <h3 className="h3faq">2. Are casamanolo shirts true to size?</h3>
                                        <p>Due to our garment’s construction being slimmer, we recommend you to size up.
                                            Our shirts and all tops are slimmer and labelled from M (Medium) to 6XL. Our
                                            M corresponds to a size 38 (cm) collar or 38” chest. Typically, if you wear
                                            a pair of 32-sized jeans, and are athletically built you will fit into our
                                            L. We recommend an XL in case you like your shirts a little looser or if you
                                            wear a size 34 jeans, and so on. For best results, please consult our sizing
                                            guide, which can be found on each product description page.</p>
                                        <h3 className="h3faq">3. Can wear casamanolo casual shirts htmlFor formal occasions</h3>
                                        <p>casamanolo makes casualwear htmlFor men that is modern and in line with global fashion
                                            trends. You can find prints and styles that cater to a variety of events.
                                            From floral microprints to solid shirts in a range of colours that can be
                                            worn to work events and parties alike</p>
                                        <h3 className="h3faq">4. What colour shirt should a man have?</h3>
                                        <p>At casamanolo, our range of shirts includes menswear classics such as navy, white,
                                            black, and grey, as well as contemporary pastels and eveningwear colours.
                                            These shirts can be styled with our line of bottoms to complete your look
                                            and ensure you're well-dressed htmlFor every occasion. We make clothes for the
                                            modern man who wants to be well-dressed, comfortable, and, most importantly,
                                            stylish.</p>
                                        <h3 className="h3faq">5. What kinds of shirts are in style?</h3>
                                        <p>At casamanolo, we make garments htmlFor men that are on trend and effortlessly
                                            stylish. Take your pick from our wide variety of prints and styles that
                                            become a part of your everyday style. Allow our shirts collection htmlFor men to
                                            accompany you to all your special moments and daily routines alike.</p>


                                    </div>
                                    <div className="mufti-jeans-explore-top">
                                        <div className="mufti-jeans-popular-search-left">
                                            <div style={{ fontSize: 21 }}>POPULAR SEARCHES</div>
                                        </div>
                                        <div className="mufti-jeans-popular-search-right" />
                                        <div className="mufti-jeans-popular-search-bot">
                                            <ul>
                                                <li><a href="/shirts-for-men/printed-shirts.html">PRINTED SHIRTS.</a>
                                                </li>
                                                <li><a href="/shirts-for-men/check-shirts.html">CHECK SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/denim-shirts.html">DENIM SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/stripe-shirts.html">STRIPE SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/plain-shirts.html">PLAIN SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/cotton-shirts.html">COTTON SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/linen-shirts.html">LINEN SHIRTS</a></li>
                                                <li><a href="/shirts-for-men/half-sleeved-shirts.html">HALF SLEEVE
                                                        SHIRT</a></li>
                                                <li><a href="/shirts-for-men/full-sleeved-shirts.html">FULL SLEEVE
                                                        SHIRT</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="mufti-jeans-explore-top">
                                        <div className="mufti-jeans-popular-search-left">
                                            <div style={{ fontSize: 21 }}>RELATED SEARCHES</div>
                                        </div>
                                        <div className="mufti-jeans-popular-search-right" />
                                        <div className="mufti-jeans-popular-search-bot">
                                            <ul>
                                                <li><a href="/jeans-for-men.html">JEANS</a></li>
                                                <li><a href="/t-shirts-for-men.html">T-SHIRTS</a></li>
                                                <li><a href="/trousers-for-men.html">TROUSERS</a></li>
                                                <li><a href="/joggers-for-men.html">JOGGERS</a></li>
                                                <li><a href="/cargo-pants-for-men.html">CARGOS</a></li>
                                                <li><a href="/shorts-for-men.html">SHORTS</a></li>
                                                <li><a href="/jackets-for-men.html">JACKETS</a></li>
                                                <li><a href="/sweatshirts-for-men.html">SWEATSHIRTS</a></li>
                                                <li><a href="/sweaters-for-mens.html">SWEATERS</a></li>
                                                <li><a href="/flatknits-for-men.html">FLATKNITS</a></li>
                                                <li><a href="/blazers-for-men.html">BLAZERS</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footermorecategory">
                    <div className="inrbx_wdth">
                        <div className="morecat-title"> You Might Be Interested In <div className="more-might">Read More</div>
                        </div>
                        <div data-content-type="html" data-appearance="default" data-element="main" data-decoded="true">
                            <div className="footermorecategory-txt">
                                <div className="footermorecategory-part1">
                                    <h3><a title="CHECK SHIRTS" href="https://www.muftijeans.in/shirts-for-men.html">Men's Shirts</a></h3>
                                    <div className="product-footer">
                                        <ul className="footersubmnulst">
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/printed-shirts.html">Printed
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/denim-shirts.html">Denim
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/stripe-shirts.html">Stripe
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/plain-shirts.html">Plain
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/check-shirts.html">Check
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/linen-shirts.html">Linen
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/cotton-shirts.html">Cotton
                                                    Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/shirts-for-men/floral-shirts.html">Floral
                                                    Shirts</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footermorecategory-part1">
                                    <h3><a title="CHECK SHIRTS" href="https://www.muftijeans.in/t-shirts-for-men.html">Men's T-Shirts</a>
                                    </h3>
                                    <div className="product-footer">
                                        <ul className="footersubmnulst">
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/plain-t-shirts.html">Plain
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/stripe-t-shirts.html">Stripe
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/printed-t-shirts.html">Printed
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/henley-t-shirts.html">Henley
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/sporty-t-shirts.html">Sporty
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/graphic-t-shirts.html">Graphic
                                                    T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/printed-polo-t-shirts.html">Printed
                                                    Polo T-Shirts</a></li>
                                            <li><a href="https://www.muftijeans.in/t-shirts-for-men/sporty-polo-t-shirts.html">Sporty
                                                    Polo T-Shirts</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footermorecategory-part1">
                                    <h3><a title="JEANS" href="https://www.muftijeans.in/jeans-for-men.html">Men's
                                            Jeans</a></h3>
                                    <div className="product-footer">
                                        <ul className="footersubmnulst">
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/denim-de-luxe-jeans.html">Denim
                                                    De Luxe Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/distressed-jeans.html">Distressed
                                                    Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/lightweight-jeans.html">Lightweight
                                                    Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/super-stretch-jeans.html">Stretchable
                                                    Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/originals-jeans.html">Originals
                                                    Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/slim-fit-jeans.html">Slim
                                                    Fit Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/skinny-fit-jeans.html">Skinny
                                                    Fit Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/straight-fit-jeans.html">Straight
                                                    Fit Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/narrow-fit-jeans.html">Narrow
                                                    Fit Jeans</a></li>
                                            <li><a href="https://www.muftijeans.in/jeans-for-men/relaxed-fit-jeans.html">Relaxed
                                                    Fit Jeans</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footermorecategory-part1">
                                    <h3><a title="JEANS" href="#">Men's Winter Wear</a></h3>
                                    <div className="product-footer">
                                        <ul className="footersubmnulst">
                                            <li><a href="https://www.muftijeans.in/jackets-for-men.html">Men's
                                                    Jackets</a></li>
                                            <li><a href="https://www.muftijeans.in/sweatshirts-for-men.html">Men's
                                                    Sweatshirts</a></li>
                                            <li><a href="https://www.muftijeans.in/sweaters-for-mens.html">Men's
                                                    Sweaters</a></li>
                                            <li><a href="https://www.muftijeans.in/flatknits-for-men.html">Men's
                                                    Flatknits</a></li>
                                            <li><a href="https://www.muftijeans.in/blazers-for-men.html">Men's
                                                    Blazers</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footermorecategory-part1">
                                    <h3><a title="JEANS" href="#">Other</a></h3>
                                    <div className="product-footer">
                                        <ul className="footersubmnulst">
                                            <li><a href="https://www.muftijeans.in/trousers-for-men.html">Men's
                                                    Trousers</a></li>
                                            <li><a href="https://www.muftijeans.in/trousers-for-men/casual-trousers.html">Casual
                                                    Trousers</a></li>
                                            <li><a href="https://www.muftijeans.in/joggers-for-men.html">Men's
                                                    Joggers</a></li>
                                            <li><a href="https://www.muftijeans.in/cargo-pants-for-men.html">Men's Cargo
                                                    Pants</a></li>
                                            <li><a href="https://www.muftijeans.in/shorts-for-men.html">Men's Shorts</a>
                                            </li>
                                            <li><a href="https://www.muftijeans.in/new-arrivals.html">New Arrivals</a>
                                            </li>
                                            <li><a href="https://www.muftijeans.in/mufti-sale.html">Mufti Sale</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="endOfPage"> </div>
                </div>
                <div className="cart-footerlogo">
                    <div className="cart-footerlogo-wrapper">
                        <div className="cart-logo"><img src={Logo} alt="Mufti Jeans" width="180" /></div>
                        <div className="cart-copy">© 2024 casamanolo. All Rights Reserved.</div>
                        <div />
                        <div className="srch_fxd closesrch" />
                    </div>
                </div>
            </div>
        </footer>

</>
  )
}

export default Footer

import React, { useContext, useState } from 'react';
import { MyContext } from '../Context/Mycontext';
import { useNavigate } from 'react-router-dom';

function Forgetpass() {
  const context = useContext(MyContext);
  const {setMainSignpage,url2} = context;
  const navigate =useNavigate();
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  
  const [formotp, setformotp] = useState("block");
  const [formnewpass, setformnewpass] = useState("none");

  // Handle input changes for the first form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle submit for sending OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!formData.email) {
      setErrors({ email: "Email is required" });
      return;
    }

    setErrors({}); // Clear any previous errors

    try {
      const response = await fetch(`${url2}api/userotp/sendotp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formData.email }), // Send email in the request body
      });

      if (response.ok) {
        alert('OTP sent successfully!');
        setformotp("none"); // Hide OTP form
        setformnewpass("block"); // Show new password form
        setOtpSent(true); // Disable the OTP button
      } else {
        const error = await response.json();
        alert(`Error: ${error.message}`);
        setOtpSent(false); // Re-enable the OTP button
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP. Please try again.');
      setOtpSent(false);
    }
  };

  // State for the second form (new password)
  const [formDatanew, setFormDatanew] = useState({ email: '', otp: '', newPassword: '' });

  // Handle input changes for the second form
  const handleChangenewpass = (e) => {
    const { name, value } = e.target;
    setFormDatanew((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle submit for resetting the password
  const handleSubmitnewpass = async (e) => {
    e.preventDefault();

  


    // Basic validation for the second form
    if (!formDatanew.email || !formDatanew.otp || !formDatanew.newPassword) {
      alert("Please fill out all the fields.");
      return;
    }

    setErrors({}); // Clear previous errors

    try {
      const response = await fetch(`${url2}api/userotp/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: formDatanew.email,
          otp: formDatanew.otp , 
          newPassword: formDatanew.newPassword,
        }),
      });

      if (response.ok) {
        alert("Password reset successfully!");

        setMainSignpage(false);
        navigate(window.location.pathname);
      } else {
        const error = await response.json();
        alert(`Error: ${error.message}`);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Failed to reset password. Please try again.');
    }
  };

  return (
    <>
      {/* OTP Form */}
      <form style={{ display: formotp }} className="form form-login" onSubmit={handleSubmit}>
        <div className="fieldset row login">
          {/* Email Field */}
          <div className="field">
            <div className="control">
              <input
                name="email"
                type="email"
                className="input-text"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                aria-label="Email Address"
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>

          {/* Submit Button */}
          <div className="actions-toolbar">
            <div className="primary">
              <button type="submit" className="action action-login secondary" disabled={otpSent}>
                {otpSent ? 'OTP Sent' : 'Send OTP'}
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* New Password Form */}
      <form style={{ display: formnewpass }} className="form form-login" onSubmit={handleSubmitnewpass}>
        <div className="fieldset row login">
          {/* Email Field */}
          <div className="field">
            <div className="control">
              <input
                name="email"
                type="email"
                className="input-text"
                placeholder="Email Address"
                value={formDatanew.email}
                onChange={handleChangenewpass}
                aria-label="Email Address"
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>

          {/* OTP Field */}
          <div className="field">
  <div className="control">
    <input
      name="otp"
      type="text" // Change to "text" to handle leading zeros
      className="input-text"
      placeholder="OTP"
      value={formDatanew.otp}
      onChange={handleChangenewpass}
      aria-label="OTP"
      pattern="\d*" // Ensure only digits are allowed
      maxLength={6} // Optionally limit OTP length to 6 digits
    />
    {errors.otp && <span className="error">{errors.otp}</span>}
  </div>
</div>


          {/* New Password Field */}
          <div className="field">
            <div className="control">
              <input
                name="newPassword"
                type="password"
                className="input-text"
                placeholder="New Password"
                value={formDatanew.newPassword}
                onChange={handleChangenewpass}
                aria-label="New Password"
              />
              {errors.newPassword && <span className="error">{errors.newPassword}</span>}
            </div>
          </div>

          {/* Submit Button */}
          <div className="actions-toolbar">
            <div className="primary">
              <button type="submit" className="action action-login secondary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Forgetpass;

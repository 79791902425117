import React, { useEffect, useState } from 'react';

function Useraddress() {
  const [addresses, setAddresses] = useState([]);
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

  useEffect(() => {
    // Function to fetch addresses from the API
    const fetchAddresses = async () => {
      try {
        const response = await fetch('https://dhancash.uw.r.appspot.com/api/Address/getaddress', { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auto-token': `${token}`,  // Include the token in the headers
          },
        });

        const result = await response.json();
        console.log(result, "API response"); // Log the entire response for debugging

        if (response.ok) {
          setAddresses(result);
        } else {
          console.error('Failed to fetch addresses:', result.message);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses(); // Call the function to fetch addresses when the component mounts
  }, [token]);

  return (
    <>
      {addresses.length > 0 ? (
        addresses.slice(0, 2).map((address, index) => (  // Display only the first 2 addresses
          <div key={index} className="box box-billing-address col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <h4 className="account-sub-title">Address {index + 1}</h4>
            <div className="box-content">
              <address>
                {address.name} <br />
                {address.address} <br />
                {address.landmark}, {address.city}, {address.state}, {address.pincode} <br />
                {address.country} <br />
                T: <a href={`tel:${address.phone}`}>{address.phone}</a>
              </address>
            </div>
            <div className="box-actions">
              <a
                className="action edit btn btn-sm btn-default"
                href={`/edit-address/${address._id}`} // Assuming each address has a unique ID (_id)
                data-ui-id={`billing-edit-link-${index}`}
              >
                <span>Edit Address</span>
              </a>
            </div>
          </div>
        ))
      ) : (
        <div className="box box-shipping-address col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <h4 className="account-sub-title">Default Shipping Address</h4>
          <div className="box-content">
            <address>You have no address!</address>
          </div>
          <div className="box-actions">
            <a
              className="action edit btn btn-sm btn-default"
              href="https://www.muftijeans.in/customer/address/edit/"
              data-ui-id="default-shipping-edit-link"
            >
              <span>Edit Address</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default Useraddress;
